import React, { useState } from 'react';
import VisitorInsightsSection from './VisitorInsightsSection';
import UserBehaviorSection from './UserBehaviorSection';
import ProductGoogleAdsIcon from './Images/ProductGoogleAdsIcon.png';
import ProductMetaAdsIcon from './Images/ProductMetaAdsIcon.png';
import ProductInstagramIcon from './Images/ProductInstagramIcon.png';
import ProductTiktokAdsIcon from './Images/ProductTiktokAdsIcon.png';
import ProductShopifyIcon from './Images/ProductShopifyIcon.png';
import ProductPagePixelIcon from "./Images/ProductPagePixelIcon.png";
import ProductPageAccess1 from "./Images/ProductPageAccess1.png";
import ProductPageAccess2 from "./Images/ProductPageAccess2.png";
import { useTranslation } from 'react-i18next';
import CampaignMonitorSection from "./CampaignMonitorSection.js"
import DeviceAccessItem from "./DeviceAccessItem.js"

import CampaignsHubVideo from './ProductPageVideos/ProductPageDashboard/CampaignsHub.mp4';
import EssentialGraphsVideo from './ProductPageVideos/ProductPageDashboard/EssentialGraphs.mp4';
import NotableChangesVideo from './ProductPageVideos/ProductPageDashboard/NotableChanges.mp4';
import PersonalizedDashboardVideo from './ProductPageVideos/ProductPageDashboard/CreateaQuick,PersonalizedDashboardinMinutes.mp4';
import CompareCampaignsVideo from './ProductPageVideos/ProductPageDashboard/MonitorandCompareCampaignsAcrossPlatforms.mp4';
import BerryPixelVideo from './ProductPageVideos/ProductPageDashboard/BerryPixel.mp4';
import SalesAttributionVideo from './ProductPageVideos/ProductPageDashboard/SalesAttributionModel.mp4';
import DeepInsightsVideo from './ProductPageVideos/ProductPageDashboard/GainDeepInsightswithAdvancedAnalytics.mp4';
import BottomFooter from "./bottomfooter";

const ProductPageDashboard = () => {
    const { t } = useTranslation();
    const [campaignsHubVideo, setCampaignsHubVideo] = useState(CampaignsHubVideo);
    const [personalizedDashboardVideo, setPersonalizedDashboardVideo] = useState(PersonalizedDashboardVideo);
    const [compareCampaignsVideo, setCompareCampaignsVideo] = useState(CompareCampaignsVideo);
    const [berryPixelVideo, setBerryPixelVideo] = useState(BerryPixelVideo);
    const [deepInsightsVideo, setDeepInsightsVideo] = useState(DeepInsightsVideo);
    const [currentVideo, setCurrentVideo] = useState(CampaignsHubVideo);

    return (
        <div className="w-full h-full flex flex-col gap-[10vh] pt-[10vh] lg:pt-[15vh]">
            <div className=''>
                <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-[#e30b5c] to-black opacity-50"></div>
                <VisitorInsightsSection
                    title={t("Dashboard and Insight Tools")}
                    subtitleMain={t("The Only Platform for All Your")}
                    subtitleEmphasized={t("E-commerce & Marketing Analytics")}
                    videoUrl={campaignsHubVideo}
                    buttons={[
                        {
                            title: t('Campaigns Hub'),
                            description: t('Monitor and compare campaigns across platforms.'),
                            onClick: () => setCampaignsHubVideo(CampaignsHubVideo)
                        },
                        {
                            title: t('Essential Graphs'),
                            description: t('Track your sales volume and advertising spent.'),
                            onClick: () => setCampaignsHubVideo(EssentialGraphsVideo)
                        },
                        {
                            title: t('Notable Changes'),
                            description: t('Easily see changes in the graphs.'),
                            onClick: () => setCampaignsHubVideo(NotableChangesVideo)
                        }
                    ]}
                />
            </div>
            <div
                x-data="{}"
                x-init="$nextTick(() => {
                        let ul = $refs.logos;
                        ul.insertAdjacentHTML('afterend', ul.outerHTML);
                        ul.nextSibling.setAttribute('aria-hidden', 'true');
                    })"
                className="w-full max-h-[200px] mt-auto inline-flex flex-nowrap overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-128px),transparent_100%)]"
            >
                <p className="mb-[20vh] ml-[8vw] max-md:ml-[10vw] text-[20px] italic font-bold">{t('integrations')}</p>
                <ul x-ref="logos" className="flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll">
                    <li>
                        <img src={ProductGoogleAdsIcon} className="h-auto max-h-[50px] w-auto" alt="Google Ads" />
                    </li>
                    <li>
                        <img src={ProductMetaAdsIcon} className="h-auto max-h-[50px] w-auto" alt="Meta Ads" />
                    </li>
                    <li>
                        <img src={ProductInstagramIcon} className="h-auto max-h-[50px] w-auto" alt="Instagram" />
                    </li>
                    <li>
                        <img src={ProductShopifyIcon} className="h-auto max-h-[50px] w-auto" alt="Shopify" />
                    </li>
                    <li>
                        <img src={ProductTiktokAdsIcon} className="h-auto max-h-[50px] w-auto" alt="TikTok Ads" />
                    </li>
                </ul>
                <ul className="flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll" aria-hidden="true">
                    <li>
                        <img src={ProductGoogleAdsIcon} className="h-auto max-h-[50px] w-auto" alt="Google Ads" />
                    </li>
                    <li>
                        <img src={ProductMetaAdsIcon} className="h-auto max-h-[50px] w-auto" alt="Meta Ads" />
                    </li>
                    <li>
                        <img src={ProductInstagramIcon} className="h-auto max-h-[50px] w-auto" alt="Instagram" />
                    </li>
                    <li>
                        <img src={ProductShopifyIcon} className="h-auto max-h-[50px] w-auto" alt="Shopify" />
                    </li>
                    <li>
                        <img src={ProductTiktokAdsIcon} className="h-auto max-h-[50px] w-auto" alt="TikTok Ads" />
                    </li>
                </ul>
            </div>
            <UserBehaviorSection
                title={t("Create a Quick, Personalized Dashboard in Minutes")}
                subtitle={t("Easily customize your dashboard to suit your unique business needs. Get started quickly and manage your data effortlessly.")}
                videoUrl={personalizedDashboardVideo}
                analyticsTitle={t("Seamless Integration")}
                analyticsDescription={t("Our platform offers seamless integration with your existing systems. Track your key metrics and gain valuable insights in real-time.")}
                interactionsTitle={t("Insightful Analytics")}
                interactionsDescription={t("Stay ahead of the competition with advanced analytics. Monitor performance, identify trends, and make data-driven decisions.")}
            />
            <CampaignMonitorSection
                title={t("Monitor and Compare Campaigns Across Platforms")}
                subtitle={t("Easily monitor and optimize your campaigns on platforms like Google Ads, Meta Ads, and Pinterest.")}
                videoUrl={compareCampaignsVideo}
                analyticsTitle={t("Comprehensive Analytics")}
                analyticsDescription={t("Track ROAS, Conversion Rates, Total Spent, CPC, AOV, and CPM for every campaign.")}
                comparisonTitle={t("Platform Comparison")}
                comparisonDescription={t("Compare campaign performance across Google Ads, Meta Ads, and Pinterest.")}
                exportTitle={t("Export Functionality")}
                exportDescription={t("Export data to XLS format for in-depth analysis and reporting.")}
            />
            <VisitorInsightsSection
                title={t("Berry Pixel")}
                subtitleMain={t("Because Every Click")}
                subtitleEmphasized={t("Tells a Story.")}
                videoUrl={berryPixelVideo}
                buttons={[
                    {
                        title: t('Berry Pixel'),
                        description: t('Integrate Berry Pixel into your online store for deep insights into visitor behavior. Capture every interaction from landing to leaving your site.'),
                        onClick: () => setBerryPixelVideo(BerryPixelVideo)
                    },
                    {
                        title: t('Sales Attribution Model'),
                        description: t('Berry Pixel tracks visitor journeys, helping you identify key sales-driving channels and refine your marketing strategies for better ROI.'),
                        onClick: () => setBerryPixelVideo(SalesAttributionVideo)
                    }
                ]}
            />
            <CampaignMonitorSection
                title={t("Gain Deep Insights with Advanced Analytics")}
                subtitle={t("Analyze your data in depth and gain critical insights for your business. Enhance your performance with comprehensive reporting.")}
                videoUrl={deepInsightsVideo}
                analyticsTitle={t("Optimize Performance:")}
                analyticsDescription={t("Use data-driven insights to optimize your processes and improve efficiency. Ensure your operations are running at peak performance with our detailed analysis.")}
                comparisonTitle={t("Increase ROI")}
                comparisonDescription={t("Leverage in-depth data analysis to enhance your return on investment. Make smarter investments based on comprehensive data insights and analytics.")}
            />
            <div className="h-fit w-full flex flex-col justify-center items-center space-y-8 bg-black">
                <h1 className="text-4xl max-lg:text-3xl mx-auto font-bold text-white">{t('Access from Anywhere')}</h1>
                <div className="flex flex-col gap-4 md:flex-row justify-center items-start w-11/12 mx-auto space-y-4 md:space-y-0 md:space-x-4">
                    <DeviceAccessItem
                        imgSrc={ProductPageAccess1}
                        iconSrc={ProductPagePixelIcon}
                        title={t("Unlimited Device Access")}
                        description={t("Unlimited access to Roasberry from any device. Manage your ad tracking effortlessly, no matter where you are.")}
                    />
                    <DeviceAccessItem
                        imgSrc={ProductPageAccess2}
                        iconSrc={ProductPagePixelIcon}
                        title={t("Mobile App Access")}
                        description={t("Keep track of your campaigns and ad data even on the go. Always stay in control of your advertising strategy.")}
                    />
                </div>
            </div>
            <BottomFooter />
        </div>
    );
};

export default ProductPageDashboard;
