import React, { useRef, useEffect } from 'react';
import ProductSubPagesCheckIcon from "./Images/ProductSubPagesCheckIcon.png";


const CampaignMonitorSection = ({ title, subtitle, videoUrl, analyticsTitle, analyticsDescription, comparisonTitle, comparisonDescription, exportTitle, exportDescription }) => {
    const videoRef = useRef(null);

    useEffect(() => {
        const video = videoRef.current;
        const handleEnded = () => {
            video.play();
        };
        video.addEventListener('ended', handleEnded);
        return () => {
            video.removeEventListener('ended', handleEnded);
        };
    }, []);

    return (
        <div className="h-fit flex flex-col gap-3 md:flex-row justify-between items-start w-11/12 mx-auto space-y-4 space-x-4 md:space-y-0">
            {/* Right side for video */}
            <div className="relative z-10 md:w-1/2 w-full h-auto">
                <div className="w-full h-auto p-2.5 bg-transparent  max-lg:p-1 bg-[#7d0633] rounded-[14.84px] border justify-center items-center inline-flex" style={{ border: '1.24px solid #cf0a54', borderColor: '#cf0a54' }}>
                    <video
                        ref={videoRef}
                        className="w-full h-full rounded-[14.84px] video-controls-on-hover"
                        src={videoUrl}
                        title="Current Video"
                        controls={false}
                        autoPlay
                        loop
                        playsInline
                    />
                </div>
            </div>
            {/* Left side for text content */}
            <div className="text-left text-white md:w-1/2">
                <h1 className="text-4xl font-bold text-white max-lg:text-3xl">

                    {title}</h1>
                <p className="mt-4 text-lg max-lg:text-[16px] ">{subtitle}</p>
                <div className="mt-6 space-y-4">
                    <div className="flex items-start">
                        <img src={ProductSubPagesCheckIcon} className="w-[20px] h-[20px] mr-2" />
                        <div>
                            <h2 className="text-xl font-semibold max-lg:text-[16px] text-[#4ED98B]">{analyticsTitle}</h2>
                            <p className='max-lg:text-[16px]'>{analyticsDescription}</p>
                        </div>
                    </div>
                    <div className="flex items-start">
                        <img src={ProductSubPagesCheckIcon} className="w-[20px] h-[20px] mr-2" />
                        <div>
                            <h2 className="text-xl font-semibold max-lg:text-[16px] text-[#4ED98B]">
                                {comparisonTitle}</h2>
                            <p className='max-lg:text-[16px]'>
                                {comparisonDescription}
                            </p>
                        </div>
                    </div>
                    {exportDescription && (
                        <div className="flex items-start">
                            <img src={ProductSubPagesCheckIcon} className="w-[20px] h-[20px] mr-2" />
                            <div>
                                <h2 className="text-xl font-semibold max-lg:text-[16px] text-[#4ED98B]">
                                    {exportTitle}
                                </h2>
                                <p className='max-lg:text-[16px]'>
                                    {exportDescription}</p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CampaignMonitorSection;
