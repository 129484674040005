import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import roasberryVSData from './roasberryVSData';
import roasberryTableIcon from "./vsroasberryImages/roasberryTableIcon.png";
import checkIcon from "./vsroasberryImages/checkIcon.png";
import noIcon from "./vsroasberryImages/noIcon.png";
import BottomFooter from './bottomfooter.js';
import { useTranslation } from 'react-i18next'; // Import the hook

function VSRoasberryComparison() {
    const { t } = useTranslation(); // Initialize the translation hook

    // Scroll to top when component mounts
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const { dataId } = useParams(); // Get the dataId from the URL
    const selectedData = roasberryVSData.find(data => data.dataId === parseInt(dataId)); // Find the relevant data

    // Fallback in case no data matches the provided ID
    if (!selectedData) {
        return <p className="text-center text-white">{t('comparisonNotFound')}</p>;
    }

    return (
        <>
            <div className="relative w-full h-full flex flex-col gap-[10vh] pt-[10vh] lg:pt-[15vh]">
                {/* Background Layer */}
                <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-[#e30b5c] to-black opacity-50 z-0"></div>
                <div className="relative z-10 h-screen lg: xl:h-3/4">
                    <div className='max-md:hidden flex flex-row w-5/6 justify-between items-center mx-auto gap-[10vw]'>
                        <div className='flex flex-col items-start gap-3'>
                            <p className="text-[64px] text-white font-bold mt-6 mb-4 text-left">
                                {t(selectedData.Title)}
                            </p>
                            <p className="text-lg max-w-3xl mx-auto text-left text-white">
                                {t(selectedData.TitleText1)}
                            </p>
                            <p className="text-lg max-w-3xl mx-auto text-left text-white">
                                {t(selectedData.TitleText2)}
                            </p>
                            <button className="px-6 py-2 border border-[#ffffff] text-white text-lg rounded-full hover:bg-black transition">
                                {t('bookDemo')}
                            </button>
                        </div>
                        <img src={selectedData.TitleImage} alt={t(selectedData.Title)} className="h-fit w-[450px] mx-auto" />
                    </div>
                    <div className='md:hidden flex flex-col w-5/6 justify-between h-fit items-center mx-auto'>
                        <img src={selectedData.TitleImage} alt={t(selectedData.Title)} className="h-fit w-[450px] mx-auto" />
                        <div className='flex flex-col items-center gap-2'>
                            <p className="text-[48px] text-white font-bold mt-6 mb-4 text-center">
                                {t(selectedData.Title)}
                            </p>
                            <p className="text-[16px] max-w-3xl mx-auto text-center text-white">
                                {t(selectedData.TitleText1)}
                            </p>
                            <p className="text-[16px] max-w-3xl mx-auto text-center text-white">
                                {t(selectedData.TitleText2)}
                            </p>
                            <button className="px-6 py-2 border border-[#ffffff] text-white text-lg rounded-full hover:bg-black transition">
                                {t('bookDemo')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Feature Comparison Section */}
            <section className="py-12 px-4 text-center max-md:mt-[30vh]">
                <p className='text-[48px] p-2'>
                    {t(selectedData.Title)}
                </p>
                <p className='text-[20px] text-[#9E9E9E] p-2'>
                    {t("Wouldn't you like to make your ad tracking more effective with Roasberry?")}
                </p>
                <div className="overflow-x-auto">
                    <table className="w-full max-w-5xl mx-auto mt-[3vh] border-seperate bg-[#121212] text-left">
                        <thead>
                            <tr className="bg-[#5F0527] text-white">
                                <th className="p-4 border border-[#222222]">
                                    {t('features')}
                                </th>
                                <th className="p-4 border border-[#222222]">
                                    <img src={roasberryTableIcon} className='h-[22px] w-fit max-md:w-fit max-md:h-fit object-fill' />
                                </th>
                                <th className="p-4 border border-[#222222]">
                                    <img src={selectedData.tableIcon} className='h-[22px] w-fit max-md:w-fit max-md:h-fit object-fill' />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {selectedData.features.map((feature, index) => (
                                <tr key={index} className="border-b border-[#222222]">
                                    {/* Feature Name */}
                                    <td className="p-4 border border-[#222222] text-white">
                                        {t(feature.name)}
                                    </td>

                                    {/* Roasberry Feature */}
                                    <td className="p-4 border border-[#222222] text-white">
                                        {feature.roasberry === "Yes" ? (
                                            <div className='flex flex-row items-center gap-2'>
                                                <img src={checkIcon} className='w-[30px] h-[30px]' alt={t('checkIconAlt')} />
                                                {t(feature.roasberry)}
                                            </div>
                                        ) : (
                                            <div className='flex flex-row items-center gap-2'>
                                                <img src={noIcon} className='w-[30px] h-[30px]' alt={t('noIconAlt')} />
                                                {t(feature.roasberry)}
                                            </div>
                                        )}
                                    </td>

                                    {/* Competitor Feature */}
                                    <td className="p-4 border border-[#222222] text-white">
                                        {dataId === "1" ? (
                                            feature.tripleWhale === "Yes" ? (
                                                <div className='flex flex-row items-center gap-2'>
                                                    <img src={checkIcon} className='w-[30px] h-[30px]' alt={t('checkIconAlt')} />
                                                    {t(feature.tripleWhale)}
                                                </div>
                                            ) : (
                                                <div className='flex flex-row items-center gap-2'>
                                                    <img src={noIcon} className='w-[30px] h-[30px]' alt={t('noIconAlt')} />
                                                    {t(feature.tripleWhale)}
                                                </div>
                                            )
                                        ) : dataId === "2" ? (
                                            feature.northbeam === "Yes" ? (
                                                <div className='flex flex-row items-center gap-2'>
                                                    <img src={checkIcon} className='w-[30px] h-[30px]' alt={t('checkIconAlt')} />
                                                    {t(feature.northbeam)}
                                                </div>
                                            ) : (
                                                <div className='flex flex-row items-center gap-2'>
                                                    <img src={noIcon} className='w-[30px] h-[30px]' alt={t('noIconAlt')} />
                                                    {t(feature.northbeam)}
                                                </div>
                                            )
                                        ) : dataId === "3" ? (
                                            feature.polarAnalytics === "Yes" ? (
                                                <div className='flex flex-row items-center gap-2'>
                                                    <img src={checkIcon} className='w-[30px] h-[30px]' alt={t('checkIconAlt')} />
                                                    {t(feature.polarAnalytics)}
                                                </div>
                                            ) : (
                                                <div className='flex flex-row items-center gap-2'>
                                                    <img src={noIcon} className='w-[30px] h-[30px]' alt={t('noIconAlt')} />
                                                    {t(feature.polarAnalytics)}
                                                </div>
                                            )
                                        ) : dataId === "4" ? (
                                            feature.upstackified === "Yes" ? (
                                                <div className='flex flex-row items-center gap-2'>
                                                    <img src={checkIcon} className='w-[30px] h-[30px]' alt={t('checkIconAlt')} />
                                                    {t(feature.upstackified)}
                                                </div>
                                            ) : (
                                                <div className='flex flex-row items-center gap-2'>
                                                    <img src={noIcon} className='w-[30px] h-[30px]' alt={t('noIconAlt')} />
                                                    {t(feature.upstackified)}
                                                </div>
                                            )
                                        ) : dataId === "5" ? (
                                            feature.kendall === "Yes" ? (
                                                <div className='flex flex-row items-center gap-2'>
                                                    <img src={checkIcon} className='w-[30px] h-[30px]' alt={t('checkIconAlt')} />
                                                    {t(feature.kendall)}
                                                </div>
                                            ) : (
                                                <div className='flex flex-row items-center gap-2'>
                                                    <img src={noIcon} className='w-[30px] h-[30px]' alt={t('noIconAlt')} />
                                                    {t(feature.kendall)}
                                                </div>
                                            )
                                        ) : dataId === "6" ? (
                                            feature.converge === "Yes" ? (
                                                <div className='flex flex-row items-center gap-2'>
                                                    <img src={checkIcon} className='w-[30px] h-[30px]' alt={t('checkIconAlt')} />
                                                    {t(feature.converge)}
                                                </div>
                                            ) : (
                                                <div className='flex flex-row items-center gap-2'>
                                                    <img src={noIcon} className='w-[30px] h-[30px]' alt={t('noIconAlt')} />
                                                    {t(feature.converge)}
                                                </div>
                                            )
                                        ) : null}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </section>
            <BottomFooter />
        </>
    );
}

export default VSRoasberryComparison;
