import React, { useState } from 'react';

function BlogCreator({ onCreate }) {
    const [newsTitle, setNewsTitle] = useState('');
    const [newsText, setNewsText] = useState('');
    const [newsWriter, setNewsWriter] = useState('');
    const [newsPublishedDate, setNewsPublishedDate] = useState('');
    const [newsImage, setNewsImage] = useState('');
    const [newsText1Headline, setNewsText1Headline] = useState('');
    const [newsText11SubHeadline, setNewsText11SubHeadline] = useState('');
    const [newsText1, setNewsText1] = useState('');
    const [newsText12SubHeadline, setNewsText12SubHeadline] = useState('');
    const [newsText2, setNewsText2] = useState('');
    const [newsText2Headline, setNewsText2Headline] = useState('');
    const [newsText3HeadlineImage, setNewsText3HeadlineImage] = useState('');
    const [newsText31SubHeadline, setNewsText31SubHeadline] = useState('');
    const [newsText4Headline, setNewsText4Headline] = useState('');
    const [newsText5Headline, setNewsText5Headline] = useState('');
    const [newsText6, setNewsText6] = useState('');
    const [newsText7, setNewsText7] = useState('');
    const [newsText8, setNewsText8] = useState('');
    const [newsText9, setNewsText9] = useState('');
    const [newsText10, setNewsText10] = useState('');
    const [newsText11, setNewsText11] = useState('');
    const [newsText12, setNewsText12] = useState('');
    const [newsText13, setNewsText13] = useState('');
    const [newsText14, setNewsText14] = useState('');
    const [newsText15, setNewsText15] = useState('');
    const [newsText16, setNewsText16] = useState('');
    const [newsText17, setNewsText17] = useState('');
    const [newsText18, setNewsText18] = useState('');
    const [newsText19, setNewsText19] = useState('');
    const [newsText20, setNewsText20] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        const newBlog = {
            newsId: Date.now(),
            newsTitle,
            newsText,
            newsWriter,
            newsPublishedDate,
            newsImage,
            newsText1Headline,
            newsText11SubHeadline,
            newsText1,
            newsText12SubHeadline,
            newsText2,
            newsText2Headline,
            newsText3HeadlineImage,
            newsText31SubHeadline,
            newsText4Headline,
            newsText5Headline,
            newsText6,
            newsText7,
            newsText8,
            newsText9,
            newsText10,
            newsText11,
            newsText12,
            newsText13,
            newsText14,
            newsText15,
            newsText16,
            newsText17,
            newsText18,
            newsText19,
            newsText20,
        };
        onCreate(newBlog);
    };

    return (
        <div className='mt-[15vh]'>
            <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                <input
                    type="text"
                    placeholder="News Title"
                    value={newsTitle}
                    onChange={(e) => setNewsTitle(e.target.value)}
                    className='bg-transparent border border-[#FFFFFF]'
                />
                <textarea
                    placeholder="News Text"
                    value={newsText}
                    onChange={(e) => setNewsText(e.target.value)}
                    className='bg-transparent border border-[#FFFFFF]'
                />
                <input
                    type="text"
                    placeholder="News Writer"
                    value={newsWriter}
                    onChange={(e) => setNewsWriter(e.target.value)}
                    className='bg-transparent border border-[#FFFFFF]'
                />
                <input
                    type="date"
                    placeholder="Published Date"
                    value={newsPublishedDate}
                    onChange={(e) => setNewsPublishedDate(e.target.value)}
                    className='bg-transparent border border-[#FFFFFF]'
                />
                <input
                    type="text"
                    placeholder="News Image URL"
                    value={newsImage}
                    onChange={(e) => setNewsImage(e.target.value)}
                    className='bg-transparent border border-[#FFFFFF]'
                />
                <input
                    type="text"
                    placeholder="News Text 1 Headline"
                    value={newsText1Headline}
                    onChange={(e) => setNewsText1Headline(e.target.value)}
                    className='bg-transparent border border-[#FFFFFF]'
                />
                <input
                    type="text"
                    placeholder="News Text 1.1 SubHeadline"
                    value={newsText11SubHeadline}
                    onChange={(e) => setNewsText11SubHeadline(e.target.value)}
                    className='bg-transparent border border-[#FFFFFF]'
                />
                <textarea
                    placeholder="News Text 1"
                    value={newsText1}
                    onChange={(e) => setNewsText1(e.target.value)}
                    className='bg-transparent border border-[#FFFFFF]'
                />
                <input
                    type="text"
                    placeholder="News Text 1.2 SubHeadline"
                    value={newsText12SubHeadline}
                    onChange={(e) => setNewsText12SubHeadline(e.target.value)}
                    className='bg-transparent border border-[#FFFFFF]'
                />
                <textarea
                    placeholder="News Text 2"
                    value={newsText2}
                    onChange={(e) => setNewsText2(e.target.value)}
                    className='bg-transparent border border-[#FFFFFF]'
                />
                <input
                    type="text"
                    placeholder="News Text 2 Headline"
                    value={newsText2Headline}
                    onChange={(e) => setNewsText2Headline(e.target.value)}
                    className='bg-transparent border border-[#FFFFFF]'
                />
                <input
                    type="text"
                    placeholder="News Text 3 Headline Image URL"
                    value={newsText3HeadlineImage}
                    onChange={(e) => setNewsText3HeadlineImage(e.target.value)}
                    className='bg-transparent border border-[#FFFFFF]'
                />
                <input
                    type="text"
                    placeholder="News Text 3.1 SubHeadline"
                    value={newsText31SubHeadline}
                    onChange={(e) => setNewsText31SubHeadline(e.target.value)}
                    className='bg-transparent border border-[#FFFFFF]'
                />
                <input
                    type="text"
                    placeholder="News Text 4 Headline"
                    value={newsText4Headline}
                    onChange={(e) => setNewsText4Headline(e.target.value)}
                    className='bg-transparent border border-[#FFFFFF]'
                />
                <input
                    type="text"
                    placeholder="News Text 5 Headline"
                    value={newsText5Headline}
                    onChange={(e) => setNewsText5Headline(e.target.value)}
                    className='bg-transparent border border-[#FFFFFF]'
                />
                <textarea
                    placeholder="News Text 6"
                    value={newsText6}
                    onChange={(e) => setNewsText6(e.target.value)}
                    className='bg-transparent border border-[#FFFFFF]'
                />
                <textarea
                    placeholder="News Text 7"
                    value={newsText7}
                    onChange={(e) => setNewsText7(e.target.value)}
                    className='bg-transparent border border-[#FFFFFF]'
                />
                <textarea
                    placeholder="News Text 8"
                    value={newsText8}
                    onChange={(e) => setNewsText8(e.target.value)}
                    className='bg-transparent border border-[#FFFFFF]'
                />
                <textarea
                    placeholder="News Text 9"
                    value={newsText9}
                    onChange={(e) => setNewsText9(e.target.value)}
                    className='bg-transparent border border-[#FFFFFF]'
                />
                <textarea
                    placeholder="News Text 10"
                    value={newsText10}
                    onChange={(e) => setNewsText10(e.target.value)}
                    className='bg-transparent border border-[#FFFFFF]'
                />
                <textarea
                    placeholder="News Text 11"
                    value={newsText11}
                    onChange={(e) => setNewsText11(e.target.value)}
                    className='bg-transparent border border-[#FFFFFF]'
                />
                <textarea
                    placeholder="News Text 12"
                    value={newsText12}
                    onChange={(e) => setNewsText12(e.target.value)}
                    className='bg-transparent border border-[#FFFFFF]'
                />
                <textarea
                    placeholder="News Text 13"
                    value={newsText13}
                    onChange={(e) => setNewsText13(e.target.value)}
                    className='bg-transparent border border-[#FFFFFF]'
                />
                <textarea
                    placeholder="News Text 14"
                    value={newsText14}
                    onChange={(e) => setNewsText14(e.target.value)}
                    className='bg-transparent border border-[#FFFFFF]'
                />
                <textarea
                    placeholder="News Text 15"
                    value={newsText15}
                    onChange={(e) => setNewsText15(e.target.value)}
                    className='bg-transparent border border-[#FFFFFF]'
                />
                <textarea
                    placeholder="News Text 16"
                    value={newsText16}
                    onChange={(e) => setNewsText16(e.target.value)}
                    className='bg-transparent border border-[#FFFFFF]'
                />
                <textarea
                    placeholder="News Text 17"
                    value={newsText17}
                    onChange={(e) => setNewsText17(e.target.value)}
                    className='bg-transparent border border-[#FFFFFF]'
                />
                <textarea
                    placeholder="News Text 18"
                    value={newsText18}
                    onChange={(e) => setNewsText18(e.target.value)}
                    className='bg-transparent border border-[#FFFFFF]'
                />
                <textarea
                    placeholder="News Text 19"
                    value={newsText19}
                    onChange={(e) => setNewsText19(e.target.value)}
                    className='bg-transparent border border-[#FFFFFF]'
                />
                <textarea
                    placeholder="News Text 20"
                    value={newsText20}
                    onChange={(e) => setNewsText20(e.target.value)}
                    className='bg-transparent border border-[#FFFFFF]'
                />
                <button type="submit">Create Blog</button>
            </form>
        </div>
    );
}

export default BlogCreator;
