import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import AdAnalyticsIcon from "../src/AdAnalyticsIcon.png";
import PixelTrackingIcon from "../src/PixelTrackingIcon.png";
import CustomerJourneysIcon from "../src/CustomerJourneysIcon.png";
import AskBerryIcon from "../src/AskBerryIcon.png";

import { Link, useLocation, useNavigate } from 'react-router-dom';
import CustomerJourneyVideo from "../src/CustomerJourney.mp4";
import PixelTrackingVideo from "../src/PixelTracking.mp4";
import AskBerryVideo from "../src/AskBerry.mp4";

import client1 from "../src/client1.png";
import client2 from "../src/client2.png";
import client3 from "../src/client3.png";
import BottomFooter from "./bottomfooter";
import DemoColumn from "./Chart.js";
import faqplus from "./SVG/faqplus.svg";
import {
    Accordion,
    AccordionHeader,
    AccordionBody,
} from "@material-tailwind/react";
import newBanner from "./Images/newBanner.png";
import newBannerMobile from "./Images/newBannerMobile.png";

import homeConvergeCompare from "./homeCompare/homeConvergeCompare.png";
import homeKendalCompare from "./homeCompare/homeKendalCompare.png";
import homeNorthCompare from "./homeCompare/homeNorthCompare.png";
import homePolarCompare from "./homeCompare/homePolarCompare.png";
import homeTWCompare from "./homeCompare/homeTWCompare.png";
import homeStackCompare from "./homeCompare/homeStackCompare.png";

import merchantsfor from "./SVG/merchantsfor.svg";
import ServicesWeOfferBackgroundSVG from "./SVG/ServicesWeOfferBackground.svg";
import mainBageSectionBG from "./SVG/mainBageSectionBG.svg";
import rightElips from "./SVG/rightElips.svg";
import leftElips from "./SVG/leftElips.svg";
import merchants from "./SVG/merchants.svg";
import mainPageSectionMobileBG from "./SVG/mainPageSectionMobileBG.svg";
function NewHome() {
    const [backgroundImage, setBackgroundImage] = useState(mainBageSectionBG); // Default to desktop image
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) { // max-md corresponds to 768px
                setBackgroundImage(mainPageSectionMobileBG); // Use mobile background
            } else {
                setBackgroundImage(mainBageSectionBG); // Use desktop background
            }
        };

        // Add event listener on window resize
        window.addEventListener('resize', handleResize);

        // Run on component mount
        handleResize();

        // Clean up the event listener on unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    const { t } = useTranslation(); // Initialize useTranslation hook

    const [isScrolled, setIsScrolled] = useState(false);
    const [isScrolledClients, setIsScrolledClients] = useState(false);
    const [isScrolledFaq, setIsScrolledFaq] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            const viewportHeight = window.innerHeight;

            // Adjusting thresholds relative to viewport height
            const scrollThreshold = viewportHeight * 0.1; // 10% of viewport height
            const scrollThresholdClients = viewportHeight * 1.2; // 100% of viewport height
            const scrollThresholdFaq = viewportHeight * 2.1; // 160% of viewport height

            setIsScrolled(scrollTop > scrollThreshold);
            setIsScrolledClients(scrollTop > scrollThresholdClients);
            setIsScrolledFaq(scrollTop > scrollThresholdFaq);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const [selectedButton, setSelectedButton] = useState(1);

    const handleButtonClick = (buttonId) => {
        setSelectedButton(buttonId === selectedButton ? null : buttonId);
    };

    const [selectedButtonFAQ, setSelectedButtonFAQ] = useState(null);
    const buttonClickHandler = (buttonName) => {
        setSelectedButtonFAQ(buttonName === selectedButton ? null : buttonName);
    };
    const [open, setOpen] = useState();

    const handleOpen = (value) => {
        setOpen(open === value ? 0 : value);
    };

    const buttonDetails = [
        {
            id: 1,
            buttonIcon: AdAnalyticsIcon,
            buttonTitle: t("adAnalytics"),
            buttonDescription: t("adAnalyticsDescription"),
            buttonDescriptionDetailed: t("adAnalyticsDetailedDescription"),
            buttonAnimationVideo: "",
            buttonAnimationVideomp4: "",
        },
        {
            id: 2,
            buttonIcon: PixelTrackingIcon,
            buttonTitle: t("pixelTracking"),
            buttonDescription: t("pixelTrackingDescription"),
            buttonDescriptionDetailed: t("pixelTrackingDetailedDescription"),
            buttonAnimationVideo: PixelTrackingVideo,
            buttonAnimationVideomp4: PixelTrackingVideo
        },
        {
            id: 3,
            buttonIcon: CustomerJourneysIcon,
            buttonTitle: t("customerJourneys"),
            buttonDescription: t("customerJourneysDescription"),
            buttonDescriptionDetailed: t("customerJourneysDetailedDescription"),
            buttonAnimationVideo: CustomerJourneyVideo,
            buttonAnimationVideomp4: CustomerJourneyVideo
        },
        {
            id: 4,
            buttonIcon: AskBerryIcon,
            buttonTitle: t("askBerry"),
            buttonDescription: t("askBerryDescription"),
            buttonDescriptionDetailed: t("askBerryDetailedDescription"),
            buttonAnimationVideo: AskBerryVideo,
            buttonAnimationVideomp4: AskBerryVideo
        },
    ];

    const clientDetails = [
        {
            id: 1,
            clientIcon: client1,
            clientDescription: t("clientDescription1"),
        },
        {
            id: 2,
            clientIcon: client2,
            clientDescription: t("clientDescription2"),
        },
        {
            id: 3,
            clientIcon: client3,
            clientDescription: t("clientDescription3"),
        },
    ];
    const faqData = [
        {
            question: "How long does it take to start tracking my ads with Roasberry?",
            answer: "Once you sign up and integrate the necessary platforms, you can begin tracking your ad performance immediately using Roasberry’s interactive dashboards."
        },
        {
            question: "Can I track my ads in real-time?",
            answer: "Yes, Roasberry allows you to track your ad performance in real-time across various platforms, giving you up-to-date insights into your campaigns."
        },
        {
            question: "What advanced metrics are provided to help optimize advertising campaigns?",
            answer: "Roasberry provides a wide range of advanced metrics including ROAS, CPC, CPM, and more, helping you to fine-tune your advertising strategies."
        },
        {
            question: "How does Roasberry integrate with third-party platforms?",
            answer: "Roasberry integrates seamlessly with popular advertising platforms like Google Ads, Facebook Ads, and others through API connections, ensuring smooth data flow and accurate tracking."
        },
        {
            question: "How secure is my data on Roasberry?",
            answer: "Your data is highly secure on Roasberry with robust encryption, regular security audits, and compliance with industry standards to protect your information."
        },
        {
            question: "How can I ensure the best return on investment (ROI) with Roasberry?",
            answer: "Roasberry’s advanced analytics tools allow you to track key performance indicators and adjust your campaigns to maximize ROI effectively."
        },
        {
            question: "How does your platform ensure transparency in tracking ad costs?",
            answer: "Roasberry provides detailed breakdowns of ad costs, helping you understand exactly where your money is going and how your budget is being utilized."
        },
        {
            question: "What type of businesses benefit the most from Roasberry?",
            answer: "Businesses of all sizes, especially those focused on digital marketing and e-commerce, benefit significantly from Roasberry’s comprehensive ad tracking and optimization tools."
        },
        {
            question: "How can I contact Roasberry’s customer support?",
            answer: "You can contact Roasberry’s customer support via email, chat, or phone. Our support team is available 24/7 to assist you with any questions or issues."
        }
    ];


    const [email, setEmail] = useState('');
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
    };

    const servicesRef = useRef(null);

    const scrollToServices = () => {
        servicesRef.current.scrollIntoView({ behavior: "smooth" });
    };

    const handleScheduleDemoClick = () => {
        window.Calendly.initPopupWidget({ url: "https://calendly.com/roasberry/30min" });
        return false;
    };


    useEffect(() => {
        // Add Intercom script dynamically
        const script = document.createElement('script');
        script.src = 'https://widget.intercom.io/widget/d1syjrug';
        script.async = true;
        document.head.appendChild(script);

        script.onload = () => {
            // Initialize Intercom after the script is loaded

            window.Intercom('boot', {
                app_id: 'd1syjrug',
            });

        };

        // Cleanup when the component unmounts or the user logs out
        return () => {
            if (window.Intercom) {
                window.Intercom('shutdown');
            }
        };
    });
    const FeaturesSection = ({ title, subtitle, description, highlightText }) => {
        return (
            <div className="w-11/12 max-md:w-11/12 h-full max-md:h-full flex-col justify-start items-center gap-2 inline-flex my-4">
                {/* "Our Features" Button */}
                <div className="px-4 bg-[#e30b5c] rounded-[51.43px] justify-center items-center inline-flex">
                    <div className="text-white text-lg font-medium font-['Raleway'] leading-[41.66px] max-md:text-[18px]">
                        {title}
                    </div>
                </div>

                {/* "Services We Offer" Heading */}
                <div className="text-center text-white text-[49px] max-md:text-4xl font-normal font-['Raleway'] leading-[58.80px] max-md:leading-[43.20px]">
                    {subtitle}
                </div>

                {/* Description Text */}
                <div className="text-center">
                    <span className="text-[#ececec] max-md:text-[#f1f1f1] text-xl max-md:text-[13px] font-normal font-['Raleway'] leading-7 max-md:leading-[20.80px]">
                        {description}
                    </span>
                    <span className="text-[#56ee99] text-2xl m max-md:text-[13px] font-normal font-['Times New Roman'] italic leading-[33.60px] max-md:leading-relaxed">
                        {" "} {highlightText}
                    </span>
                </div>
            </div>
        );
    };
    const ComparisonCard = ({ title, linkUrl, imgSrc }) => {
        return (
            <div className="w-full lg:h-[96px] max-md:h-[79px] max-md:p-1 bg-[#B8094A] bg-opacity-10 rounded-[10px] shadow border-2 border-[#CF0A54] border-opacity-5 flex justify-center items-center gap-6">
                <img src={imgSrc} className="h-[60px] max-md:h-[51px] object-contain" alt="Comparison Icons" />
                <div className="flex flex-col text-center lg:gap-3">
                    <p className="text-[15px] max-md:text-[12px] text-white">
                        {title}
                    </p>
                    <Link to={linkUrl} className="w-full lg:h-[30px] max-md:h-[25px] p-3 bg-[#CF0A54] text-center text-white/90 text-[15px] font-medium font-['Roboto'] tracking-tight rounded-lg max-md:rounded-[5px] flex justify-center items-center">
                        Learn More
                    </Link>
                </div>
            </div>
        );
    };
    return (
        <div className="w-full h-full bg-black">
            <div className="h-screen w-full flex flex-col items-center justify-center lg:pt-[10vh] max-md:pt-[8vh]"
                style={{
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'initial',
                }}
            >
                <div className="flex flex-col w-full mx-auto mt-auto items-center justify-center">
                    <div className="flex flex-col items-center justify-center max-md:w-full gap-3 max-md:gap-">
                        <a href="https://www.producthunt.com/posts/roasberry?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-roasberry" target="_blank">
                            <img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=488960&theme=neutral" alt="Roasberry - Finally&#0032;an&#0032;accurate&#0044;&#0032;intuitive&#0032;and&#0032;affordable&#0032;pixel&#0032;tracker&#0046; | Product Hunt"
                                style={{ width: "250px", height: "54px", marginInline: "auto" }} />
                        </a>
                        <p className="text-center max-md:text-[13px] lg:text-[20px] xl:text-[20px] 2xl:text-[24px] 
                    border border-[#E30B5C]/20 rounded-full text-[#ECECEC] py-1 px-3">
                            {t('realTimeInsights')}
                        </p>
                        <p className="font-semibold
                      max-md:text-[36px] lg:text-[68px] xl:text-[68px] 2xl:text-[60px]
                     text-center w-11/12 ">
                            {t('betterAdTracking')}
                        </p>
                    </div>
                    <img src={merchantsfor} className="w-2/6 h-full max-md:w-3/4 md:mt-5 2xl:w-1/2" />
                    <div className="flex flex-row w-4/6 gap-6 items-center mx-auto justify-center mt-4 max-md:w-11/12 max-md:flex-col">
                        <button className=" max-md:w-full max-md:h-[50px] text-[18px] max-md:text-[18px] bg-[#CF0A54] px-[33px] pt-3.5 pb-[13px] text-white rounded-[40px] max-md:px-[30px] max-md:py-[10px] hover:bg-[#CF0A56] hover:bg-opacity-60 hover:text-opacity-100"
                            onClick={handleScheduleDemoClick}>
                            {t('bookDemo')}
                        </button>
                        <button className="max-md:w-full items-center max-md:h-[50px] text-white flex flex-col justify-center px-[33px] pt-3.5 pb-[13px] rounded-[40px] border border-[#E30B5C] border-opacity-20 max-md:px-[40px] max-md:py-[10px] hover:border-[transparent] hover:bg-[#000000] hover:bg-opacity-20 text-[18px] max-md:text-[18px] "
                            onClick={scrollToServices}>
                            {t('learnMore')}
                        </button>
                    </div>
                </div>
                <div className="flex flex-col w-full items-center gap-4 mt-auto">
                    <p className="text-[20px]  2xl:text-[20px] text-[#ECECEC]">{t('ourClients')}</p>
                    <div className="bg-[#46041D] w-full bg-opacity-50 lg:h-[100px] flex flex-col justify-center">
                        <img src={merchants} className="w-11/12 h-fit object-fill max-md:hidden mx-auto" />
                        <img src={newBannerMobile} className="w-4/5 h-fit object-fill max-md:w-full md:hidden  " />
                    </div>
                </div>
            </div>
            <div
                ref={servicesRef}
                className="lg:h-screen lg:w-full max-md:h-fit"
                style={{
                    backgroundImage: `url(${ServicesWeOfferBackgroundSVG})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                }}
            >
                <div
                    className={`flex flex-col items-center justify-center lg:h-full lg:py-[5vh] Services-footer ${isScrolled ? 'scrolled' : ''}`}>
                    <FeaturesSection
                        title="Our Features"
                        subtitle="Services We Offer"
                        description="We drive your success with customized, innovative, and efficient solutions tailored to"
                        highlightText="meet your company's unique needs."
                    />
                    <div className="flex flex-col w-10/12 items-center md:hidden">
                        {buttonDetails.map(button => (
                            <div key={button.id}>
                                <button
                                    className={`bg-[#101010] px-4 py-5 w-[full] h-[130px] max-md:px-2 max-md:py-1 flex flex-row rounded-[12px] shadow-custom ${button.id === selectedButton ? 'border border-[#CF0A54] border-opacity-50 - 2px center]' : ''}`}
                                    onClick={() => handleButtonClick(button.id)}
                                >
                                    <div className="w-2/6 ml-1 mt-2">
                                        <img src={button.buttonIcon} className="w-full max-w-[60px] h-auto" alt={button.buttonTitle} />
                                    </div>
                                    <div className="flex flex-col text-left ml-2 mt-2 gap-2">
                                        <p className="max-md:text-[19px] max-lg:text-[19px] max-xl:text-[19px] max-2xl:text-[19px]">
                                            {button.buttonTitle}
                                        </p>
                                        <p className="max-md:text-[13px] max-lg:text-[13px] max-xl:text-[13px] max-2xl:text-[13px]">
                                            {button.buttonDescription}
                                        </p>
                                    </div>
                                </button>
                                {button.id === selectedButton && (
                                    <div className="md:hidden">
                                        <div className="flex flex-row max-md:flex-col justify-between mt-10 items-center w-full">
                                            <div className="relative h-full w-1/3 max-md:w-full">
                                                {button.id === selectedButton && button.id === 1 ? (
                                                    <div className="w-[300px] h-fit mr-4 mx-auto">
                                                        <DemoColumn />
                                                    </div>
                                                ) : (
                                                    <>
                                                        <video
                                                            className="w-full h-full rounded-[14.84px] object-fill p-0 video-controls-on-hover"
                                                            src={button.buttonAnimationVideo}
                                                            title="Current Video"
                                                            controls={false}
                                                            autoPlay
                                                            loop
                                                            playsInline
                                                        />
                                                    </>
                                                )}
                                            </div>
                                            <div className="flex flex-col gap-5 ">
                                                <p className="max-md:text-[23px] lg:text-[23px] xl:text-[28px] 2xl:text-[28px]">
                                                    {button.buttonTitle}
                                                </p>
                                                <p className="max-md:text-[13px] lg:text-[13px] xl:text-[19px] 2xl:text-[19px]">
                                                    {button.buttonDescriptionDetailed}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>

                    <div className="max-md:hidden w-full lg:w-11/12 h-full items-start mx-auto flex flex-row">
                        <div className="w-full lg:w-1/2 flex flex-col items-start gap-4">
                            {buttonDetails.map((button) => (
                                <button
                                    className={`bg-[#101010] px-4 py-2 w-full flex flex-row rounded-[12px] shadow-custom ${button.id === selectedButton ? 'border border-[#CF0A54] border-opacity-50' : ''}`}
                                    onClick={() => handleButtonClick(button.id)}
                                >
                                    <div className="w-2/6 ml-1 mt-2">
                                        <img src={button.buttonIcon} className="w-full max-w-[60px] h-auto" alt={button.buttonTitle} />
                                    </div>
                                    <div className="flex flex-col text-left ml-2 mt-2 gap-2">
                                        <p className="text-[19px]">{button.buttonTitle}</p>
                                        <p className="text-[13px]">{button.buttonDescription}</p>
                                    </div>
                                </button>
                            ))}
                        </div>

                        {/* Right section with detailed content */}
                        <div className="w-3/4 lg:w-full flex flex-col items-center ml-4 h-full">
                            {buttonDetails.map((button) => (
                                <div key={button.id} className="h-full w-full">
                                    {button.id === selectedButton && (
                                        <div className="flex flex-col justify-between items-start w-full gap-3 h-full">
                                            <div className="w-full h-auto p-2.5 bg-transparent bg-[#7d0633] rounded-[14.84px] border border-[#cf0a54]">
                                                {button.id === selectedButton && button.id === 1 ? (
                                                    <div className="flex-grow w-full h-fit">
                                                        <DemoColumn />
                                                    </div>
                                                ) : (
                                                    <video
                                                        ref={button.buttonAnimationVideo.ref}
                                                        className="w-full h-[300px] rounded-[14.84px] video-controls-on-hover object-fit"
                                                        src={button.buttonAnimationVideo}
                                                        title="Current Video"
                                                        controls={false}
                                                        autoPlay
                                                        loop
                                                        playsInline
                                                    />
                                                )}
                                            </div>
                                            <div className="flex flex-col w-full gap-5 ml-5 mt-auto">
                                                <p className="text-[23px] flex flex-row gap-2 items-center">
                                                    <img src={button.buttonIcon} className="w-[40px] h-[40px] object-contain" />
                                                    {button.buttonTitle}
                                                </p>
                                                <p className="text-[13px] lg:text-[16px] xl:text-[16px]">
                                                    {button.buttonDescriptionDetailed}
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-full h-fit lg:min-h-[600px] flex flex-col justify-center items-center my-[5vh]">
                <FeaturesSection
                    title="Compare Us"
                    subtitle="Roasberry vs Other Software"
                    description="Compare us with the software you're currently using and"
                    highlightText="see how we stand out."
                />
                <div className="w-11/12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-y-10 max-md:gap-y-4 gap-x-4">
                    <ComparisonCard
                        title="Roasberry vs Triple Whale"
                        linkUrl="/comparison/1"
                        imgSrc={homeTWCompare}
                    />
                    <ComparisonCard
                        title="Roasberry vs Polar Analytics"
                        linkUrl="/comparison/3"
                        imgSrc={homePolarCompare}
                    />
                    <ComparisonCard
                        title="Roasberry vs Northbeam"
                        linkUrl="/comparison/2"
                        imgSrc={homeNorthCompare}
                    />
                    <ComparisonCard
                        title="Roasberry vs Upstackified"
                        linkUrl="/comparison/4"
                        imgSrc={homeStackCompare}
                    />
                    <ComparisonCard
                        title="Roasberry vs Kendall"
                        linkUrl="/comparison/5"
                        imgSrc={homeKendalCompare}
                    />
                    <ComparisonCard
                        title="Roasberry vs Converge"
                        linkUrl="/comparison/6"
                        imgSrc={homeConvergeCompare}
                    />
                </div>
            </div>

            <div className={`Clients-footer ${isScrolledClients ? 'scrolled' : ''}`}>
                <div className="w-11/12 mx-auto h-fit relative justify-center items-center my-[5vh]">
                    <img
                        src={leftElips}
                        alt="Left Ellipse"
                        className="absolute top-0 left-0 w-[350px] h-[350px] max-md:w-[200px] max-md:h-[200px]"
                    />
                    <img
                        src={rightElips}
                        alt="Right Ellipse"
                        className="absolute bottom-0 right-0 w-[350px] h-[350px] max-md:w-[200px] max-md:h-[200px]"
                    />
                    <div className="h-fit max-md:h-full w-full flex flex-col items-center justify-center my-[5vh]" >
                        <FeaturesSection
                            title="Clients’ Story"
                            subtitle="What Our Clients Say"
                            description="Get inspired by the success stories and experiences shared"
                            highlightText="by our best clients and partners."
                        />
                        <div className="flex flex-col sm:flex-row w-10/12 justify-between gap-8 mt-4 mb-[100px] min-h-[300px]">
                            {clientDetails.map(client => (
                                <div className="flex flex-col p-4 items-start rounded-[20px] border border-[#CF0A54] border-opacity-50 shadow-custom lg:min-h-[300px]" key={client.id}>
                                    <img src={client.clientIcon} alt={client.clientDescription} />
                                    <p className="max-md:text-[13px]">{client.clientDescription}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

            </div>


            <div className={`Faq-footer ${isScrolledFaq ? 'scrolled' : ''}`}>
                <div className="h-fit max-md:h-full w-11/12 mx-auto flex flex-col items-center justify-center bg-black  my-[5vh]">
                    <FeaturesSection
                        title="FAQ"
                        subtitle="Frequently Asked Questions"
                        description="Your go-to guide for answers to commonly asked questions"
                        highlightText="about our products and services."
                    />
                    <div className="flex flex-row max-md:flex-col text-white max-md:w-full mx-auto lg:gap-[5vw] ">
                        <div className="flex flex-col items-start max-md:hidden">
                            <p className="lg:text-6xl">Dashboard</p>
                            <p className="lg:text-6xl">& Features</p>
                        </div>
                        <div className="flex flex-col w-full ">
                            {faqData.map((faq, index) => (
                                <Accordion
                                    key={index}
                                    open={open === index + 1}
                                    className={`mb-4 lg:p-4 max-md:px-2 h-fit max-md:justify-center max-md:items-center rounded-[16px] border border-[#222222] bg-[#0C0C0C] text-white font-regular max-md:text-[16px] ${open === index + 1 ? "border-[#CF0A54] border-opacity-5 bg-[#340214] " : ""}`}
                                >
                                    <AccordionHeader
                                        onClick={() => handleOpen(index + 1)}
                                        className="border-b-0 flex items-center justify-between transition-colors max-md:items-start max-md:h-[88px]"
                                    >
                                        <p className="text-base font-medium max-md:w-10/12">{faq.question}</p>
                                        <img
                                            src={open === index + 1 ? faqplus : faqplus}
                                            className={`w-[24px] ml-auto transition-transform duration-300 ${open === index + 1 ? 'rotate-45' : ''
                                                }`}
                                            alt="Toggle FAQ"
                                        />
                                    </AccordionHeader>
                                    <AccordionBody className="pt-0 text-white font-normal mt-3">
                                        <p className="text-sm max-md:text-[13px]">{faq.answer}</p>
                                    </AccordionBody>
                                </Accordion>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <BottomFooter />
        </div >
    );
}

export default NewHome;
