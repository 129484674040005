import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import MobileNavbar from './MobileNavbar';
import DesktopNavbar from './DesktopNavbar';
import navbarBlog from './Images/navbarBlog.png';
import logoTransparent from './Images/logoTransparent.png';
import { useTranslation } from 'react-i18next';
import newBlogNavbar from "./Images/newBlogNavbar.png";
import "./Navbar.css"
const Navbar = ({ setTopBarHeight }) => {
    const { t, i18n } = useTranslation();
    const [activeLink, setActiveLink] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
    const [productMenuOpen, setProductMenuOpen] = useState(false);
    const location = useLocation();
    const productLinkRef = useRef(null);
    const navigate = useNavigate();

    const getNavbarBackgroundColor = () => {
        if (location.pathname.startsWith('/BlogPage/')) {
            return 'blog-navbar';
        }
        return 'bg-transparent';
    };

    const handleLinkClick = (link) => {
        setActiveLink(link);
    };

    const handleNavbarLinkClick = () => {
        setProductMenuOpen(false); // Close the product menu
        setActiveLink('');
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleScheduleDemoClick = () => {
        window.Calendly.initPopupWidget({ url: "https://calendly.com/roasberry/30min" });
        return false;
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    const handleLanguageChange = (lng) => {
        setSelectedLanguage(lng);
        i18n.changeLanguage(lng);
    };

    const [isVisible, setIsVisible] = useState(true);
    const handleContinue = () => {
        setIsVisible(false);
    };

    const [selectedLanguage, setSelectedLanguage] = useState('en');

    const handleProductClick = () => {
        if (window.innerWidth < 768) { // If mobile view
            setProductMenuOpen(!productMenuOpen);
        } else {
            if (showPopup) {
                setShowPopup(false);
            } else {
                const productLinkRect = productLinkRef.current.getBoundingClientRect();
                const leftPosition = productLinkRect.left + (productLinkRect.width / 2) - (582 / 2); // Center the popup under the link
                setPopupPosition({ top: productLinkRect.bottom, left: leftPosition });
                setShowPopup(true);
            }
        }
    };

    const handleChildLinkClick = (path) => {
        setProductMenuOpen(false);
        navigate(path);
    };

    useEffect(() => {
        if (isVisible) {
            const topBar = document.querySelector('.top-bar');
            if (topBar) {
                setTopBarHeight(topBar.offsetHeight);
            }
        } else {
            setTopBarHeight(0);
        }
    }, [isVisible, setTopBarHeight]);



    const [showComparePopup, setShowComparePopup] = useState(false);
    const compareLinkRef = useRef(null);
    const handleCompareClick = () => {
        if (window.innerWidth < 768) { // If mobile view
            setProductMenuOpen(!productMenuOpen);
        } else {
            if (showComparePopup) {
                setShowComparePopup(false);
            } else {
                if (compareLinkRef.current) {
                    const compareLinkRect = compareLinkRef.current.getBoundingClientRect();
                    const leftPosition = compareLinkRect.left + (compareLinkRect.width / 2) - (582 / 2); // Center the popup under the link
                    setPopupPosition({ top: compareLinkRect.bottom, left: leftPosition });
                    setShowComparePopup(true);
                } else {
                    console.error("compareLinkRef is null");
                }
            }
        }
    };
    const handleCompareClosePopup = () => {
        setShowComparePopup(false);
    };

    return (
        <nav className={`fixed top-0 left-0 w-full z-50 backdrop-blur-sm ${getNavbarBackgroundColor()}`}>
            {isVisible && (
                <div className="flex flex-row max-md:flex-col w-full items-center justify-around h-fit min-h-[4vh] max-md:h-auto p-4 bg-[#0C0C0C]">
                    <div className='flex flex-row w-2/3 max-md:w-5/6 items-center max-md:flex-col max-md:py-2'>
                        <p className="w-5/6 text-[13px]">
                            {t("See content specific to your location and shop online by selecting another country or region.")}
                        </p>
                        <div className="flex flex-row w-full items-center h-1/2 max-md:h-full gap-2">
                            <div className="flex flex-col w-full max-md:w-full">
                                <select
                                    onChange={(e) => handleLanguageChange(e.target.value)}
                                    value={selectedLanguage}
                                    className="bg-[#1A1A1A] text-white p-2 rounded-lg px-4 text-[13px] max-md:h-[40px]"
                                >
                                    <option value="en">English</option>
                                    <option value="tr">Turkish</option>
                                </select>
                            </div>
                            <button
                                onClick={handleContinue}
                                className="py-1.5 px-4 bg-[#CF0A54] w-1/3 text-white rounded-lg text-[13px] max-md:h-[40px]"
                            >
                                {t("Continue")}
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <div className="flex justify-between items-center py-4 h-full bg-opacity-10 bg-black px-6">
                <div className="font-bold text-white min-w-[40px] max-w-[120px]">
                    <Link to="/Home">
                        {location.pathname.startsWith('/BlogPage/') ? (
                            <img src={navbarBlog} alt="Blog Logo" draggable="false" className="w-[160px]" />
                        ) : (
                            <img src={logoTransparent} alt="Logo" draggable="false" className="w-[160px]" />
                        )}
                    </Link>
                </div>
                <MobileNavbar
                    handleLinkClick={handleLinkClick}
                    handleNavbarLinkClick={handleNavbarLinkClick}
                    handleScheduleDemoClick={handleScheduleDemoClick}
                    productLinkRef={productLinkRef}
                    productMenuOpen={productMenuOpen}
                    handleProductClick={handleProductClick}
                    handleChildLinkClick={handleChildLinkClick}
                />
                <DesktopNavbar
                    handleLinkClick={handleLinkClick}
                    handleNavbarLinkClick={handleNavbarLinkClick}
                    handleScheduleDemoClick={handleScheduleDemoClick}
                    handleProductClick={handleProductClick}
                    handleCompareClick={handleCompareClick}
                    handleChildLinkClick={handleChildLinkClick}
                    productLinkRef={productLinkRef}
                    compareLinkRef={compareLinkRef}
                    showPopup={showPopup}
                    popupPosition={popupPosition}
                    showComparePopup={showComparePopup} // Ensure this state is passed
                    handleClosePopup={handleClosePopup}
                    handleCompareClosePopup={handleCompareClosePopup}
                />
            </div>
        </nav>
    );
};

export default Navbar;
