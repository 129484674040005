import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { motion, useScroll, useSpring } from 'framer-motion';
import './BlogPage.css';
import resourcesXIcon from './Images/resourcesXIcon.png';
import resourcesMetaIcon from './Images/resourcesMetaIcon.png';
import resourcesLinkedinIcon from './Images/resourcesLinkedinIcon.png';
import { Link } from 'react-router-dom';

function BlogPage({ blogNewsData }) {
    const { t } = useTranslation();
    const { newsTitle } = useParams();
    const formattedTitle = newsTitle ? newsTitle.replace(/-/g, ' ') : '';
    const blogData = blogNewsData.find(item => item.newsTitle.toLowerCase() === formattedTitle.toLowerCase());

    const { scrollYProgress } = useScroll();
    const scaleX = useSpring(scrollYProgress, {
        stiffness: 100,
        damping: 30,
        restDelta: 0.001
    });

    if (!blogData) {
        return <div>{t('Blog not found')}</div>;
    }

    return (
        <div className="BlogPage">
            <motion.div className="progress-bar" style={{ scaleX }} />
            <div className="BlogPage-Container">
                <div className="BlogPage-Container-Up">
                    <p>{t('Resources - Blog -')} {t(blogData.newsTitle)}</p>
                </div>
                <p style={{ fontSize: "24px" }}>{t(blogData.newsTitle)}</p>
                <div className="BlogPage-Container-Header" style={{ marginBottom: "2%" }}>
                    <p>{t(blogData.NewsWriter)}</p>
                    <p style={{ marginLeft: "3%" }}>{t(blogData.NewsPublishedDate)}</p>
                </div>
                <img src={blogData.newsImage} className="newsImage" draggable="false" alt={t(blogData.newsTitle)} />
                <div className="BlogPage-Container-Text-Card-Section">
                    <div className="BlogPage-Container-Text-Section">
                        <p style={{ marginTop: "1%" }}>{t(blogData.newsText)}</p>
                        {blogData.newsText1Headline && <p style={{ marginTop: "1%" }}>{t(blogData.newsText1Headline)}</p>}
                        {blogData.newsText11SubHeadline && <p style={{ marginTop: "1%" }}>{t(blogData.newsText11SubHeadline)}</p>}
                        {blogData.newsText1 && <p style={{ marginTop: "1%" }}>{t(blogData.newsText1)}</p>}
                        {blogData.newsText12SubHeadline && <p style={{ marginTop: "1%" }}>{t(blogData.newsText12SubHeadline)}</p>}
                        {blogData.newsText2 && <p style={{ marginTop: "1%" }}>{t(blogData.newsText2)}</p>}
                        {blogData.newsText2Headline && <p style={{ marginTop: "1%" }}>{t(blogData.newsText2Headline)}</p>}
                        {blogData.newsText3HeadlineImage && (
                            <img src={blogData.newsText3HeadlineImage} className="newsImage" draggable="false" alt={t(blogData.newsText3Headline)} />
                        )}
                        {blogData.newsText3 && <p style={{ marginTop: "1%" }}>{t(blogData.newsText3)}</p>}
                        {blogData.newsText4 && <p style={{ marginTop: "1%" }}>{t(blogData.newsText4)}</p>}
                        {blogData.newsText5 && <p style={{ marginTop: "1%" }}>{t(blogData.newsText5)}</p>}
                        {blogData.newsText31SubHeadline && <p style={{ marginTop: "1%" }}>{t(blogData.newsText31SubHeadline)}</p>}
                        {blogData.newsText4Headline && <p style={{ marginTop: "1%" }}>{t(blogData.newsText4Headline)}</p>}
                        {blogData.newsText5Headline && <p style={{ marginTop: "1%" }}>{t(blogData.newsText5Headline)}</p>}
                        {blogData.newsText6 && <p style={{ marginTop: "1%" }}>{t(blogData.newsText6)}</p>}
                        {blogData.newsText7 && <p style={{ marginTop: "1%" }}>{t(blogData.newsText7)}</p>}
                        {blogData.newsText8 && <p style={{ marginTop: "1%" }}>{t(blogData.newsText8)}</p>}
                        {blogData.newsText9 && <p style={{ marginTop: "1%" }}>{t(blogData.newsText9)}</p>}
                        {blogData.newsText10 && <p style={{ marginTop: "1%" }}>{t(blogData.newsText10)}</p>}
                        {blogData.newsText11 && <p style={{ marginTop: "1%" }}>{t(blogData.newsText11)}</p>}
                        {blogData.newsText12 && <p style={{ marginTop: "1%" }}>{t(blogData.newsText12)}</p>}
                        {blogData.newsText13 && <p style={{ marginTop: "1%" }}>{t(blogData.newsText13)}</p>}
                        {blogData.newsText14 && <p style={{ marginTop: "1%" }}>{t(blogData.newsText14)}</p>}
                        {blogData.newsText15 && <p style={{ marginTop: "1%" }}>{t(blogData.newsText15)}</p>}
                        {blogData.newsText16 && <p style={{ marginTop: "1%" }}>{t(blogData.newsText16)}</p>}
                        {blogData.newsText17 && <p style={{ marginTop: "1%" }}>{t(blogData.newsText17)}</p>}
                        {blogData.newsText18 && <p style={{ marginTop: "1%" }}>{t(blogData.newsText18)}</p>}
                        {blogData.newsText19 && <p style={{ marginTop: "1%" }}>{t(blogData.newsText19)}</p>}
                        {blogData.newsText20 && <p style={{ marginTop: "1%" }}>{t(blogData.newsText20)}</p>}
                        {blogData.newsText21 && <p style={{ marginTop: "1%" }}>{t(blogData.newsText21)}</p>}
                        {blogData.newsText22 && <p style={{ marginTop: "1%" }}>{t(blogData.newsText22)}</p>}
                        {blogData.newsText23 && <p style={{ marginTop: "1%" }}>{t(blogData.newsText23)}</p>}
                        {blogData.newsText24 && <p style={{ marginTop: "1%" }}>{t(blogData.newsText24)}</p>}
                        {blogData.newsText25 && <p style={{ marginTop: "1%" }}>{t(blogData.newsText25)}</p>}
                        {blogData.newsText26 && <p style={{ marginTop: "1%" }}>{t(blogData.newsText26)}</p>}
                        {blogData.newsText27 && <p style={{ marginTop: "1%" }}>{t(blogData.newsText27)}</p>}
                        {blogData.newsText28 && <p style={{ marginTop: "1%" }}>{t(blogData.newsText28)}</p>}
                        {blogData.newsText29 && <p style={{ marginTop: "1%" }}>{t(blogData.newsText29)}</p>}
                        {blogData.newsText30Headline && <p style={{ marginTop: "1%" }}>{t(blogData.newsText30Headline)}</p>}
                        {blogData.newsText31 && <p style={{ marginTop: "1%" }}>{t(blogData.newsText31)}</p>}
                        {blogData.newsText32 && <p style={{ marginTop: "1%" }}>{t(blogData.newsText32)}</p>}


                    </div>
                    <div className="w-1/3 border border-bg-[#2D2D2D] h-fit p-2 mt-2 max-lg:w-full">
                        <p className="text-gray-900 font-bold text-xl mt-4">
                            {t('Subscribe to')} <br />
                            <span className="text-pink-600">{t('Roasberry’s Email Newsletter!')}</span>
                        </p>
                        <p className="mt-4">
                            {t('Get the latest insights on ad tracking, optimization tips, and industry trends delivered right to your inbox!')}
                        </p>
                        <input
                            className="w-full bg-white text-black border border-gray-700 rounded-full px-4 py-2 mt-10 text-center opacity-50"
                            placeholder={t("Enter your mail")}
                        />
                        <button
                            className="w-full bg-green-500 text-white border-none rounded-full px-4 py-2 mt-4"
                        >
                            {t('Subscribe')}
                        </button>
                        <div className="w-full flex flex-row items-center justify-center gap-2 p-2 mt-2">
                            <Link to="https://www.instagram.com/roasberrycom?igsh=MXA1enZhOXZpd2xhdg==" >
                                <img src={resourcesMetaIcon} className="w-[30px] h-[30px]" alt={t('Meta Icon')} />
                            </Link>
                            <Link to="https://x.com/roasberrycom?s=21&t=QuKCweFoy8SdG0L9_k8zDA" >
                                <img src={resourcesXIcon} className="w-[30px] h-[30px]" alt={t('X Icon')} />
                            </Link>
                            <Link to="https://www.linkedin.com/company/roasberry/" >
                                <img src={resourcesLinkedinIcon} className="w-[30px] h-[30px]" alt={t('LinkedIn Icon')} />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BlogPage;
