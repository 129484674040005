import React, { useEffect, useState } from "react";
import "./Product.css";
import "./pricing.css";
import { BsFillCheckCircleFill } from "react-icons/bs";
import PricingPageLeftColumnImage from "./Images/Pricing.png";
import BottomFooter from "./bottomfooter";
import cancelIcon from "./Images/cancelIcon.png";
import Instantly25 from "./Images/Instantly25.png";
import EarlyBirdIcon from "./Images/EarlyBirdIcon.png";
import ScheduleDemoPopup from "./ScheduleDemoPopup";
import pricingD1 from "./Images/PricingD1.png";
import pricingD2 from "./Images/PricingD2.png";
import pricingD3 from "./Images/PricingD3.png";
import freeTrialButtonNew from "./Images/freeTrialButtonNew.png";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import DiscreteSliderLabel from "./CustomSlider.js";
import searchIcon from "./Images/searchIcon.png";
import adSpentIcon from "./Images/adSpentIcon.png";
import faqIcon from "./Images/faqIcon.png";
import faqPlusIcon from "./Images/faqPlusIcon.png";
import faqMinusIcon from "./Images/faqMinusIcon.png";
import homePageLastImage from "./Images/homePageLastImage.png";
import favicon from "./Images/formIcon.png";
import pricingLine from "../src/pricingLine.png";
import princingNewBackground from "../src/princingNewBackground.png";
import BackgroundImage from "../src/princingNewBackground.png";
import { FaCheck } from "react-icons/fa"; // Assuming you're using react-icons library
import AnimatedSection from "./AnimatedSection";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import { useTranslation } from "react-i18next";

function Newpricing() {
  const { t } = useTranslation();
  const PricingCard = ({ name, title, price, features }) => (
    <div className="max-w-sm mrounded overflow-hidden shadow-lg bg-[#0C0C0C] text-white p-6 mb-4">
      <div className="mb-4">
        <h3 className="text-[24px] font-bold">{name}</h3>
        <p className="text-[14px] font-base whitespace-pre-line"> {title}</p>
      </div>
      <hr className="border-[#222222] border-1 mb-4" />
      <div className="mb-4 flex flex-row items-baseline">
        <p className="text-[32px] font-bold">
          ${isAnnual ? price * 10 : price}
        </p>
        <p className="text-[16px]">/ {isAnnual ? "year" : "month"}</p>
      </div>
      <button
        className="bg-[#CF0A54] hover:bg-pink-700 text-white font-bold py-2 px-4 rounded"
        onClick={() =>
          (window.location.href = "https://roasberrydashboard.web.app/#/Login")
        }
      >
        Start 14-Day Free Trial
      </button>
      <ul className="mt-6 space-y-2">
        {features.map((feature, index) => (
          <li key={index} className="flex items-center">
            <span className="text-[#CF0A54] mr-2">✔</span> {feature}
          </li>
        ))}
      </ul>
    </div>
  );

  const plans = [
    {
      name: "Starter Tier",
      title: "For businesses with annual revenue \nup to $500K.",
      price: 149,
      features: [
        "All-in-One Dashboard",
        "Pixel Tracking",
        "Advanced Marketing Analytics",
        "Automated Reporting",
        "Customer Journeys",
        "Visitor Insights/Logs",
        "Unlimited Devices",
        "Customer Support",
      ],
    },
    {
      name: "Growth Tier",
      title: "For businesses with annual revenue \nbetween $500K and $1M.",
      price: 199,
      features: [
        "All-in-One Dashboard",
        "Pixel Tracking",
        "Advanced Marketing Analytics",
        "Automated Reporting",
        "Customer Journeys",
        "Visitor Insights/Logs",
        "Unlimited Devices",
        "Customer Support",
      ],
    },
    {
      name: "Scale Tier",
      title: "For businesses with annual revenue \nbetween $1M and $5M.",
      price: 299,
      features: [
        "All-in-One Dashboard",
        "Pixel Tracking",
        "Advanced Marketing Analytics",
        "Automated Reporting",
        "Customer Journeys",
        "Visitor Insights/Logs",
        "Unlimited Devices",
        "Customer Support",
      ],
    },
    {
      name: "Enterprise Tier",
      title: "For businesses with annual revenue \nover $5M.",
      price: 499,
      features: [
        "All-in-One Dashboard",
        "Pixel Tracking",
        "Advanced Marketing Analytics",
        "Automated Reporting",
        "Customer Journeys",
        "Visitor Insights/Logs",
        "Unlimited Devices",
        "Customer Support",
      ],
    },
  ];

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };
  const [open, setOpen] = useState();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedButton, setSelectedButton] = useState(null);

  const buttonClickHandler = (buttonName) => {
    setSelectedButton(buttonName === selectedButton ? null : buttonName);
  };

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const [price, setPrice] = useState(149);
  const [isTypeformOpen, setIsTypeformOpen] = useState(true);

  const handleOpenTypeform = () => {
    setIsTypeformOpen(true);
  };

  const handleCloseTypeform = () => {
    setIsTypeformOpen(false);
  };
  const [clickedButton, setClickedButton] = useState("monthly");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    const dropdown = document.getElementById("dropdown");
    dropdown.classList.toggle("hidden");
    setIsDropdownOpen(!isDropdownOpen);
  };

  const [billingInterval, setBillingInterval] = useState("monthly");
  const [isAnnuallyAdjusted, setIsAnnuallyAdjusted] = useState(false);

  const [monthlyPrice, setMonthlyPrice] = useState(149); // Add monthly price state

  const handleTopButtonClick = (interval) => {
    if (interval === "annually" && !isAnnuallyAdjusted) {
      setPrice(price * 10); // Adjust the price for annual billing
      setIsAnnuallyAdjusted(true);
    } else if (interval === "monthly" && isAnnuallyAdjusted) {
      setPrice(monthlyPrice); // Set price to monthly price
      setIsAnnuallyAdjusted(false);
    }
    setBillingInterval(interval);
  };
  const [isAnnual, setIsAnnual] = useState(false);

  return (
    <div className="bg-black w-full h-full">
      <div
        className="flex w-full h-screen max-md:h-auto pt-[10vh] lg:pt-[35vh] text-left justify-center"
        style={{
          backgroundImage: `url(${BackgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
        }}
      >
        <div className="flex flex-col w-11/12 lg:mt-[15vh] xl:mt-[-5vh] items-center justify-center gap-10 max-md:flex-col max-md:items-center">
          <div className="w-full text-center mt-3">
            <span className="text-white text-5xl font-bold leading-[57.60px] font-['Roboto']">
              Revenue - Based Pricing,{" "}
            </span>
            <span className="text-green-600 text-5xl font-bold leading-[57.60px] font-['Roboto']">
              Full Access.
            </span>
          </div>
          <div className="w-full text-center">
            <span className="text-white text-[19px] font-normal leading-relaxed font-['Roboto']">
              We appreciate your interest in our services. Before getting
              started, please note that we offer four different packages, all
              with the same features.
            </span>
            <br />
            <span className="text-white text-[19px] underline font-bold leading-relaxed font-['Roboto']">
              The pricing is based on your estimated total revenue over the last
              12 months in USD.
            </span>
            <br />
            <span className="text-white text-[19px] font-normal leading-relaxed font-['Roboto']">
              To proceed, select a package that best fits your revenue. If you
              have any questions, our team is ready to help.
            </span>
          </div>
          <div>
            <div className="flex justify-center mb-4 max-lg:w-full max-lg:items-center max-lg:justify-center">
              <div className="flex items-center space-x-4 max-lg:w-full max-lg:items-center max-lg:justify-center max-lg:flex-col">
                <p className="text-white">
                  Pay Annually and Get 2 Months Free!
                </p>
                <div className="bg-[#0C0C0C] rounded-full p-1 ">
                  <button
                    onClick={() => setIsAnnual(true)}
                    className={`py-2 px-4 rounded-full ${isAnnual
                      ? "bg-[#CF0A54] text-white"
                      : "bg-[#0C0C0C] text-gray-400"
                      }`}
                  >
                    Annually
                  </button>
                  <button
                    onClick={() => setIsAnnual(false)}
                    className={`py-2 px-4 rounded-full ${!isAnnual
                      ? "bg-[#CF0A54] text-white"
                      : "bg-[#0C0C0C] text-gray-400"
                      }`}
                  >
                    Monthly
                  </button>
                </div>
              </div>
            </div>
            <div className="flex justify-around gap-5 max-lg:flex-col max-lg:gap-3 max-lg:items-center">
              {plans.map((plan, index) => (
                <PricingCard
                  key={index}
                  name={plan.name}
                  title={plan.title}
                  price={plan.price}
                  features={plan.features}
                  isAnnual={isAnnual}
                />
              ))}
            </div>
          </div>
        </div>
      </div>

      <AnimatedSection>
        <div className="flex flex-col mt-[25vh] items-center mb-[10vh]  max-md:mt-[10vh]">
          <p className="max-sm:text-[28px] max-md:text-[28px] lg:text-[33px] xl:text-[40px] 2xl:text-[40px]">
            {t("pricingFAQTitle")}
          </p>
          <p className="w-3/4 text-center max-sm:text-[16px] max-md:text-[16px] lg:text-[16px] xl:text-[19px] 2xl:text-[19px]">
            {t("pricingFAQDescription")}
          </p>
          <div className="w-3/4 mt-[10vh] text-white max-md:w-11/12">
            <Accordion
              open={open === 1}
              className={`mb-7 p-5 rounded-lg px-4 bg-[#0C0C0C] text-white max-md:text-[13px] ${open === 1 ? "border border-[#CF0A54]" : ""
                }`}
            >
              <AccordionHeader
                onClick={() => handleOpen(1)}
                className={`border-b-0  items-center transition-colors ${open === 1 ? "" : ""
                  }`}
              >
                <p className="max-sm:text-[16px] max-md:text-[16px] lg:text-[16px] xl:text-[16px] 2xl:text-[16px]">
                  {t("pricingFAQQuestion1")}
                </p>
                <img
                  src={open === 1 ? faqMinusIcon : faqPlusIcon}
                  className="w-[24px] ml-auto"
                />
              </AccordionHeader>
              <AccordionBody className="pt-0 text-white font-normal w-11/12 mt-3">
                <p className="max-sm:text-[13px] max-md:text-[13px] lg:text-[13px] xl:text-[16px] 2xl:text-[16px]">
                  {t("pricingFAQAnswer1")}
                </p>
              </AccordionBody>
            </Accordion>

            <Accordion
              open={open === 2}
              className={`mb-7 p-5 rounded-lg px-4 bg-[#0C0C0C] text-white max-md:text-[13px] ${open === 2 ? "border border-[#CF0A54]" : ""
                }`}
            >
              <AccordionHeader
                onClick={() => handleOpen(2)}
                className={`border-b-0 transition-colors ${open === 2 ? "" : ""
                  }`}
              >
                <p className="max-sm:text-[16px] max-md:text-[16px] lg:text-[16px] xl:text-[16px] 2xl:text-[16px]">
                  {t("pricingFAQQuestion2")}
                </p>
                <img
                  src={open === 2 ? faqMinusIcon : faqPlusIcon}
                  className="w-[24px] ml-auto"
                />
              </AccordionHeader>
              <AccordionBody className="pt-0 text-white font-normal w-11/12 mt-3">
                <p className="max-sm:text-[13px] max-md:text-[13px] lg:text-[13px] xl:text-[16px] 2xl:text-[16px]">
                  {t("pricingFAQAnswer2")}
                </p>
              </AccordionBody>
            </Accordion>

            <Accordion
              open={open === 3}
              className={`mb-7 p-5 rounded-lg px-4 bg-[#0C0C0C] text-white max-md:text-[13px] ${open === 3 ? "border border-[#CF0A54]" : ""
                }`}
            >
              <AccordionHeader
                onClick={() => handleOpen(3)}
                className={`border-b-0 transition-colors ${open === 3 ? "" : ""
                  }`}
              >
                <p className="max-sm:text-[16px] max-md:text-[16px] lg:text-[16px] xl:text-[16px] 2xl:text-[16px]">
                  {t("pricingFAQQuestion3")}
                </p>
                <img
                  src={open === 3 ? faqMinusIcon : faqPlusIcon}
                  className="w-[24px] ml-auto"
                />
              </AccordionHeader>
              <AccordionBody className="pt-0 text-white font-normal w-11/12 mt-3">
                <p className="max-sm:text-[13px] max-md:text-[13px] lg:text-[13px] xl:text-[16px] 2xl:text-[16px]">
                  {t("pricingFAQAnswer3")}
                </p>
              </AccordionBody>
            </Accordion>

            <Accordion
              open={open === 4}
              className={`mb-7 p-5 rounded-lg px-4 bg-[#0C0C0C] text-white max-md:text-[13px] ${open === 4 ? "border border-[#CF0A54]" : ""
                }`}
            >
              <AccordionHeader
                onClick={() => handleOpen(4)}
                className={`border-b-0 transition-colors ${open === 4 ? " " : ""
                  }`}
              >
                <p className="max-sm:text-[16px] max-md:text-[16px] lg:text-[16px] xl:text-[16px] 2xl:text-[16px]">
                  {t("pricingFAQQuestion4")}
                </p>
                <img
                  src={open === 4 ? faqMinusIcon : faqPlusIcon}
                  className="w-[24px] ml-auto"
                />
              </AccordionHeader>
              <AccordionBody className="pt-0 text-white font-normal w-11/12 mt-3">
                <p className="max-sm:text-[13px] max-md:text-[13px] lg:text-[13px] xl:text-[16px] 2xl:text-[16px]">
                  {t("pricingFAQAnswer4")}
                </p>
              </AccordionBody>
            </Accordion>

            <Accordion
              open={selectedButton === "refund"}
              className={`mb-7 p-5 rounded-lg px-4 bg-[#0C0C0C] text-white max-md:text-[13px] ${selectedButton === "refund" ? "border border-[#CF0A54]" : ""
                }`}
            >
              <AccordionHeader
                onClick={() => buttonClickHandler("refund")}
                className={`border-b-0 transition-colors ${selectedButton === "refund" ? "" : ""
                  }`}
              >
                <p className="max-sm:text-[16px] max-md:text-[16px] lg:text-[16px] xl:text-[16px] 2xl:text-[16px]">
                  {t("pricingFAQQuestionRefund")}
                </p>
                <img
                  src={selectedButton === "refund" ? faqMinusIcon : faqPlusIcon}
                  className="w-[24px] ml-auto"
                  alt="Icon"
                  draggable="false"
                />
              </AccordionHeader>
              <AccordionBody className="pt-0 text-white font-normal w-11/12 mt-3">
                <p className="max-sm:text-[13px] max-md:text-[13px] lg:text-[13px] xl:text-[16px] 2xl:text-[16px]">
                  {t("pricingFAQAnswerRefund")}
                </p>
              </AccordionBody>
            </Accordion>

            <Accordion
              open={selectedButton === "revenue"}
              className={`mb-7 p-5 rounded-lg px-4 bg-[#0C0C0C] text-white max-md:text-[13px] ${selectedButton === "revenue" ? "border border-[#CF0A54]" : ""
                }`}
            >
              <AccordionHeader
                onClick={() => buttonClickHandler("revenue")}
                className={`border-b-0 transition-colors ${selectedButton === "revenue" ? "" : ""
                  }`}
              >
                <p className="max-sm:text-[16px] max-md:text-[16px] lg:text-[16px] xl:text-[16px] 2xl:text-[16px]">
                  {t("pricingFAQQuestionRevenue")}
                </p>
                <img
                  src={
                    selectedButton === "revenue" ? faqMinusIcon : faqPlusIcon
                  }
                  className="w-[24px] ml-auto"
                  alt="Icon"
                  draggable="false"
                />
              </AccordionHeader>
              <AccordionBody className="pt-0 text-white font-normal w-11/12 mt-3">
                <p className="max-sm:text-[13px] max-md:text-[13px] lg:text-[13px] xl:text-[16px] 2xl:text-[16px]">
                  {t("pricingFAQAnswerRevenue")}
                </p>
              </AccordionBody>
            </Accordion>

            <Accordion
              open={selectedButton === "support"}
              className={`mb-7 p-5 rounded-lg px-4 bg-[#0C0C0C] text-white max-md:text-[13px] ${selectedButton === "support" ? "border border-[#CF0A54]" : ""
                }`}
            >
              <AccordionHeader
                onClick={() => buttonClickHandler("support")}
                className={`border-b-0 transition-colors ${selectedButton === "support" ? "" : ""
                  }`}
              >
                <p className="max-sm:text-[16px] max-md:text-[16px] lg:text-[16px] xl:text-[16px] 2xl:text-[16px]">
                  {t("pricingFAQQuestionSupport")}
                </p>
                <img
                  src={
                    selectedButton === "support" ? faqMinusIcon : faqPlusIcon
                  }
                  className="w-[24px] ml-auto"
                  alt="Icon"
                  draggable="false"
                />
              </AccordionHeader>
              <AccordionBody className="pt-0 text-white font-normal w-11/12 mt-3">
                <p className="max-sm:text-[13px] max-md:text-[13px] lg:text-[13px] xl:text-[16px] 2xl:text-[16px]">
                  {t("pricingFAQAnswerSupport")}
                </p>
              </AccordionBody>
            </Accordion>

            <Accordion
              open={selectedButton === "changes"}
              className={`mb-7 p-5 rounded-lg px-4 bg-[#0C0C0C] text-white max-md:text-[13px] ${selectedButton === "changes" ? "border border-[#CF0A54]" : ""
                }`}
            >
              <AccordionHeader
                onClick={() => buttonClickHandler("changes")}
                className={`border-b-0 transition-colors ${selectedButton === "changes" ? "" : ""
                  }`}
              >
                <p className="max-sm:text-[16px] max-md:text-[16px] lg:text-[16px] xl:text-[16px] 2xl:text-[16px]">
                  {t("pricingFAQQuestionChanges")}
                </p>
                <img
                  src={
                    selectedButton === "changes" ? faqMinusIcon : faqPlusIcon
                  }
                  className="w-[24px] ml-auto"
                  alt="Icon"
                  draggable="false"
                />
              </AccordionHeader>
              <AccordionBody className="pt-0 text-white font-normal w-11/12 mt-3">
                <p className="max-sm:text-[13px] max-md:text-[13px] lg:text-[13px] xl:text-[16px] 2xl:text-[16px]">
                  {t("pricingFAQAnswerChanges")}
                </p>
              </AccordionBody>
            </Accordion>
          </div>
        </div>
      </AnimatedSection>

      <BottomFooter />
      {isPopupOpen && <ScheduleDemoPopup onClose={togglePopup} />}
    </div>
  );
}

export default Newpricing;