import React, { useRef, useEffect, useState } from 'react';
import ProductPagePixelIcon from "./Images/ProductPagePixelIcon.png";

const VisitorInsightsSection = ({ title, subtitleMain, subtitleEmphasized, videoUrl, buttons }) => {
    const videoRef = useRef(null);

    useEffect(() => {
        const video = videoRef.current;
        const handleEnded = () => {
            video.play();
        };
        video.addEventListener('ended', handleEnded);
        return () => {
            video.removeEventListener('ended', handleEnded);
        };
    }, []);
    const [isMobile, setIsMobile] = useState(false);
    const [activeButton, setActiveButton] = useState("");
    const handleButtonClick = (index, onClick) => {
        setActiveButton(index);
        if (onClick) {
            onClick();
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 900);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const video = videoRef.current;
        const handleEnded = () => {
            video.play();
        };
        video.addEventListener('ended', handleEnded);
        return () => {
            video.removeEventListener('ended', handleEnded);
        };
    }, []);

    return (
        <div className="h-fit flex flex-col md:flex-row justify-between w-11/12 items-start mx-auto space-y-4 md:space-y-0">
            <div className="relative z-10 w-full mx-auto h-full">
                <div className="text-center mb-8  space-y-2 ">
                    <div className="h-[47px] max-lg:h-fit px-5 py-2.5 rounded-[61.91px] border border-[#e30b5c]/20 justify-start items-center gap-2 inline-flex">
                        <div className="w-6 h-6 relative rounded-[4.80px]">
                            <div className="w-[21.60px] h-[20.60px] left-[1.26px] top-[1.31px] absolute">
                                <img src={ProductPagePixelIcon} className='w-[24px] h-[24px]' alt="Icon" />
                            </div>
                        </div>
                        <div className="text-center text-white text-[19px] font-normal leading-relaxed">{title}</div>
                    </div>
                    <h1 className="text-4xl font-bold text-white max-lg:text-3xl">
                        {subtitleMain}
                        <span className="text-[#4ED98B] italic">{" "}{subtitleEmphasized}</span>
                    </h1>
                </div>
                <div className="flex flex-col max-lg:gap-5 md:flex-row max-lg:w-full justify-between items-start w-full mx-auto">
                    <div className="w-2/3 max-lg:w-full max-lg:h-[200px] h-fit p-[9.89px] max-lg:p-1 bg-[#7d0633] rounded-[14.84px] border justify-center items-center inline-flex" style={{ border: '1.24px solid #cf0a54', borderColor: '#cf0a54' }}>
                        <video
                            ref={videoRef}
                            className="w-full h-full rounded-[14.84px] object-fill p-0 video-controls-on-hover"
                            src={videoUrl}
                            title="Current Video"
                            controls={false}
                            autoPlay
                            loop
                            playsInline
                        />
                    </div>
                    <div className={`flex flex-col w-1/4 h-fit gap-4 max-lg:h-fit max-lg:w-full max-lg:gap-5 justify-between  ${buttons.length === 2 ? 'h-1/2' : 'h-1/2'}`}>
                        {buttons.map((button, index) => (
                            <button
                                key={index}
                                className={`w-full h-auto lg:min-h-[125px] xl:min-h-[140px]  2xl:min-h-[172px] 3xl:min-h-[234px] max-lg:w-full max-lg:h-auto px-6 py-4 bg-[#cf0a54]/20 rounded-2xl border-none flex items-center
                       hover:border-[#cf0a54] hover:border focus:border-[#cf0a54] focus:border"
                                    ${activeButton === index ? 'border-[#cf0a54]' : ''}
                                    hover:border-[#cf0a54] hover:border focus:border-[#cf0a54] focus:border`}
                                onClick={() => handleButtonClick(index, button.onClick)}
                            >
                                <div className="flex items-start gap-4 text-left w-full">
                                    <div className="w-8 h-8 relative">
                                        <img src={ProductPagePixelIcon} alt="Icon" className="w-full h-full" />
                                    </div>
                                    <div className="flex-1 flex-col">
                                        <div className="text-white text-lg font-semibold leading-7">{button.title}</div>
                                        <div className="text-[#ececec] text-base font-medium leading-normal">{button.description}</div>
                                    </div>
                                </div>
                            </button>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VisitorInsightsSection;
