import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { motion, useAnimation } from "framer-motion";
import './Product.css';
import BottomFooter from "./bottomfooter";
import dividerLine from './Images/dividerLine.png';
import ProductGoogleAdsIcon from './Images/ProductGoogleAdsIcon.png';
import ProductMetaAdsIcon from './Images/ProductMetaAdsIcon.png';
import ProductInstagramIcon from './Images/ProductInstagramIcon.png';
import ProductTiktokAdsIcon from './Images/ProductTiktokAdsIcon.png';
import ProductShopifyIcon from './Images/ProductShopifyIcon.png';
import newMockup from './Images/newMockup.png';
import newProductBackground from "../src/newProductBackground.png";
import productCheckIcon from "./productCheckIcon.png";
import flexibleDashboardHeader from "./flexibleDashboardHeader.png";
import askBerryHeader from "./askBerryHeader.png";
import insightsHeader from "./insightsHeader.png";
import image1 from "./image1.png";
import image2 from "./image2.png";
import image3 from "./image3.png";

const easeInOut = { duration: 1.8, ease: "easeInOut" };

const AnimatedSection = ({ children, controls, sectionRef }) => {
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        controls.start({
                            opacity: 1,
                            y: 0,
                            transition: easeInOut,
                        });
                    }
                });
            },
            { threshold: 0.4 }
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, [controls, sectionRef]);

    return (
        <motion.div
            ref={sectionRef}
            initial={{ opacity: 0, y: 50 }}
            animate={controls}
            className="section flex flex-row max-md:flex-col w-full items-center justify-center h-[60vh] max-md:h-fit"
        >
            {children}
        </motion.div>
    );
};

function Product() {
    const { t } = useTranslation();
    const controls1 = useAnimation();
    const controls2 = useAnimation();
    const controls3 = useAnimation();
    const [showText1, setShowText1] = useState(false);
    const [showText2, setShowText2] = useState(false);
    const [showText3, setShowText3] = useState(false);
    const section1Ref = useRef(null);
    const section2Ref = useRef(null);
    const section3Ref = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        if (entry.target === section1Ref.current) {
                            setShowText1(true);
                        } else if (entry.target === section2Ref.current) {
                            setShowText2(true);
                        } else if (entry.target === section3Ref.current) {
                            setShowText3(true);
                        }
                    }
                });
            },
            {
                threshold: 0.5, // Adjust this value based on when you want to trigger the animation
            }
        );

        if (section1Ref.current) observer.observe(section1Ref.current);
        if (section2Ref.current) observer.observe(section2Ref.current);
        if (section3Ref.current) observer.observe(section3Ref.current);

        return () => {
            if (section1Ref.current) observer.unobserve(section1Ref.current);
            if (section2Ref.current) observer.unobserve(section2Ref.current);
            if (section3Ref.current) observer.unobserve(section3Ref.current);
        };
    }, []);

    return (
        <div className="Product-Page w-full">
            <div className="flex flex-col h-[100vh] w-full items-center"
                style={{ backgroundImage: `url(${newProductBackground})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className="flex flex-row max-md:flex-col max-md:pt-[12vh] max-md:w-full max-md:items-center items-start w-10/12 justify-center pt-[20vh]">
                    <div className="flex flex-col w-3/4 max-md:w-11/12 items-start">
                        <p className="w-full max-md:text-[40px] lg:text-[48px] xl:text-[48px] 2xl:text-[48px] font-normal">
                            <span style={{ color: "#15C536" }} className="font-roboto">{t('allInOneMarketingPlatform')}</span>
                        </p>
                        <p className="w-full max-md:text-[16px] lg:text-[19px] xl:text-[19px] 2xl:text-[19px] font-normal">
                            {t('productDescription')}
                        </p>
                    </div>

                    <div className="w-2/3">
                        <img src={newMockup} className="w-full" alt="masahomefooterimage" draggable="false" />
                    </div>
                </div>

                <div
                    x-data="{}"
                    x-init="$nextTick(() => {
                        let ul = $refs.logos;
                        ul.insertAdjacentHTML('afterend', ul.outerHTML);
                        ul.nextSibling.setAttribute('aria-hidden', 'true');
                    })"
                    className="w-full max-h-[200px] mt-auto inline-flex flex-nowrap overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-128px),transparent_100%)]"
                >
                    <p className="mb-[20vh] ml-[8vw] max-md:ml-[10vw] text-[20px] italic font-bold">{t('integrations')}</p>
                    <ul x-ref="logos" className="flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll">
                        <li>
                            <img src={ProductGoogleAdsIcon} className="h-auto max-h-[50px] w-auto" alt="Google Ads" />
                        </li>
                        <li>
                            <img src={ProductMetaAdsIcon} className="h-auto max-h-[50px] w-auto" alt="Meta Ads" />
                        </li>
                        <li>
                            <img src={ProductInstagramIcon} className="h-auto max-h-[50px] w-auto" alt="Instagram" />
                        </li>
                        <li>
                            <img src={ProductShopifyIcon} className="h-auto max-h-[50px] w-auto" alt="Shopify" />
                        </li>
                        <li>
                            <img src={ProductTiktokAdsIcon} className="h-auto max-h-[50px] w-auto" alt="TikTok Ads" />
                        </li>
                    </ul>
                    <ul className="flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll" aria-hidden="true">
                        <li>
                            <img src={ProductGoogleAdsIcon} className="h-auto max-h-[50px] w-auto" alt="Google Ads" />
                        </li>
                        <li>
                            <img src={ProductMetaAdsIcon} className="h-auto max-h-[50px] w-auto" alt="Meta Ads" />
                        </li>
                        <li>
                            <img src={ProductInstagramIcon} className="h-auto max-h-[50px] w-auto" alt="Instagram" />
                        </li>
                        <li>
                            <img src={ProductShopifyIcon} className="h-auto max-h-[50px] w-auto" alt="Shopify" />
                        </li>
                        <li>
                            <img src={ProductTiktokAdsIcon} className="h-auto max-h-[50px] w-auto" alt="TikTok Ads" />
                        </li>
                    </ul>
                </div>
            </div>

            <AnimatedSection controls={controls1} sectionRef={section1Ref}>
                <div className="flex flex-col items-start gap-4 w-1/2 max-md:w-11/12">
                    <img src={flexibleDashboardHeader} className="w-1/3" />
                    <div className={`transition-opacity duration-1000 ease-in-out ${showText1 ? 'opacity-100' : 'opacity-0'}`}>
                        <p className="w-full max-md:text-[23px] lg:text-[28px] xl:text-[28px] 2xl:text-[33px]">
                            {t('createDashboard')}
                        </p>
                        <p className="w-full max-md:text-[13px] lg:text-[13px] xl:text-[13px] 2xl:text-[13px]">
                            {t('dashboardDescription')}
                        </p>
                        <div className="flex flex-row items-center max-md:items-start justify-center gap-3">
                            <img src={productCheckIcon} className="w-[24px] h-auto max-md:w-[16px]" />
                            <p className="w-full max-md:text-[13px] lg:text-[16px] xl:text-[16px] 2xl:text-[19px]">
                                {t('tailorDashboard')}
                            </p>
                        </div>
                        <div className="flex flex-row items-center max-md:items-start justify-center gap-3">
                            <img src={productCheckIcon} className="w-[24px] h-auto max-md:w-[16px]" />
                            <p className="w-full max-md:text-[13px] lg:text-[16px] xl:text-[16px] 2xl:text-[19px]">
                                {t('viewSuccess')}
                            </p>
                        </div>
                        <div className="flex flex-row items-center max-md:items-start justify-center gap-3">
                            <img src={productCheckIcon} className="w-[24px] h-auto max-md:w-[16px]" />
                            <p className="w-full max-md:text-[13px] lg:text-[16px] xl:text-[16px] 2xl:text-[19px]">
                                {t('createMetrics')}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="w-2/5 max-md:w-2/3">
                    <img src={image1} className="w-full" draggable="false" />
                </div>
            </AnimatedSection>

            <AnimatedSection controls={controls2} sectionRef={section2Ref}>
                <div className="w-1/3 max-md:w-2/3">
                    <img src={image2} className="w-full" draggable="false" />
                </div>
                <div className="flex flex-col items-start gap-3 w-1/2 max-md:w-11/12">
                    <img src={askBerryHeader} className="w-1/5" />
                    <div className={`transition-opacity duration-1000 ease-in-out ${showText2 ? 'opacity-100' : 'opacity-0'}`}>
                        <p className="w-full max-md:text-[23px] lg:text-[28px] xl:text-[28px] 2xl:text-[33px]">
                            {t('meetBerry')}
                        </p>
                        <p className="w-full max-md:text-[13px] lg:text-[13px] xl:text-[13px] 2xl:text-[13px]">
                            {t('berryDescription')}
                        </p>
                        <div className="flex flex-row items-center max-md:items-start justify-center gap-3">
                            <img src={productCheckIcon} className="w-[24px] h-auto max-md:w-[16px]" />
                            <p className="w-full max-md:text-[13px] lg:text-[16px] xl:text-[16px] 2xl:text-[19px]">
                                {t('customizeBerry')}
                            </p>
                        </div>
                        <div className="flex flex-row items-center max-md:items-start justify-center gap-3">
                            <img src={productCheckIcon} className="w-[24px] h-auto max-md:w-[16px]" />
                            <p className="w-full max-md:text-[13px] lg:text-[16px] xl:text-[16px] 2xl:text-[19px]">
                                {t('achieveSuccess')}
                            </p>
                        </div>
                        <div className="flex flex-row items-center max-md:items-start justify-center gap-3">
                            <img src={productCheckIcon} className="w-[24px] h-auto max-md:w-[16px]" />
                            <p className="w-full max-md:text-[13px] lg:text-[16px] xl:text-[16px] 2xl:text-[19px]">
                                {t('controlAdTracking')}
                            </p>
                        </div>
                    </div>
                </div>
            </AnimatedSection>

            <img src={dividerLine} className="Product-dividerLine" />

            <AnimatedSection controls={controls3} sectionRef={section3Ref}>
                <div className="flex flex-col items-start gap-4 w-1/2 max-md:w-11/12">
                    <img src={insightsHeader} className="w-1/4" />
                    <div className={`transition-opacity duration-1000 ease-in-out ${showText3 ? 'opacity-100' : 'opacity-0'}`}>
                        <p className="w-full max-md:text-[23px] lg:text-[28px] xl:text-[28px] 2xl:text-[33px]">
                            {t('knowledgeOfCustomers')}
                        </p>
                        <p className="w-full max-md:text-[13px] lg:text-[13px] xl:text-[13px] 2xl:text-[13px]">
                            {t('insightsDescription')}
                        </p>
                        <div className="flex flex-row items-center max-md:items-start justify-center gap-3">
                            <img src={productCheckIcon} className="w-[24px] h-auto max-md:w-[16px]" />
                            <p className="w-full max-md:text-[13px] lg:text-[16px] xl:text-[16px] 2xl:text-[19px]">
                                {t('maximizeCLV')}
                            </p>
                        </div>
                        <div className="flex flex-row items-center max-md:items-start justify-center gap-3">
                            <img src={productCheckIcon} className="w-[24px] h-auto max-md:w-[16px]" />
                            <p className="w-full max-md:text-[13px] lg:text-[16px] xl:text-[16px] 2xl:text-[19px]">
                                {t('identifyValuableProducts')}
                            </p>
                        </div>
                        <div className="flex flex-row items-center max-md:items-start justify-center gap-3">
                            <img src={productCheckIcon} className="w-[24px] h-auto max-md:w-[16px]" />
                            <p className="w-full max-md:text-[13px] lg:text-[16px] xl:text-[16px] 2xl:text-[19px]">
                                {t('defineCustomerSegments')}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="w-2/5 max-md:w-2/3">
                    <img src={image3} className="w-full" draggable="false" />
                </div>
            </AnimatedSection>

            <BottomFooter />
        </div>
    );
}

export default Product;
