import React, { useEffect, useRef } from "react";
import { motion, useAnimation } from "framer-motion";

const easeInOut = { duration: 0.8, ease: "easeInOut" };

const AnimatedSection = ({ children }) => {
    const sectionRef = useRef(null);
    const controls = useAnimation();

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        controls.start({
                            opacity: 1,
                            y: 0,
                            transition: easeInOut,
                        });
                    }
                });
            },
            { threshold: 0.4 }
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, [controls]);

    return (
        <motion.div
            ref={sectionRef}
            initial={{ opacity: 0, y: 50 }}
            animate={controls}
            className="section w-full"
        >
            {children}
        </motion.div>
    );
};

export default AnimatedSection;
