import React from "react";
import "./ScheduleDemoPopup.css";
import PopupGraph from "./Images/PopupGraph.png";
import popupLeftImage from "./Images/popupLeftImage.png";
import mailIcon from "./Images/mailIcon.png";
import phoneIcon from "./Images/phoneIcon.png";
import locationIcon from "./Images/locationIcon.png";
import popupSocialMediaImage from "./Images/Buyuk popupSocialMediaImage.png";

const ScheduleDemoPopup = ({ onClose, isPopupActive }) => {
  return (
    <div className={`popup ${isPopupActive ? "blurred-background" : ""}`}>
      <div className="popup__content">
        <img
          src={popupLeftImage}
          alt="PopupGraph"
          className="scheduledemo-graph-image"
          draggable="false"
        />
        <div className="popup__form">
          <h1>Contact Us!</h1>
          <h2>Get in touch with the Roasberry Team</h2>
          <div className="ContactUs-Icon-Container">
            <img
              src={phoneIcon}
              className="ContactUs-Icon-Container-Icon-Image"
            />
            <p style={{ marginLeft: "3%" }}>+1 (562) - 512 5326</p>
          </div>
          <div className="ContactUs-Icon-Container">
            <img
              src={mailIcon}
              className="ContactUs-Icon-Container-Icon-Image"
            />
            <p style={{ marginLeft: "3%" }}>info@roasberry.com</p>
          </div>
          <div className="ContactUs-Icon-Container">
            <img
              src={locationIcon}
              className="ContactUs-Icon-Container-Icon-Image"
            />
            <p style={{ marginLeft: "3%" }}>
              Mehmet Akif Mah. Okay Cad. Öztürk Apt No:5B Ümraniye/İstanbul
            </p>
          </div>
          <img src={popupSocialMediaImage} style={{ width: "100%" }} />
        </div>

        <span
          className="popup__close-icon"
          onClick={onClose}
          style={{
            color: "white",
            marginTop: "5%",
            marginRight: "5%",
            fontSize: "24px",
          }}
        >
          ✕
        </span>
      </div>
    </div>
  );
};

export default ScheduleDemoPopup;
