import { Column } from '@ant-design/plots';
import React from 'react';
import googleAdsIcon from "./googleAdsIcon.png";
import metaAdsIcon from "./metaAdsIcon.png";


const DemoColumn = () => {
    const [data, setData] = React.useState([]);
    const [selectedDuration, setSelectedDuration] = React.useState("last_7d");

    const generateMockData = (timeFrame) => {
        const today = new Date();
        const endDate = today.getTime();
        let startDate;
        switch (timeFrame) {
            case "today":
                startDate = new Date(today);
                break;
            case "last_7d":
                startDate = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
                break;
            case "last_30d":
                startDate = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);
                break;
            case "last_90d":
                startDate = new Date(today.getTime() - 12 * 24 * 60 * 60 * 1000);
                break;
            default:
                startDate = new Date(today);
        }

        const mockData = [];
        const millisecondsInDay = 24 * 60 * 60 * 1000;

        for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
            const formattedDate = date.toISOString().split('T')[0];
            const googleAdsValue = Math.floor(Math.random() * 1000) + 500;
            const metaAdsValue = Math.floor(Math.random() * 500) + 200;
            mockData.push({ date: formattedDate, value: googleAdsValue, type: "Google Ads" });
            mockData.push({ date: formattedDate, value: metaAdsValue, type: "Meta Ads" });
        }

        return mockData;
    };
    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const googleData = generateMockData(selectedDuration);
                setData(googleData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [selectedDuration]);

    const formatDate = (dateString, timeFrame) => {
        const date = new Date(dateString);
        const options = { day: "numeric", month: "long", year: "numeric" };

        switch (timeFrame) {
            case "today":
                return date.toLocaleDateString("en-US", options); // Display as date
            case "last_7d":
                return `${date.toLocaleDateString("en-US", { month: "long", day: "numeric" })} - 7 Days`;
            case "last_30d":
                return `${date.toLocaleDateString("en-US", { month: "long", day: "numeric" })} - 30 Days`;
            case "last_90d":
                return `${date.toLocaleDateString("en-US", { month: "long", day: "numeric" })} - 90 Days`;
            default:
                return date.toLocaleDateString("en-US", options);
        }
    };

    const handleDurationChange = (value) => {
        setSelectedDuration(value);
    };

    const config = {
        data: data,
        xField: "date",
        yField: "value",
        isStack: true,
        seriesField: "type",
        groupField: "name",
        color: ({ type }) => {
            if (type === "Meta Ads") {
                return "#0081FB";
            } else if (type === "Google Ads") {
                return "green";
            }
        },
        yAxis: {
            label: {
                formatter: (v) => "$ " +
                    `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
                style: {
                    fill: "#FFFFFF",
                },
            },
            nice: true,
            reverse: true,
            grid: {
                line: {
                    style: { stroke: "#3D3D3D" }, // Setting color of gridlines
                },
            },
        },
        xAxis: {
            label: {
                style: { fill: "#FFFFFF" },
                formatter: (date) => formatDate(date),
            },
        },
        tooltip: {
            customContent: (title, items) => {
                return (
                    <div
                        style={{
                            width: "180px",
                            height: "100%",
                            backgroundColor: "#1A1A1A",
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                            padding: "10px",
                            margin: "-20px",
                            borderRadius: "2px",
                            fontSize: "13px",
                            boxShadow: "0 0 5px 2px rgba(255, 255, 255, 0.5)", // Adjusted boxShadow property to white
                        }}
                    >
                        <p style={{ color: "#FFF", margin: "2px" }}>{formatDate(title)}</p>{" "}
                        {/* Render the title */}
                        <ul style={{ padding: "0", margin: "0" }}>
                            {/* Render each data item */}
                            {items.map((item, index) => (
                                <li
                                    key={index}
                                    style={{
                                        padding: "0",
                                        margin: "2px",
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "5px",
                                        alignItems: "center",
                                        justifyContent: "left",
                                    }}
                                >
                                    <div className=" flex flex-row items-center gap-2 mt-2">
                                        {item.name === "Google Ads" ? (
                                            <img
                                                src={googleAdsIcon}
                                                alt="increaseIcon"
                                                className="w-3 h-3"
                                            />
                                        ) : (
                                            <img
                                                src={metaAdsIcon}
                                                alt="increaseIcon"
                                                className="w-3 h-3"
                                            />
                                        )}
                                        <div className="gap-3 ">
                                            <span style={{ color: "#FFF", fontSize: "13px" }}>
                                                {item.name + ": "}
                                            </span>
                                            <span style={{ color: "#FFF", fontSize: "13px" }}>

                                                {parseFloat(item.value)
                                                    .toFixed(2)
                                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                            </span>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                );
            },
        },
        columnWidthRatio: 0.6,
        legend: {
            position: "bottom",
            itemName: { style: { fill: "#FFFFFF" } },
        },
        smooth: true,
        interaction: [{ type: "element-selected", style: { fill: "gray" } }],
    };

    // Inside DemoColumn component
    return (
        <div className="w-full h-full relative"> {/* Set the container to occupy full height and width */}
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "5px",
                }}
            >
                <h3 className="text-xl font-semibold text-white">Ad Spent</h3>
                <select
                    value={selectedDuration}
                    onChange={(e) => handleDurationChange(e.target.value)}
                    style={{
                        backgroundColor: "#1A1A1A",
                        marginLeft: "auto",
                        width: "120px",
                        borderRadius: "4px",
                        padding: "8px",
                        fontSize: "12px",
                        color: "white",
                    }}
                >
                    <option value="today">Today</option>
                    <option value="last_7d">Last 7 days</option>
                    <option value="last_30d">Last 30 days</option>
                    <option value="last_90d">Last 90 days</option>
                </select>
            </div>

            <Column {...config} /> {/* Ensure the chart adapts to the container */}
        </div>
    );

};

export default DemoColumn;
