import React from "react";
import { useEffect, useState } from "react";
import "./Resources.css";
import BottomFooter from "./bottomfooter";
import ScheduleDemoPopup from "./ScheduleDemoPopup";
import infoButton from "./Images//InfoButton.png";
import blogIconAndText from "./Images//BlogIconAndText.png";
import { Link, useNavigate } from "react-router-dom";
import aboutUsHeader from "./Images/Buyuk aboutUsHeader.png";
import resouresBerryIcon from "./Images/resouresBerryIcon.png";
import resourcesTechIcon from "./Images/resourcesTechIcon.png";
import blogNewsData from "./blogNewsData.js";
import { useLocation } from 'react-router-dom';

import MarketingIcon from "./Images/MarketingIcon.png";
import AIIcon from "./Images/AIIcon.png";
import AlgoIcon from "./Images/AlgoIcon.png";
import DataIcon from "./Images/DataIcon.png";

import mailIcon from "./Images/mailIcon.png";
import phoneIcon from "./Images/phoneIcon.png";
import locationIcon from "./Images/locationIcon.png";
import ResourcesForm from "./ResourcesForm.jsx";
import blogheader from "./blogheader.png";
import aboutusImage from "./aboutusImage.png";
import { useTranslation } from "react-i18next";

function ResourcesPage(props) {
  const { t } = useTranslation();

  const [selectedButton, setSelectedButton] = useState("Marketing Metrics");

  const buttonClickHandler = (buttonName) => {
    setSelectedButton(buttonName === selectedButton ? null : buttonName);
  };
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };
  const navigate = useNavigate();

  const [showNavbar, setShowNavbar] = React.useState(false);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  const handleBlogLinkClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };




  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerColumn = 3;

  const indexOfLastItem = currentPage * itemsPerColumn;
  const indexOfFirstItem = indexOfLastItem - itemsPerColumn;
  const currentItems = blogNewsData.slice(indexOfFirstItem, indexOfLastItem);

  const indexOfLastItemTop = blogNewsData.length - 1;
  const topItems = blogNewsData.slice(
    indexOfLastItemTop - 1,
    indexOfLastItemTop + 1
  );

  const totalPages = Math.ceil(blogNewsData.length / itemsPerColumn);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  function copyToClipboard() {
    const emailText = "info@roasberry.com";
    const textArea = document.createElement("textarea");
    textArea.value = emailText;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    const copyIcon = document.getElementById("copyIcon");
    copyIcon.innerText = "Copied!";
    setTimeout(() => {
      copyIcon.innerText = " ⧉";
    }, 2000);
  }

  const algorithmsData = [
    {
      algorithmsId: 1,
      algorithmsIcon: MarketingIcon,
      algorithmsTitle: t("Marketing Metrics"),
      algorithmsDescription: t(
        "Exploring beyond micro marketing measurements and considering the holistic view of various marketing efforts is crucial for brands in eCommerce. It is not sufficient to rely solely on channel-specific results or campaign ROAS when making major marketing decisions. To make the best decisions for your business, it is essential to examine case studies, delve into actionable strategies, and understand the overall customer journey. By doing so, brands can gain valuable insights into how different marketing initiatives contribute to their return on investment."
      ),
    },
    {
      algorithmsId: 2,
      algorithmsIcon: DataIcon,
      algorithmsTitle: t("Data Analytics"),
      algorithmsDescription: t(
        "Through advanced data analytics, the dashboard leverages AI algorithms to analyze vast amounts of customer data, including purchase history, browsing behavior, demographics, and more. These algorithms identify patterns, trends, and correlations that may go unnoticed to human analysts, enabling businesses to make data-driven decisions."
      ),
    },
    {
      algorithmsId: 3,
      algorithmsIcon: AIIcon,
      algorithmsTitle: t("AI & ML Powered Dashboards"),
      algorithmsDescription: t(
        "One of the key features of the AI and ML-powered dashboard is its ability to segment customers based on their preferences, behaviors, and purchase patterns. By segmenting customers effectively, businesses can tailor their marketing messages, promotions, and product recommendations to specific groups, leading to higher conversion rates and personalized experiences."
      ),
    },
    {
      algorithmsId: 4,
      algorithmsIcon: AlgoIcon,
      algorithmsTitle: t("Algorithms"),
      algorithmsDescription: t(
        "The AI-powered dashboard utilizes K-means clustering to automatically identify meaningful customer segments, which can then be visualized and analyzed through intuitive dashboards and visualizations. These segments represent clusters of customers who exhibit similar characteristics or behaviors, allowing businesses to tailor their marketing strategies and offerings accordingly."
      ),
    },
  ];

  const [selectedAlgorithm, setSelectedAlgorithm] = useState(algorithmsData[0]);
  const handleAlgorithmButtonClick = (algorithm) => {
    setSelectedAlgorithm(algorithm);
  };
  useEffect(() => {
    setSelectedAlgorithm(algorithmsData[0]);
  }, []);


  const reactRouterLocation = useLocation();  // Use a different variable name
  useEffect(() => {
    const queryParams = new URLSearchParams(reactRouterLocation.search);
    const scrollTo = queryParams.get('scrollTo');

    if (scrollTo === 'aboutUs') {
      const aboutUsElement = document.getElementById('about-us-section');
      if (aboutUsElement) {
        // Scroll smoothly to the section
        aboutUsElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [reactRouterLocation]);

  return (
    <div className="Resources-Page">
      <div className="ResourcesPage-BlogContainer">
        <img
          src={blogheader}
          className="w-[60px] max-md:pt-[5vh]"
          draggable="false"
        />
        <p className="w-3/4 text-center max-sm:text-[36px] max-md:text-[36px] lg:text-[40px] xl:text-[40px] 2xl:text-[40px] mt-[2vh] ">
          {t("Roasberry News")}
        </p>
        <p
          className="max-md:w-5/6 w-3/4 max-md:text-[13px]"
          style={{ textAlign: "center" }}
        >
          {t("Read about industry trends and stories surrounding digital marketing for e-commerce businesses and understand how Roasberry’s technological developments are helping enterprises position themselves for growth.")}

        </p>
        <div className="blog-CardView">
          {topItems.map((newsItem) => (
            <div className="blog-Card-column" key={newsItem.newsId}>
              <Link to={`/BlogPage/${newsItem.newsTitle.replace(/\s+/g, '-').toLowerCase()}`} key={newsItem.newsId}>
                <div className="blog-Card">
                  <img
                    src={newsItem.newsImage}
                    alt={t(newsItem.newsTitle)}
                    className="newsImage"
                    draggable="false"
                  />
                  <p className="max-md:text-[13px]">{t(newsItem.newsTitle)}</p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      alignItems: "initial",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-evenly",
                        marginRight: "auto",
                      }}
                    >
                      <img
                        src={resouresBerryIcon}
                        className="resourcesBerryIcon"
                        draggable="false"
                        style={{
                          height: "16px",
                          width: "auto",
                          marginRight: "2%",
                        }}
                      />
                      <p className="max-md:text-[13px]">
                        {t(newsItem.NewsWriter)}
                      </p>
                    </div>
                    <p className="max-md:text-[13px]">
                      {t(newsItem.NewsPublishedDate)}
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
        <div className="blog-CardView">
          {currentItems.map((newsItem) => (
            <div
              className="blog-Card-column max-md:text-[13px]"
              key={newsItem.newsId}
            >
              <Link to={`/BlogPage/${newsItem.newsTitle.replace(/\s+/g, '-').toLowerCase()}`} key={newsItem.newsId}>
                <div className="blog-Card">
                  <img
                    src={newsItem.newsImage}
                    alt={t(newsItem.newsTitle)}
                    className="newsImage"
                    draggable="false"
                  />
                  <p className="max-md:text-[13px]">{t(newsItem.newsTitle)}</p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      alignItems: "initial",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-evenly",
                        marginRight: "auto",
                      }}
                    >
                      <img
                        src={resouresBerryIcon}
                        className="resourcesBerryIcon"
                        draggable="false"
                        style={{
                          height: "16px",
                          width: "auto",
                          marginRight: "2%",
                        }}
                      />
                      <p className="max-md:text-[13px]">
                        {t(newsItem.NewsWriter)}
                      </p>
                    </div>
                    <p className="max-md:text-[13px]">
                      {t(newsItem.NewsPublishedDate)}
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>

      <div className="w-full flex flex-col items-center mt-[5vh]">
        <img
          src={resourcesTechIcon}
          className="Resources-Algorithms-Image"
          draggable="false"
        />
        <p
          style={{ textAlign: "center", marginTop: "2%", fontWeight: "bold" }}
          className="max-md:w-11/12 lg:text-[40px] max-md:text-[28px]"
        >
          {t("We are transparent about our technology.")}
        </p>
        <p
          style={{ textAlign: "center", marginTop: "2%" }}
          className="max-md:w-11/12"
        >
          {t(
            "Understand how our process works and what we are doing to skyrocket your ecommerce brand's success."
          )}
        </p>

        <div className="w-11/12 h-fit flex flex-row gap-4 items-start mt-[5vh] justify-center">
          <div className="w-1/3 grid grid-cols-2 max-md:flex max-md:flex-col gap-4">
            {algorithmsData.map((algorithm) => (
              <button
                key={algorithm.algorithmsId} // Ensure algorithmsId is unique
                onClick={() => handleAlgorithmButtonClick(algorithm)}
                className={`Resources-Algorithms-Section-Left-Button bg-[#0C0C0C] rounded-[12px] max-md:[text-13px] text-white text-left flex flex-col h-auto min-h-[100px] items-start p-2 w-full ${selectedAlgorithm &&
                  selectedAlgorithm.algorithmsId === algorithm.algorithmsId
                  ? "selected"
                  : ""
                  }`}
              >
                <img src={algorithm.algorithmsIcon} className="w-[32px]" />
                <p className="max-md:text-[13px]">
                  {algorithm.algorithmsTitle}
                </p>
              </button>
            ))}
          </div>
          <div className="w-1/2">
            {selectedAlgorithm ? (
              <>
                <p className="lg:text-[28px] max-md:text-[19px]">
                  {selectedAlgorithm.algorithmsTitle}
                </p>
                <p className="lg:text-[13px] xl:text-[19px] max-md:text-[13px]">
                  {selectedAlgorithm.algorithmsDescription}
                </p>
              </>
            ) : (
              <>
                <p>{t("No algorithm selected")}</p>
                <p>{t("Please click on an algorithm to see its details.")}</p>
              </>
            )}
          </div>
        </div>
      </div>

      <div id="about-us-section" className="flex flex-col w-full items-center justify-center lg:mt-[10vh]">
        <img
          src={aboutusImage}
          className="w-[60px] max-md:pt-[5vh]"
          draggable="false"
        />
        <p className="w-3/4 text-center max-sm:text-[36px] max-md:text-[36px] lg:text-[40px] xl:text-[40px] 2xl:text-[40px] mt-[2vh] ">
          {t("About Us")}
        </p>
        <div className="aboutUs-Container-Second-Row">
          <div className="aboutUsHeaderFirstRow-RightColumn-Box">
            <p className=" max-md:text-[13px]">
              {t(
                "We are an international marketing intelligence SAAS (Software as a Service) company on a mission to provide cutting-edge solutions for the global marketing industry. While we have a strong presence in the United States and Turkey, our reach extends far beyond these borders."
              )}
            </p>
          </div>
          <div className="aboutUsHeaderFirstRow-RightColumn-Box">
            <p className=" max-md:text-[13px]">
              {t(
                "Our core expertise lies in services such as Pixel Tracking and Data Analysis, essential components of modern marketing strategies. We cater to businesses seeking innovative ways to optimize their marketing efforts, enhance their customer targeting, and drive more effective campaigns."
              )}
            </p>
          </div>

          <div className="aboutUsHeaderFirstRow-RightColumn-Box">
            <p className=" max-md:text-[13px]">
              {t(
                "In today's dynamic and data-driven business environment, our solutions empower companies to harness the power of data analytics and pixel tracking to gain valuable insights into consumer behavior, campaign performance, and market trends. We are also committed to promoting ESG (Environmental, Social, and Governance) principles and EDI (Equity, Diversity, and Inclusion) in the marketing field, ensuring that our clients align with ethical and socially responsible practices."
              )}
            </p>
          </div>
          <div className="aboutUsHeaderFirstRow-RightColumn-Box">
            <p className=" max-md:text-[13px]">
              {t(
                "Our dedication to innovation, ESG, and EDI, combined with our tailored services that cater to the unique demands of markets worldwide, allows us to keep our clients ahead of the competition and make informed decisions to maximize their ROI. We are firmly committed to staying at the forefront of marketing intelligence technology, continuously evolving our offerings to meet the ever-changing needs of our clients. This commitment to excellence, innovation, and customer satisfaction sets us apart as a trusted partner in the world of marketing intelligence."
              )}
            </p>
          </div>
        </div>
      </div>

      <div className="Resources-Contact-Section">
        <div
          className="Resources-Contact-Section-Left"
          style={{
            borderRadius: "12px",
            justifyContent: "space-evenly",
          }}
        >
          <p className=" max-md:text-[13px] lg:text-[23px] mt-[2vh]">
            {t("Contact Information")}
          </p>
          <p className=" max-md:text-[13px] lg:text-[13px]">
            {t("Get in touch with the Roasberry Team")}
          </p>
          <div className="Resources-Icon-Container">
            <img src={phoneIcon} className="Resources-Icon-Container-Image" />
            <p>+1 (562) - 512 5326</p>
          </div>
          <div className="Resources-Icon-Container">
            <img src={mailIcon} className="Resources-Icon-Container-Image" />
            <p>info@roasberry.com</p>
          </div>
          <div className="Resources-Icon-Container">
            <img
              src={locationIcon}
              className="Resources-Icon-Container-Image"
            />
            <p>
              {t(
                "Mehmet Akif Mah. Okay Cad. Öztürk Apt No:5B Ümraniye/İstanbul"
              )}
            </p>
          </div>
        </div>
        <div className="Resources-Contact-Section-Right">
          <form>
            <div className="form-group-container">
              <div class="form-group">
                <label for="firstName">
                  <p>{t("First Name")}</p>
                </label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  required
                  style={{ color: "white", padding: "3px" }}
                />
              </div>
              <div class="form-group">
                <label for="lastName">
                  <p>{t("Last Name")}</p>
                </label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  required
                  style={{ color: "white", padding: "3px" }}
                />
              </div>
            </div>

            <div className="form-group-container">
              <div class="form-group">
                <label for="email">
                  <p>{t("Email")}</p>
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  required
                  style={{ color: "white", padding: "3px" }}
                />
              </div>
              <div class="form-group">
                <label for="phoneNumber">
                  <p>{t("Phone Number")}</p>
                </label>
                <input
                  type="tel"
                  id="phoneNumber"
                  name="phoneNumber"
                  required
                  style={{ color: "white", padding: "3px" }}
                />
              </div>
            </div>

            <div className="radiobutton-container">
              <label>
                <p>{t("Reason for Contact")}</p>
              </label>
              <div className="w-full flex flex-row gap-4 max-md:grid max-md:gird-cols-2">
                <div className="w-full flex flex-row items-baseline">
                  <input
                    type="radio"
                    id="generalInquiry"
                    name="contactReason"
                    value="General Inquiry"
                  />
                  <label for="generalInquiry">
                    <p>{t("General Inquiry")}</p>
                  </label>
                </div>
                <div className="w-full flex flex-row items-baseline">
                  <input
                    type="radio"
                    id="techSupport"
                    name="contactReason"
                    value="Tech Support"
                  />
                  <label for="techSupport">
                    <p>{t("Tech Support")}</p>
                  </label>
                </div>
                <div className="w-full flex flex-row items-baseline">
                  <input
                    type="radio"
                    id="pricing"
                    name="contactReason"
                    value="Pricing"
                  />
                  <label for="pricing">
                    <p>{t("Pricing")}</p>
                  </label>
                </div>
                <div className="w-full flex flex-row items-baseline">
                  <input
                    type="radio"
                    id="careers"
                    name="contactReason"
                    value="Careers & Employment"
                  />
                  <label for="careers">
                    <p>{t("Careers & Employment")}</p>
                  </label>
                </div>
              </div>
            </div>
            <div className="w-full items-center">
              <div className="message-box-container">
                <label for="message">
                  <p style={{ marginTop: "20px" }}>{t("Message")}</p>
                </label>
                <textarea
                  id="message"
                  name="message"
                  rows="4"
                  required
                  style={{ color: "white", padding: "10px", marginTop: "10px" }}
                ></textarea>
              </div>
              <div className="message-box-container-button">
                <button type="submit">{t("Send Message")}</button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <BottomFooter />
      {isPopupOpen && <ScheduleDemoPopup onClose={togglePopup} />}
    </div>
  );
}

export default ResourcesPage;
