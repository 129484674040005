import React, { useEffect, useRef } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import productLogNavIcon from "./Images/productLogNavIcon.png";
import productDashboardNavIcon from "./Images/productDashboardNavIcon.png";
import loginIcon from './Images/loginIcon.png';
import CompareRoasberryConverge from "./vsroasberryImages/CompareRoasberryConverge.png";
import CompareRoasberryKendall from "./vsroasberryImages/CompareRoasberryKendall.png";
import CompareRoasberryNorthbeam from "./vsroasberryImages/CompareRoasberryNorthbeam.png";
import CompareRoasberryPolar from "./vsroasberryImages/CompareRoasberryPolar.png";
import CompareRoasberryTW from "./vsroasberryImages/CompareRoasberryTW.png";
import CompareRoasberryUpstack from "./vsroasberryImages/CompareRoasberryUpstack.png";

const DesktopNavbar = ({
    handleLinkClick,
    handleNavbarLinkClick,
    handleScheduleDemoClick,
    handleProductClick,
    handleCompareClick,
    handleChildLinkClick,
    productLinkRef,
    compareLinkRef,
    showPopup,
    showComparePopup,
    popupPosition,
    handleClosePopup,
    handleCompareClosePopup
}) => {
    const { t } = useTranslation();
    const productPopupRef = useRef(null);
    const comparePopupRef = useRef(null);

    // Effect for handling clicks outside of both popups
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (showPopup && productPopupRef.current && !productPopupRef.current.contains(event.target)) {
                handleClosePopup(); // Close the product popup
            }
            if (showComparePopup && comparePopupRef.current && !comparePopupRef.current.contains(event.target)) {
                handleCompareClosePopup(); // Close the compare popup
            }
        };

        if (showPopup || showComparePopup) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showPopup, showComparePopup, handleClosePopup, handleCompareClosePopup]);

    const ProductPopup = ({ onClose }) => (
        <div ref={productPopupRef} className="absolute" style={{ top: popupPosition.top, left: popupPosition.left }}>
            <div className="relative w-[582px] h-[129px]">
                <div className="absolute left-1/2 transform -translate-x-1/2 -top-2 w-0 h-0 border-l-8 border-l-transparent border-r-8 border-r-transparent border-b-8 border-b-black"></div>
                <div className="bg-black rounded-2xl shadow w-full h-full relative">
                    <div className="absolute top-[11px] left-0 w-full h-[118px]">
                        <div className="absolute flex flex-col justify-start items-start gap-4 left-[30px] top-[41px]">
                            <div className="text-[#ececec] text-xs font-semibold uppercase leading-[18px] tracking-wide">PRODUCT</div>
                            <div className="flex gap-4">
                                <div className="flex h-6 items-center gap-3">
                                    <img src={productDashboardNavIcon} className='w-[24px] h-[24px]' />
                                    <Link to="/dashboard-and-insight-tools" onClick={onClose} className="text-white text-base font-semibold">Dashboard and Insight Tools</Link>
                                </div>
                                <div className="flex h-6 items-center gap-3">
                                    <img src={productLogNavIcon} className='w-[24px] h-[24px]' />
                                    <Link to="/visitor-insights-log" onClick={onClose} className="text-white text-base font-semibold">Visitor Insights/Log</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    const ComparePopup = ({ onClose }) => (
        <div ref={comparePopupRef} className="absolute" style={{ top: popupPosition.top, left: popupPosition.left }}>
            <div className="relative w-[582px] h-[230px]">
                <div className="absolute left-1/2 transform -translate-x-1/2 -top-2 w-0 h-0 border-l-8 border-l-transparent border-r-8 border-r-transparent border-b-8 border-b-black"></div>
                <div className="bg-black rounded-2xl shadow w-full h-full relative">
                    <div className="absolute top-[11px] left-0 w-full h-[118px]">
                        <div className="absolute flex flex-col justify-start items-start gap-4 left-[30px] top-[41px]">
                            <div className="text-[#ececec] text-xs font-semibold uppercase leading-[18px] tracking-wide">COMPARE US</div>
                            <div className="grid grid-cols-2 gap-y-4">
                                <div className="flex flex-row items-center gap-1">
                                    <img src={CompareRoasberryTW} className='w-[40px] h-[24px]' />
                                    <Link to="/comparison/1" onClick={onClose} className="text-white text-base font-semibold">
                                        Roasberry vs Triple Whale
                                    </Link>
                                </div>
                                <div className="flex h-6 items-center gap-1">
                                    <img src={CompareRoasberryPolar} className='w-[40px] h-[24px]' />
                                    <Link to="/comparison/3" onClick={onClose} className="text-white text-base font-semibold">
                                        Roasberry vs Polar Analytics
                                    </Link>
                                </div>
                                <div className="flex h-6 items-center gap-1">
                                    <img src={CompareRoasberryNorthbeam} className='w-[40px] h-[24px]' />
                                    <Link to="/comparison/2" onClick={onClose} className="text-white text-base font-semibold">
                                        Roasberry vs Northbeam
                                    </Link>
                                </div>
                                <div className="flex h-6 items-center gap-1">
                                    <img src={CompareRoasberryUpstack} className='w-[40px] h-[24px]' />
                                    <Link to="/comparison/4" onClick={onClose} className="text-white text-base font-semibold">
                                        Roasberry vs Upstackified
                                    </Link>
                                </div>
                                <div className="flex h-6 items-center gap-1">
                                    <img src={CompareRoasberryKendall} className='w-[40px] h-[24px]' />
                                    <Link to="/comparison/5" onClick={onClose} className="text-white text-base font-semibold">
                                        Roasberry vs Kendall
                                    </Link>
                                </div>
                                <div className="flex h-6 items-center gap-1">
                                    <img src={CompareRoasberryConverge} className='w-[40px] h-[24px]' />
                                    <Link to="/comparison/6" onClick={onClose} className="text-white text-base font-semibold">
                                        Roasberry vs Converge
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <>
            <div className="hidden md:flex justify-between items-center py-4 h-full bg-opacity-10 px-6 w-full">
                <div className="font-bold text-white min-w-[40px] max-w-[120px]"></div>
                <div className="flex-grow flex justify-center">
                    <ul className="flex flex-row gap-8 list-none p-0">
                        <li>
                            <NavLink exact to="/Home" className="no-underline text-[#ECECEC] opacity-60 text-[16px]" activeClassName="text-white opacity-100" onClick={() => { handleLinkClick('Home'); handleNavbarLinkClick(); }}>
                                {t('home')}
                            </NavLink>
                        </li>
                        <li ref={productLinkRef}>
                            <NavLink exact to="#" className="no-underline text-[#ECECEC] opacity-60 text-[16px]" activeClassName="text-white opacity-100" onClick={(e) => { e.preventDefault(); handleLinkClick('Product'); handleProductClick(); }}>
                                {t('product')}
                            </NavLink>
                        </li>
                        <li ref={compareLinkRef}>
                            <NavLink
                                exact
                                to="#"
                                className="no-underline text-[#ECECEC] opacity-60 text-[16px]"
                                activeClassName="text-white opacity-100"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleLinkClick('Compare Us');
                                    handleCompareClick();  // Trigger the compare popup
                                }}
                            >
                                {t('compareUs')}
                            </NavLink>
                        </li>

                        <li>
                            <NavLink exact to="/Pricing" className="no-underline text-[#ECECEC] opacity-60 text-[16px]" activeClassName="text-white opacity-100" onClick={() => { handleLinkClick('Pricing'); handleNavbarLinkClick(); }}>
                                {t('pricing')}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink exact to="/Resources" className="no-underline text-[#ECECEC] opacity-60 text-[16px]" activeClassName="text-white opacity-100" onClick={() => { handleLinkClick('Resources'); handleNavbarLinkClick(); }}>
                                {t('resources')}
                            </NavLink>
                        </li>
                    </ul>
                </div>
                <ul className="flex flex-row gap-8 list-none p-0">
                    <li>
                        <NavLink exact to="https://app.roasberry.com/#/Login" className="text-white text-[16px] no-underline flex items-center gap-2">
                            <img src={loginIcon} className='w-[22px]' alt="Login Icon" />
                            {t('login')}
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact to="" className="text-white text-[16px] no-underline bg-[#CF0A5450] px-6 py-3 border-none rounded-[32px] shadow" onClick={handleScheduleDemoClick}>
                            {t('bookDemo')}
                        </NavLink>
                    </li>
                </ul>
            </div>
            {showPopup && <ProductPopup onClose={handleClosePopup} />}
            {showComparePopup && <ComparePopup onClose={handleCompareClosePopup} />}
        </>
    );
};

export default DesktopNavbar;
