import React from 'react';

const DeviceAccessItem = ({ imgSrc, iconSrc, title, description }) => (
    <div className="flex flex-col w-1/2 min-h-fit max-lg:w-full items-center text-center space-y-4">
        <img src={imgSrc} alt="Device Mockup" className="w-full h-full rounded-[14.84px]" />
        <div className="h-fit w-full pl-6 pr-7 pt-[27px] pb-7 bg-[#cf0a54]/20 rounded-2xl border border-[#cf0a54] justify-start items-center inline-flex">
            <div className="self-stretch justify-start items-start gap-4 inline-flex">
                <img src={iconSrc} className='w-8 h-8' />
                <div className="w-[438px] max-lg:w-full flex-col justify-start items-start gap-1 inline-flex text-left">
                    <div className="self-stretch text-white text-lg max-lg:text-[18px] font-semibold leading-7">{title}</div>
                    <div className="self-stretch text-[#ececec] text-base font-medium leading-normal">{description}</div>
                </div>
            </div>
        </div>
    </div>
);

export default DeviceAccessItem;
