import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: {
                    "home": "Home",
                    "product": "Product",
                    "pricing": "Pricing",
                    "resources": "Resources",
                    "login": "Login",
                    "bookDemo": "Book a Demo",
                    "cookieSettings": "Cookie Settings",
                    "cookieDescription": "We use cookies and similar technologies to help personalize content, tailor and measure ads, and provide a better experience. By clicking accept, you agree to this, as outlined in our Cookie Policy.",
                    "acceptAll": "Accept All",
                    "acceptEssential": "Accept Only Essential Cookies",
                    "welcome": "Welcome",
                    "realTimeInsights": "Real-Time Insights: Empowering Your Decisions with Customer-Driven Data",
                    "betterAdTracking": "Better Ad Tracking For Smarter Decisions",
                    "servicesWeOffer": "Services We Offer",
                    "servicesDescription": "We drive your success with customized, innovative, and efficient solutions tailored to meet your company's unique needs.",
                    "ourClients": "Our Best Clients and Partners",
                    "whatClientsSay": "What Our Clients Say",
                    "clientStories": "Get inspired by the success stories and experiences shared by our best clients and partners.",
                    "faq": "Frequently Asked Questions",
                    "faqDescription": "Your go-to guide for answers to commonly asked questions about our products and services.",
                    "faqQuestion1": "How can I begin tracking ad performance across platforms using your interactive dashboards?",
                    "faqAnswer1": "You can request a demo right away and we will take it from there! Once you are onboarded, different members of your organization can log in using their credentials and customize their home page consisting of interactive dashboards.",
                    "faqQuestion2": "What advanced metrics are provided to help optimize advertising campaigns?",
                    "faqAnswer2": "We provide insights on Cost-per-Click, Cost-per-Acquisition, Click-Through-Rate, ROAS, average order value, lifetime value, average session duration, and Adjusted ROAS which is a Roasberry metric that normalizes the ROAS according to real life events, the overall state of your industry and trends surrounding your products.",
                    "faqQuestion3": "Can you describe the customization options and any automated features for performance reporting?",
                    "faqAnswer3": "You can customize your reports based on time frames, platforms, metrics, and what you want the focal point of the report to be.",
                    "faqQuestion4": "How does your platform ensure transparency in tracking ad costs?",
                    "faqAnswer4": "We provide detailed, granular tracking of all ad costs, including ad spend, platform fees, and any additional expenses. Our advanced metrics and reporting options give you complete visibility into where your ad budget is allocated and how it performs on each platform.",
                    "adAnalytics": "Ad Analytics",
                    "adAnalyticsDescription": "Advanced digital marketing metrics to keep your campaigns accountable.",
                    "adAnalyticsDetailedDescription": "All of your ad campaigns across different platforms together in one easy-to-use dashboard. See the detailed performance metrics of your ads and understand which campaigns are making the most desired impact on your brand. See the real-life effects of your advertising budget and adjust your spending accordingly.",
                    "pixelTracking": "Pixel Tracking",
                    "pixelTrackingDescription": "Maximize insights with comprehensive visitor tracking on your store.",
                    "pixelTrackingDetailedDescription": "Implement the berry pixel to your online store and we record your visitors’ behavior from before they land on your website until they leave. We also detect returning visitors to perform the most informed sales attribution across all channels.",
                    "customerJourneys": "Customer Journeys",
                    "customerJourneysDescription": "Analyze visitor behavior and bounce rates to improve customer experience.",
                    "customerJourneysDetailedDescription": "Visualize the average journey of a visitor on your website and see the bounce rate for every page. Understanding where your visitors spend the most time, which patterns most visitors have while navigating, and which page leads to most checkouts is crucial in enhancing customer experience and hence revenue.",
                    "askBerry": "Ask Berry",
                    "askBerryDescription": "Simplify marketing tasks with crafting concise reports from brand data.",
                    "askBerryDetailedDescription": "Marketing team overworked? We have got you covered. Ask berry is an AI tool designed to analyze your brands data and generate executive summaries and marketing reports of different lengths and detail.",
                    "clientDescription1": "“Roasberry stands out with its intuitive interface simplifying data analysis, offering insights that are unmatched by competitors. With Roasberry, we are able to keep a hold on everything going on in different channels.”",
                    "clientDescription2": "“When it comes to attribution and pixel tracking, Roasberry leads the pack. Its accuracy and intuitive interface streamline data analysis, providing insights that surpass other platforms we tried in the past.”",
                    "clientDescription3": "“Attribution and pixel tracking are perfected by Roasberry. Its precision and user-friendly interface outshine competitors, guiding us towards smarter decisions. With Roasberry, achieving marketing goals is seamless.”",

                    //footer
                    "contactUs": "Contact Us",
                    "careers": "Careers",
                    "refundPolicy": "Refund Policy",
                    "privacyPolicy": "Privacy Policy",
                    "subscribeToNewsletter": "Subscribe to Roasberry’s Newsletter!",
                    "newsletterDescription": "Get the latest insights on ad tracking, optimization tips, and industry trends delivered right to your inbox!",
                    "enterEmail": "Enter Your Email",
                    "subscribe": "Subscribe",
                    "companyInfo": "Roasberry Bilişim Yazılım Geliştirme",
                    "companyAddress": "Mehmet Akif Mah. Okay Cad. Öztürk Apt No:5B Ümraniye/İstanbul",
                    "allRightsReserved": "© 2024 Roasberry Inc. All rights reserved.",

                    //PRODUCT PAGE
                    "allInOneMarketingPlatform": "All-in-One Marketing Platform.",
                    "productDescription": "Roasberry is a digital marketing optimization platform that leverages sophisticated data models to provide contemporary brands with a deep, thorough and customizable analysis regarding their campaigns, enabling them to maximize their ROAS and expand their operations in a profitable manner.",
                    "integrations": "Integrations",
                    "createDashboard": "Create a Quick, Personalized Dashboard in Minutes",
                    "dashboardDescription": "All of Your Metrics Can Be Mixed, Computed, and Analyzed In Real Time.",
                    "tailorDashboard": "Tailor the dashboard page of Roasberry to your requirements and use unlimited look-back to quickly see development over time.",
                    "viewSuccess": "View success using custom audiences created in Roasberry with your own first-party data. To maximize success, draw attention to customer clusters with the highest engagement and AOV.",
                    "createMetrics": "Track success using custom audiences created in Roasberry with your own first-party data",
                    "meetBerry": "Meet Berry, Your 24/7 Ad Tracking Assistant",
                    "berryDescription": "Your Ad Tracking Assistant for Quick and Customized Insights",
                    "customizeBerry": "Customize Berry to perfectly match your requirements and enjoy unlimited historical data access for tracking your long-term progress.",
                    "achieveSuccess": "Achieve success with Berry's powerful capabilities. Utilize your first-party data to create your reporting, supercharge your campaigns, and achieve effortless ROI optimization.",
                    "controlAdTracking": "In just a few clicks, you have 24/7 control over your ad tracking insights from various platforms.",
                    "knowledgeOfCustomers": "Knowledge of Who, What, When, and How",
                    "insightsDescription": "To assist you in finding answers to important questions about your customers and products, Roasberry offers 10+ insights tools.",
                    "maximizeCLV": "Maximize customer lifetime value and profit from the average time between orders to improve customer retention. Learn from your most valuable cohorts.",
                    "identifyValuableProducts": "Identify your most valuable products, advertise top-selling bundles, and rely on key promotions that encourage repeat purchases to elevate your product strategy.",
                    "defineCustomerSegments": "Define customer segments to create personalized experiences, targeted communications, and growth opportunities.",

                    //PRICING PAGE
                    "pricingTitle": "One Price, ",
                    "pricingDescription": "Hello there, we appreciate your interest in our services. To get started, let us know your estimated total revenue over the last 12 months in USD using the expandable menu below. If you have any questions, our team is ready to help.",
                    "annualRevenueQuestion": "What was your total revenue over the last 12 months in USD?",
                    "annualBillingMessage": "Pay Annually and Get 2 Months Free!",
                    "billedMonthly": "Billed Monthly",
                    "billedAnnually": "Billed Annually",
                    "pricingFAQTitle": "Frequently Asked Questions",
                    "pricingFAQDescription": "Your go-to guide for answers to commonly asked questions about our products and services.",
                    "pricingFAQQuestion1": "Is there a free trial available?",
                    "pricingFAQAnswer1": "Yes, of course! We have a 14-Day Free Trial period for everyone who wants to test if our product fits within their needs with no credit card information required.",
                    "pricingFAQQuestion2": "Are there any sign-up fees or other hidden fees?",
                    "pricingFAQAnswer2": "No, there are no additional fees. You will only be charged once every billing period according to your preference.",
                    "pricingFAQQuestion3": "Is there a discount available for annual memberships?",
                    "pricingFAQAnswer3": "Yes, our annually billed customers are receiving two months of service for free.",
                    "pricingFAQQuestion4": "Does my plan allow me to have unlimited users within my company?",
                    "pricingFAQAnswer4": "Yes, our “One price, full package.” approach consists of an enterprise license meaning that every person in your organization can have access to the platform.",
                    "pricingFAQQuestionRefund": "Is there a refund policy?",
                    "pricingFAQAnswerRefund": "Yes, please refer to the footer section of this page.",
                    "pricingFAQQuestionRevenue": "Why am I being asked to share my revenue information?",
                    "pricingFAQAnswerRevenue": "Your revenue data is being used to approximate the data storage costs associated with your company which affects the pricing tier you are in. Other usage of revenue data is strictly prohibited - please refer to the footer section of this page.",
                    "pricingFAQQuestionSupport": "How can I get in touch with your sales support team?",
                    "pricingFAQAnswerSupport": "Feel free to contact us at info@roasberry.com",
                    "pricingFAQQuestionChanges": "What if the revenue tier I belong to changes while using the product?",
                    "pricingFAQAnswerChanges": "First of all, congratulations! Revenue increases are extremely common among Roasberry’s customers. Your plan will be updated at the end of a 12 month period after you sign up, so you can keep using the platform on the same plan for 12 months after signing up.",
                    "pixelTracking": "Pixel Tracking",
                    "allInOneDashboard": "All-in-One Dashboard",
                    "customerJourneys": "Customer Journeys",
                    "automatedReporting": "Automated Reporting",
                    "customerSupport": "Customer Support",
                    "unlimitedDevices": "Unlimited Devices",
                    "advancedMarketingAnalytics": "Advanced Marketing Analytics",
                    "month": "month",
                    "year": "year",
                    "start14DayFreeTrial": "Start 14-Day Free Trial",
                    //RESOURCES PAGE
                    "Roasberry News": "Roasberry News",
                    "We are transparent about our technology.": "We are transparent about our technology.",
                    "Understand how our process works and what we are doing to skyrocket your ecommerce brand's success.": "Understand how our process works and what we are doing to skyrocket your ecommerce brand's success.",
                    "No algorithm selected": "No algorithm selected",
                    "Please click on an algorithm to see its details.": "Please click on an algorithm to see its details.",
                    "About Us": "About Us",
                    "Contact Information": "Contact Information",
                    "Get in touch with the Roasberry Team": "Get in touch with the Roasberry Team",
                    "First Name": "First Name",
                    "Last Name": "Last Name",
                    "Email": "Email",
                    "Phone Number": "Phone Number",
                    "Reason for Contact": "Reason for Contact",
                    "General Inquiry": "General Inquiry",
                    "Tech Support": "Tech Support",
                    "Pricing": "Pricing",
                    "Careers & Employment": "Careers & Employment",
                    "Message": "Message",
                    "Send Message": "Send Message",
                    "learnMore": "Learn More",


                    "Uncover Hidden Profit Potential: Leverage Roasberry's Advanced Data Models to Unlock Growth": "Uncover Hidden Profit Potential: Leverage Roasberry's Advanced Data Models to Unlock Growth",
                    "In the ever-evolving landscape of digital marketing, staying ahead of the competition requires more than just a creative campaign. It demands a deep understanding of your audience, precise targeting, and the ability to adapt swiftly to emerging trends. Roasberry, a cutting-edge digital marketing optimization platform, is revolutionizing the way contemporary brands approach their campaigns. By leveraging sophisticated data models, Roasberry provides a deep, thorough, and customizable analysis, empowering brands to maximize their Return on Ad Spend (ROAS) and unlock untapped growth potential.": "In the ever-evolving landscape of digital marketing, staying ahead of the competition requires more than just a creative campaign. It demands a deep understanding of your audience, precise targeting, and the ability to adapt swiftly to emerging trends. Roasberry, a cutting-edge digital marketing optimization platform, is revolutionizing the way contemporary brands approach their campaigns. By leveraging sophisticated data models, Roasberry provides a deep, thorough, and customizable analysis, empowering brands to maximize their Return on Ad Spend (ROAS) and unlock untapped growth potential.",
                    "Understanding the Power of Roasberry's Advanced Data Models:": "Understanding the Power of Roasberry's Advanced Data Models:",
                    "At the core of Roasberry's success lies its advanced data models, designed to sift through vast amounts of data to extract meaningful insights. These models go beyond conventional analytics, providing a comprehensive understanding of campaign performance, customer behavior, and market trends. The platform's ability to process and analyze data in real-time allows brands to make informed decisions on the fly, ensuring their marketing strategies remain agile and effective.": "At the core of Roasberry's success lies its advanced data models, designed to sift through vast amounts of data to extract meaningful insights. These models go beyond conventional analytics, providing a comprehensive understanding of campaign performance, customer behavior, and market trends. The platform's ability to process and analyze data in real-time allows brands to make informed decisions on the fly, ensuring their marketing strategies remain agile and effective.",
                    "Customizable Analysis for Tailored Solutions:": "Customizable Analysis for Tailored Solutions:",
                    "No two brands are identical, and Roasberry recognizes this diversity. The platform offers a high degree of customization, allowing brands to tailor the analysis to their unique needs and goals. Whether you're focused on lead generation, brand awareness, or e-commerce sales, Roasberry's data models can be fine-tuned to deliver insights that align with your specific objectives. This level of flexibility ensures that the platform is a valuable asset for brands across various industries.": "No two brands are identical, and Roasberry recognizes this diversity. The platform offers a high degree of customization, allowing brands to tailor the analysis to their unique needs and goals. Whether you're focused on lead generation, brand awareness, or e-commerce sales, Roasberry's data models can be fine-tuned to deliver insights that align with your specific objectives. This level of flexibility ensures that the platform is a valuable asset for brands across various industries.",
                    "Maximizing ROAS for Profitable Growth:": "Maximizing ROAS for Profitable Growth:",
                    "Return on Ad Spend (ROAS) is a key metric for measuring the success of digital marketing efforts. Roasberry goes beyond providing a basic ROAS calculation; it dives deep into the factors influencing your ROAS, uncovering opportunities for improvement.": "Return on Ad Spend (ROAS) is a key metric for measuring the success of digital marketing efforts. Roasberry goes beyond providing a basic ROAS calculation; it dives deep into the factors influencing your ROAS, uncovering opportunities for improvement.",
                    "By identifying high-performing channels, optimizing ad creatives, and refining targeting parameters, Roasberry enables brands to maximize their ROAS and achieve sustainable, profitable growth.": "By identifying high-performing channels, optimizing ad creatives, and refining targeting parameters, Roasberry enables brands to maximize their ROAS and achieve sustainable, profitable growth.",
                    "Real-time Insights for Agile Decision-Making:": "Real-time Insights for Agile Decision-Making:",
                    "In the fast-paced world of digital marketing, timely decisions can make all the difference. Roasberry's real-time analytics capabilities ensure that brands have access to the latest insights when they need them most. Whether it's adjusting bidding strategies, reallocating budget to high-performing channels, or pivoting campaign messaging based on emerging trends, Roasberry empowers brands to stay one step ahead of the competition.": "In the fast-paced world of digital marketing, timely decisions can make all the difference. Roasberry's real-time analytics capabilities ensure that brands have access to the latest insights when they need them most. Whether it's adjusting bidding strategies, reallocating budget to high-performing channels, or pivoting campaign messaging based on emerging trends, Roasberry empowers brands to stay one step ahead of the competition.",
                    "Unlocking Growth Opportunities:": "Unlocking Growth Opportunities:",
                    "Roasberry isn't just a tool for optimizing existing campaigns; it's a catalyst for unlocking new growth opportunities. By providing a holistic view of your marketing ecosystem, Roasberry identifies areas where your brand can expand its reach, connect with untapped audiences, and capitalize on emerging market trends. The platform's forward-looking approach positions brands for long-term success in an ever-changing digital landscape.": "Roasberry isn't just a tool for optimizing existing campaigns; it's a catalyst for unlocking new growth opportunities. By providing a holistic view of your marketing ecosystem, Roasberry identifies areas where your brand can expand its reach, connect with untapped audiences, and capitalize on emerging market trends. The platform's forward-looking approach positions brands for long-term success in an ever-changing digital landscape.",
                    "Conclusion:": "Conclusion:",
                    "In the world of digital marketing, where success is measured in clicks and conversions, Roasberry stands out as a game-changer. Its advanced data models, customizable analysis, and focus on maximizing ROAS set it apart as a valuable asset for contemporary brands looking to unlock hidden profit potential and achieve sustainable growth. By leveraging the power of Roasberry, brands can navigate the complexities of the digital landscape with confidence, knowing that they have a partner dedicated to uncovering the insights that will drive their success.": "In the world of digital marketing, where success is measured in clicks and conversions, Roasberry stands out as a game-changer. Its advanced data models, customizable analysis, and focus on maximizing ROAS set it apart as a valuable asset for contemporary brands looking to unlock hidden profit potential and achieve sustainable growth. By leveraging the power of Roasberry, brands can navigate the complexities of the digital landscape with confidence, knowing that they have a partner dedicated to uncovering the insights that will drive their success.",
                    "Gain a Strategic Edge with Roasberry's Customizable Campaign Analysis": "Gain a Strategic Edge with Roasberry's Customizable Campaign Analysis",
                    "In the ever-evolving landscape of digital marketing, staying ahead of the competition requires more than just intuition and creative flair. Brands that aim for sustained success understand the importance of data-driven decision-making. Enter Roasberry, a revolutionary digital marketing optimization platform that empowers contemporary brands with a strategic edge through its sophisticated Customizable Campaign Analysis.": "In the ever-evolving landscape of digital marketing, staying ahead of the competition requires more than just intuition and creative flair. Brands that aim for sustained success understand the importance of data-driven decision-making. Enter Roasberry, a revolutionary digital marketing optimization platform that empowers contemporary brands with a strategic edge through its sophisticated Customizable Campaign Analysis.",
                    "Unlocking the Power of Data:": "Unlocking the Power of Data:",
                    "Roasberry doesn't just provide data; it transforms raw information into actionable insights. Leveraging cutting-edge data models, Roasberry enables brands to dive deep into the intricacies of their campaigns. The platform collects and analyzes a plethora of data points, offering a comprehensive view of the performance metrics that matter most.": "Roasberry doesn't just provide data; it transforms raw information into actionable insights. Leveraging cutting-edge data models, Roasberry enables brands to dive deep into the intricacies of their campaigns. The platform collects and analyzes a plethora of data points, offering a comprehensive view of the performance metrics that matter most.",
                    "Customization for Precision:": "Customization for Precision:",
                    "One size does not fit all in the world of digital marketing, and Roasberry understands this implicitly. The platform's Customizable Campaign Analysis allows brands to tailor their data analysis to align with their unique goals and objectives. Whether you're focused on increasing brand awareness, driving conversions, or optimizing ad spend efficiency, Roasberry adapts to your specific needs.": "One size does not fit all in the world of digital marketing, and Roasberry understands this implicitly. The platform's Customizable Campaign Analysis allows brands to tailor their data analysis to align with their unique goals and objectives. Whether you're focused on increasing brand awareness, driving conversions, or optimizing ad spend efficiency, Roasberry adapts to your specific needs.",
                    "Through Analysis for Informed Decision-Making:": "Through Analysis for Informed Decision-Making:",
                    "The key to successful marketing lies in making informed decisions, and Roasberry equips brands with the tools to do just that. The platform goes beyond surface-level metrics, providing a nuanced and thorough analysis of campaign performance. From customer demographics and behavior patterns to channel effectiveness and creative performance, Roasberry leaves no stone unturned.": "The key to successful marketing lies in making informed decisions, and Roasberry equips brands with the tools to do just that. The platform goes beyond surface-level metrics, providing a nuanced and thorough analysis of campaign performance. From customer demographics and behavior patterns to channel effectiveness and creative performance, Roasberry leaves no stone unturned.",
                    "Maximizing ROAS for Sustainable Growth:": "Maximizing ROAS for Sustainable Growth:",
                    "Return on Ad Spend (ROAS) is the heartbeat of any successful marketing campaign, and Roasberry understands its pivotal role. By delving into the intricacies of your campaigns, the platform identifies areas of improvement and optimization, allowing brands to maximize their ROAS. This not only enhances short-term profitability but also lays the foundation for sustainable, long-term growth.": "Return on Ad Spend (ROAS) is the heartbeat of any successful marketing campaign, and Roasberry understands its pivotal role. By delving into the intricacies of your campaigns, the platform identifies areas of improvement and optimization, allowing brands to maximize their ROAS. This not only enhances short-term profitability but also lays the foundation for sustainable, long-term growth.",
                    "Expanding Operations with Confidence:": "Expanding Operations with Confidence:",
                    "Armed with the insights gained from Roasberry's Customizable Campaign Analysis, brands can confidently expand their operations. Whether entering new markets, scaling existing campaigns, or diversifying advertising channels, Roasberry's data-driven approach provides a solid foundation for strategic decision-making.": "Armed with the insights gained from Roasberry's Customizable Campaign Analysis, brands can confidently expand their operations. Whether entering new markets, scaling existing campaigns, or diversifying advertising channels, Roasberry's data-driven approach provides a solid foundation for strategic decision-making.",
                    "Real Results, Real Impact:": "Real Results, Real Impact:",
                    "Roasberry isn't just a tool; it's a partner in your journey towards marketing excellence. By harnessing the power of data, customization, and thorough analysis, Roasberry empowers brands to achieve real results and make a tangible impact on their bottom line.": "Roasberry isn't just a tool; it's a partner in your journey towards marketing excellence. By harnessing the power of data, customization, and thorough analysis, Roasberry empowers brands to achieve real results and make a tangible impact on their bottom line.",
                    "In conclusion, in a digital landscape where precision and adaptability are paramount, Roasberry stands out as a beacon of innovation. Gain a strategic edge with Roasberry's Customizable Campaign Analysis and propel your brand towards unprecedented success in the dynamic world of digital marketing. Your data-driven future starts here.": "In conclusion, in a digital landscape where precision and adaptability are paramount, Roasberry stands out as a beacon of innovation. Gain a strategic edge with Roasberry's Customizable Campaign Analysis and propel your brand towards unprecedented success in the dynamic world of digital marketing. Your data-driven future starts here.",
                    "Unleash your Marketing Potential: Explore the Untapped Opportunities with Roasberry's Comprehensive Analysis": "Unleash your Marketing Potential: Explore the Untapped Opportunities with Roasberry's Comprehensive Analysis",
                    "In the rapidly evolving world of digital marketing, navigating the intricacies of data has become synonymous with success. Roasberry, a digital marketing optimization platform, takes center stage as a transformative force for contemporary brands. By employing sophisticated data models, Roasberry offers a profound analysis of campaigns, providing brands with the tools needed to maximize Return on Ad Spend (ROAS) and drive profitable expansion.": "In the rapidly evolving world of digital marketing, navigating the intricacies of data has become synonymous with success. Roasberry, a digital marketing optimization platform, takes center stage as a transformative force for contemporary brands. By employing sophisticated data models, Roasberry offers a profound analysis of campaigns, providing brands with the tools needed to maximize Return on Ad Spend (ROAS) and drive profitable expansion.",
                    "Decoding Data for Strategic Insights:": "Decoding Data for Strategic Insights:",
                    "In the age of information, successful marketing hinges on the ability to decode data and derive actionable insights. Roasberry excels at this, utilizing advanced data models to delve deep into the intricacies of campaigns. This approach ensures that brands gain valuable insights, recognize patterns, and make informed decisions based on a thorough understanding of their marketing landscape.": "In the age of information, successful marketing hinges on the ability to decode data and derive actionable insights. Roasberry excels at this, utilizing advanced data models to delve deep into the intricacies of campaigns. This approach ensures that brands gain valuable insights, recognize patterns, and make informed decisions based on a thorough understanding of their marketing landscape.",
                    "Customization for Precision:": "Customization for Precision:",
                    "One size does not fit all in the world of digital marketing, and Roasberry understands this implicitly. The platform's Customizable Campaign Analysis allows brands to tailor their data analysis to align with their unique goals and objectives. Whether you're focused on increasing brand awareness, driving conversions, or optimizing ad spend efficiency, Roasberry adapts to your specific needs.": "One size does not fit all in the world of digital marketing, and Roasberry understands this implicitly. The platform's Customizable Campaign Analysis allows brands to tailor their data analysis to align with their unique goals and objectives. Whether you're focused on increasing brand awareness, driving conversions, or optimizing ad spend efficiency, Roasberry adapts to your specific needs.",
                    "Tailored Analysis for Targeted Impact:": "Tailored Analysis for Targeted Impact:",
                    "Understanding the unique identity of each brand, Roasberry provides a tailored analysis that aligns with specific needs and goals. Whether optimizing social media advertising, fine-tuning search engine marketing, or refining email campaigns, Roasberry's comprehensive analysis adapts to deliver insights that are crucial for each brand's success.": "Understanding the unique identity of each brand, Roasberry provides a tailored analysis that aligns with specific needs and goals. Whether optimizing social media advertising, fine-tuning search engine marketing, or refining email campaigns, Roasberry's comprehensive analysis adapts to deliver insights that are crucial for each brand's success.",
                    "Strategic Budgeting for Maximum ROAS:": "Strategic Budgeting for Maximum ROAS:",
                    "ROAS is a critical metric, and Roasberry goes beyond basic calculations by offering a nuanced understanding of the factors influencing returns. Through the analysis of each marketing channel's performance, audience segmentation, and creative impact, Roasberry empowers brands to strategically allocate budgets, ensuring maximum returns and sustainable growth.": "ROAS is a critical metric, and Roasberry goes beyond basic calculations by offering a nuanced understanding of the factors influencing returns. Through the analysis of each marketing channel's performance, audience segmentation, and creative impact, Roasberry empowers brands to strategically allocate budgets, ensuring maximum returns and sustainable growth.",
                    "Discovering Hidden Gems:": "Discovering Hidden Gems:",
                    "Within the vast expanse of digital marketing, hidden opportunities often await discovery. Roasberry serves as a guide, directing brands towards unexplored territories through its in-depth analysis. Whether it's untapped audience segments, overlooked channels, or emerging trends, Roasberry ensures brands seize potential growth opportunities.": "Within the vast expanse of digital marketing, hidden opportunities often await discovery. Roasberry serves as a guide, directing brands towards unexplored territories through its in-depth analysis. Whether it's untapped audience segments, overlooked channels, or emerging trends, Roasberry ensures brands seize potential growth opportunities.",
                    "Staying Ahead with Adaptive Insights:": "Staying Ahead with Adaptive Insights:",
                    "The digital marketing landscape is dynamic, and success requires adaptability. Roasberry keeps brands ahead of the curve by continually updating its data models to align with changing trends and consumer behavior. This commitment ensures that marketing strategies remain in sync with the latest industry insights, providing brands with a competitive edge.": "The digital marketing landscape is dynamic, and success requires adaptability. Roasberry keeps brands ahead of the curve by continually updating its data models to align with changing trends and consumer behavior. This commitment ensures that marketing strategies remain in sync with the latest industry insights, providing brands with a competitive edge.",
                    "Roasberry emerges as a catalyst for marketing excellence, offering a transformative approach to navigating the complexities of the digital realm. Through its comprehensive analysis, powered by advanced data models, Roasberry becomes the key to unlocking your marketing potential. Maximizing ROAS, uncovering untapped opportunities, and staying ahead of industry trends, Roasberry positions itself as an indispensable ally in the dynamic world of digital marketing. Thrive in the digital landscape with Roasberry by your side, and let your marketing potential flourish.": "Roasberry emerges as a catalyst for marketing excellence, offering a transformative approach to navigating the complexities of the digital realm. Through its comprehensive analysis, powered by advanced data models, Roasberry becomes the key to unlocking your marketing potential. Maximizing ROAS, uncovering untapped opportunities, and staying ahead of industry trends, Roasberry positions itself as an indispensable ally in the dynamic world of digital marketing. Thrive in the digital landscape with Roasberry by your side, and let your marketing potential flourish.",
                    "Stay Agile in a Dynamic Market: Roasberry's Thorough Analysis Keeps You Ahead": "Stay Agile in a Dynamic Market: Roasberry's Thorough Analysis Keeps You Ahead",
                    "In the fast-paced and ever-evolving landscape of digital marketing, staying ahead requires not just agility but a keen understanding of your campaigns and their impact. Contemporary brands navigating this dynamic market need a partner that goes beyond the basics, offering a level of analysis that is both thorough and adaptable. This is where Roasberry steps in, providing a sophisticated digital marketing optimization platform that ensures your brand stays agile in the face of constant change.": "In the fast-paced and ever-evolving landscape of digital marketing, staying ahead requires not just agility but a keen understanding of your campaigns and their impact. Contemporary brands navigating this dynamic market need a partner that goes beyond the basics, offering a level of analysis that is both thorough and adaptable. This is where Roasberry steps in, providing a sophisticated digital marketing optimization platform that ensures your brand stays agile in the face of constant change.",
                    "Adaptability in a Shifting Landscape:": "Adaptability in a Shifting Landscape:",
                    "The digital market is inherently dynamic, with trends, consumer behaviors, and algorithms evolving at a rapid pace. To thrive in such an environment, brands must be agile – ready to pivot strategies and seize new opportunities as they arise. Roasberry recognizes the importance of adaptability and equips brands with a thorough analysis that acts as a compass in navigating the complexities of the digital landscape.": "The digital market is inherently dynamic, with trends, consumer behaviors, and algorithms evolving at a rapid pace. To thrive in such an environment, brands must be agile – ready to pivot strategies and seize new opportunities as they arise. Roasberry recognizes the importance of adaptability and equips brands with a thorough analysis that acts as a compass in navigating the complexities of the digital landscape.",
                    "Uncover Insights Beyond the Surface:": "Uncover Insights Beyond the Surface:",
                    "While surface-level metrics provide a basic understanding of campaign performance, Roasberry takes it a step further. Its sophisticated data models allow brands to uncover insights that go beyond the obvious, diving deep into the intricacies of each campaign. This level of analysis is crucial for identifying emerging trends, understanding audience behaviors, and making data-driven decisions that set the stage for success.": "While surface-level metrics provide a basic understanding of campaign performance, Roasberry takes it a step further. Its sophisticated data models allow brands to uncover insights that go beyond the obvious, diving deep into the intricacies of each campaign. This level of analysis is crucial for identifying emerging trends, understanding audience behaviors, and making data-driven decisions that set the stage for success.",
                    "Customizable Solutions for Your Unique Challenges:": "Customizable Solutions for Your Unique Challenges:",
                    "No two brands face identical challenges, and Roasberry understands the need for tailored solutions. The platform's customizable analysis ensures that your brand's unique requirements are not just acknowledged but addressed with precision. Whether it's adjusting targeting parameters, refining ad creatives, or optimizing across different channels, Roasberry's adaptability empowers brands to tackle their specific challenges head-on.": "No two brands face identical challenges, and Roasberry understands the need for tailored solutions. The platform's customizable analysis ensures that your brand's unique requirements are not just acknowledged but addressed with precision. Whether it's adjusting targeting parameters, refining ad creatives, or optimizing across different channels, Roasberry's adaptability empowers brands to tackle their specific challenges head-on.",
                    "Maximize ROAS and Expand Operations Smartly:": "Maximize ROAS and Expand Operations Smartly:",
                    "In the competitive world of digital marketing, Return on Ad Spend (ROAS) is a crucial metric. Roasberry doesn't just focus on ROAS; it maximizes it by providing insights that lead to informed decisions. The platform's thorough analysis acts as a strategic guide, helping brands allocate budgets effectively, identify high-performing channels, and optimize campaigns for profitability.": "In the competitive world of digital marketing, Return on Ad Spend (ROAS) is a crucial metric. Roasberry doesn't just focus on ROAS; it maximizes it by providing insights that lead to informed decisions. The platform's thorough analysis acts as a strategic guide, helping brands allocate budgets effectively, identify high-performing channels, and optimize campaigns for profitability.",
                    "Expanding operations profitably requires a nuanced understanding of market dynamics, and Roasberry's analysis serves as the compass for smart expansion. By uncovering opportunities and mitigating risks, the platform ensures that your brand grows not just rapidly but sustainably in the ever-shifting digital landscape.": "Expanding operations profitably requires a nuanced understanding of market dynamics, and Roasberry's analysis serves as the compass for smart expansion. By uncovering opportunities and mitigating risks, the platform ensures that your brand grows not just rapidly but sustainably in the ever-shifting digital landscape.",
                    "In a market that demands constant adaptation, Roasberry stands as a beacon of agility and precision. Its commitment to providing brands with a thorough, customizable analysis is not just a service; it's a strategic advantage in the pursuit of digital marketing excellence.": "In a market that demands constant adaptation, Roasberry stands as a beacon of agility and precision. Its commitment to providing brands with a thorough, customizable analysis is not just a service; it's a strategic advantage in the pursuit of digital marketing excellence.",
                    "Stay ahead of the curve with Roasberry's comprehensive insights, adapt your strategies to the evolving market, and watch your brand not only survive but thrive in the dynamic digital landscape. In a world where staying relevant is key, Roasberry ensures your brand not only stays relevant but stays ahead. Embrace the power of thorough analysis – stay agile with Roasberry.": "Stay ahead of the curve with Roasberry's comprehensive insights, adapt your strategies to the evolving market, and watch your brand not only survive but thrive in the dynamic digital landscape. In a world where staying relevant is key, Roasberry ensures your brand not only stays relevant but stays ahead. Embrace the power of thorough analysis – stay agile with Roasberry.",
                    "Unlock the Secrets to Campaign Success: Roasberry's Innovative Platform Maximizes ROAS": "Unlock the Secrets to Campaign Success: Roasberry's Innovative Platform Maximizes ROAS",
                    "In the intricate world of digital marketing, success is often seen as a code waiting to be cracked. For contemporary brands seeking not just success but excellence in their campaigns, Roasberry emerges as the master key. This innovative digital marketing optimization platform goes beyond conventional approaches, employing sophisticated data models to unlock the secrets of campaign success and maximize Return on Ad Spend (ROAS).": "In the intricate world of digital marketing, success is often seen as a code waiting to be cracked. For contemporary brands seeking not just success but excellence in their campaigns, Roasberry emerges as the master key. This innovative digital marketing optimization platform goes beyond conventional approaches, employing sophisticated data models to unlock the secrets of campaign success and maximize Return on Ad Spend (ROAS).",
                    "Decoding Success with Data-Driven Innovation:": "Decoding Success with Data-Driven Innovation:",
                    "Roasberry understands that successful campaigns are built on a foundation of data-driven innovation. The platform leverages cutting-edge data models that go beyond traditional analytics, providing brands with a comprehensive understanding of their campaigns. This innovative approach is the key to cracking the code to campaign success, empowering brands to make informed decisions that drive results.": "Roasberry understands that successful campaigns are built on a foundation of data-driven innovation. The platform leverages cutting-edge data models that go beyond traditional analytics, providing brands with a comprehensive understanding of their campaigns. This innovative approach is the key to cracking the code to campaign success, empowering brands to make informed decisions that drive results.",
                    "Unleashing the Power of Personalized Insights:": "Unleashing the Power of Personalized Insights:",
                    "In the quest for success, one size does not fit all. Roasberry acknowledges the uniqueness of each brand and campaign, offering a personalized analysis that uncovers insights tailored to specific needs. This personalization is not just a feature; it's a philosophy that runs through the core of Roasberry's platform, ensuring that brands receive insights that resonate with their unique identity and goals.": "In the quest for success, one size does not fit all. Roasberry acknowledges the uniqueness of each brand and campaign, offering a personalized analysis that uncovers insights tailored to specific needs. This personalization is not just a feature; it's a philosophy that runs through the core of Roasberry's platform, ensuring that brands receive insights that resonate with their unique identity and goals.",
                    "Maximizing ROAS: More Than a Metric, It's a Mission:": "Maximizing ROAS: More Than a Metric, It's a Mission:",
                    "Return on Ad Spend (ROAS) is more than just a metric; it's a mission for brands seeking to maximize the impact of their campaigns. Roasberry doesn't just aim to improve ROAS; it strives to maximize it. The platform's innovative analysis identifies areas for optimization, hones in on high-performing channels, and guides brands toward strategies that ensure every advertising dollar delivers maximum value.": "Return on Ad Spend (ROAS) is more than just a metric; it's a mission for brands seeking to maximize the impact of their campaigns. Roasberry doesn't just aim to improve ROAS; it strives to maximize it. The platform's innovative analysis identifies areas for optimization, hones in on high-performing channels, and guides brands toward strategies that ensure every advertising dollar delivers maximum value.",
                    "Innovative Solutions for a Competitive Edge:": "Innovative Solutions for a Competitive Edge:",
                    "In a digital landscape teeming with competition, innovation is the differentiator. Roasberry provides brands with innovative solutions that set them apart from the crowd. From advanced audience targeting strategies to creative optimization techniques, the platform equips brands with the tools they need to stay ahead in the ever-evolving digital marketing arena.": "In a digital landscape teeming with competition, innovation is the differentiator. Roasberry provides brands with innovative solutions that set them apart from the crowd. From advanced audience targeting strategies to creative optimization techniques, the platform equips brands with the tools they need to stay ahead in the ever-evolving digital marketing arena.",
                    "Roasberry – Your Key to Unlocked Success": "Roasberry – Your Key to Unlocked Success",
                    "In the quest for campaign success, Roasberry stands out as the key to unlocking the full potential of digital marketing endeavors. Its innovative platform, driven by sophisticated data models and a commitment to personalized insights, ensures that brands not only crack the code but rewrite it in their favor.": "In the quest for campaign success, Roasberry stands out as the key to unlocking the full potential of digital marketing endeavors. Its innovative platform, driven by sophisticated data models and a commitment to personalized insights, ensures that brands not only crack the code but rewrite it in their favor.",
                    "Maximize your ROAS, unleash the power of data-driven innovation, and elevate your campaigns to new heights with Roasberry. In a world where success is elusive, Roasberry provides the code that transforms aspirations into achievements. Crack the code with Roasberry – your pathway to campaign success in the digital age.": "Maximize your ROAS, unleash the power of data-driven innovation, and elevate your campaigns to new heights with Roasberry. In a world where success is elusive, Roasberry provides the code that transforms aspirations into achievements. Crack the code with Roasberry – your pathway to campaign success in the digital age.",
                    "Drive Growth, Amplify Impact! Roasberry's Advanced Analysis Paves the Way for Digital Triumph": "Drive Growth, Amplify Impact! Roasberry's Advanced Analysis Paves the Way for Digital Triumph",
                    "In the fast-evolving realm of digital marketing, the pursuit of growth and impact is an ongoing challenge for brands striving to stand out in the digital landscape. Roasberry, a cutting-edge digital marketing optimization platform, has emerged as a catalyst for success, offering advanced analysis tools that pave the way for unparalleled digital triumph. This article explores how Roasberry's sophisticated Advanced Analysis empowers brands to drive growth and amplify their impact in the digital sphere.": "In the fast-evolving realm of digital marketing, the pursuit of growth and impact is an ongoing challenge for brands striving to stand out in the digital landscape. Roasberry, a cutting-edge digital marketing optimization platform, has emerged as a catalyst for success, offering advanced analysis tools that pave the way for unparalleled digital triumph. This article explores how Roasberry's sophisticated Advanced Analysis empowers brands to drive growth and amplify their impact in the digital sphere.",
                    "Unleashing the Power of Advanced Analysis:": "Unleashing the Power of Advanced Analysis:",
                    "Roasberry's Advanced Analysis is a beacon for brands navigating the complexities of digital marketing. Tailored for the contemporary marketer, this feature goes beyond traditional analytics, offering an in-depth examination of campaigns, consumer behavior, and market trends. Let's delve into the key elements that make Roasberry's Advanced Analysis a game-changer:": "Roasberry's Advanced Analysis is a beacon for brands navigating the complexities of digital marketing. Tailored for the contemporary marketer, this feature goes beyond traditional analytics, offering an in-depth examination of campaigns, consumer behavior, and market trends. Let's delve into the key elements that make Roasberry's Advanced Analysis a game-changer:",
                    "Comprehensive Campaign Evaluation:": "Comprehensive Campaign Evaluation:",
                    "Roasberry's Advanced Analysis doesn't just scratch the surface; it delves deep into the intricacies of your campaigns. By evaluating performance metrics, audience engagement, and conversion data, brands gain a comprehensive understanding of what works and what needs refinement, providing the groundwork for strategic decision-making.": "Roasberry's Advanced Analysis doesn't just scratch the surface; it delves deep into the intricacies of your campaigns. By evaluating performance metrics, audience engagement, and conversion data, brands gain a comprehensive understanding of what works and what needs refinement, providing the groundwork for strategic decision-making.",
                    "Dynamic Consumer Behavior Insights:": "Dynamic Consumer Behavior Insights:",
                    "Understanding consumer behavior is crucial in the digital age. Roasberry's Advanced Analysis dissects consumer interactions across various touchpoints, unveiling insights that enable brands to tailor their messaging, offerings, and user experiences for maximum resonance and impact.": "Understanding consumer behavior is crucial in the digital age. Roasberry's Advanced Analysis dissects consumer interactions across various touchpoints, unveiling insights that enable brands to tailor their messaging, offerings, and user experiences for maximum resonance and impact.",
                    "Market Trend Anticipation:": "Market Trend Anticipation:",
                    "Staying ahead of market trends is the key to staying relevant. Roasberry's Advanced Analysis utilizes predictive analytics to anticipate shifts in the market, enabling brands to proactively adapt their strategies and capitalize on emerging opportunities, ensuring sustained growth in a rapidly changing landscape.": "Staying ahead of market trends is the key to staying relevant. Roasberry's Advanced Analysis utilizes predictive analytics to anticipate shifts in the market, enabling brands to proactively adapt their strategies and capitalize on emerging opportunities, ensuring sustained growth in a rapidly changing landscape.",
                    "Multi-Channel Optimization:": "Multi-Channel Optimization:",
                    "The digital ecosystem is diverse, and Roasberry's Advanced Analysis recognizes the importance of a multi-channel approach. By analyzing the performance of campaigns across various channels, brands can optimize their presence where it matters most, ensuring a holistic and effective digital marketing strategy.": "The digital ecosystem is diverse, and Roasberry's Advanced Analysis recognizes the importance of a multi-channel approach. By analyzing the performance of campaigns across various channels, brands can optimize their presence where it matters most, ensuring a holistic and effective digital marketing strategy.",
                    "ROI Maximization Through Data-Driven Decisions:": "ROI Maximization Through Data-Driven Decisions:",
                    "Roasberry's Advanced Analysis empowers brands to make data-driven decisions that go beyond intuition. By identifying high-performing channels, refining targeting parameters, and optimizing ad creatives, brands can maximize their Return on Investment (ROI) and drive growth in a cost-efficient manner.": "Roasberry's Advanced Analysis empowers brands to make data-driven decisions that go beyond intuition. By identifying high-performing channels, refining targeting parameters, and optimizing ad creatives, brands can maximize their Return on Investment (ROI) and drive growth in a cost-efficient manner.",
                    "In the era of digital dominance, the ability to drive growth and amplify impact is a testament to a brand's adaptability and strategic acumen. Roasberry's Advanced Analysis serves as a beacon, providing brands with the tools they need to navigate the complexities of the digital landscape successfully. By offering comprehensive campaign evaluation, dynamic consumer behavior insights, market trend anticipation, multi-channel optimization, and ROI maximization, Roasberry propels brands towards digital triumph. Embrace the power of advanced analysis with Roasberry and unlock new dimensions of growth and impact in the digital realm!": "In the era of digital dominance, the ability to drive growth and amplify impact is a testament to a brand's adaptability and strategic acumen. Roasberry's Advanced Analysis serves as a beacon, providing brands with the tools they need to navigate the complexities of the digital landscape successfully. By offering comprehensive campaign evaluation, dynamic consumer behavior insights, market trend anticipation, multi-channel optimization, and ROI maximization, Roasberry propels brands towards digital triumph. Embrace the power of advanced analysis with Roasberry and unlock new dimensions of growth and impact in the digital realm!",


                    //BLOG31
                    "The Future of E Commerce: Trends and Innovations": "The Future of E Commerce: Trends and Innovations",
                    "E Commerce is evolving rapidly, shaped by new technologies and shifting consumer behaviors. As we look to the future, several key trends and innovations are set to transform online shopping. Let's dive into these exciting developments that promise to make e commerce more engaging for both businesses and consumers.": "E Commerce is evolving rapidly, shaped by new technologies and shifting consumer behaviors. As we look to the future, several key trends and innovations are set to transform online shopping. Let's dive into these exciting developments that promise to make e commerce more engaging for both businesses and consumers.",
                    "What is E Commerce?": "What is E Commerce?",
                    "E Commerce, short for electronic commerce, is all about buying and selling goods and services online. It covers a vast array of online business activities, from shopping for clothes and electronics to banking, investing, and even renting apartments. E Commerce has completely changed the way we do business and shop. It offers incredible convenience, a wider variety of choices, and often better prices. Instead of driving to a store, we can now shop from the comfort of our homes, compare prices with a few clicks, and have items delivered right to our doorsteps. It's a modern way of doing business that fits perfectly into our busy, digital lives.": "E Commerce, short for electronic commerce, is all about buying and selling goods and services online. It covers a vast array of online business activities, from shopping for clothes and electronics to banking, investing, and even renting apartments. E Commerce has completely changed the way we do business and shop. It offers incredible convenience, a wider variety of choices, and often better prices. Instead of driving to a store, we can now shop from the comfort of our homes, compare prices with a few clicks, and have items delivered right to our doorsteps. It's a modern way of doing business that fits perfectly into our busy, digital lives.",
                    "Personalization and Customer Experience": "Personalization and Customer Experience",
                    "One of the biggest trends in E Commerce is personalization. Today's shoppers expect tailored experiences that cater to their unique preferences and needs. With advances in artificial intelligence (AI) and machine learning, e-commerce platforms can now offer highly personalized product recommendations, dynamic pricing, and customized marketing messages. By analyzing data like browsing history and purchase behavior, businesses can create unique experiences for each customer, boosting satisfaction and loyalty.": "One of the biggest trends in E Commerce is personalization. Today's shoppers expect tailored experiences that cater to their unique preferences and needs. With advances in artificial intelligence (AI) and machine learning, e-commerce platforms can now offer highly personalized product recommendations, dynamic pricing, and customized marketing messages. By analyzing data like browsing history and purchase behavior, businesses can create unique experiences for each customer, boosting satisfaction and loyalty.",
                    "Augmented Reality (AR) and Virtual Reality (VR)": "Augmented Reality (AR) and Virtual Reality (VR)",
                    "AR and VR technologies are revolutionizing online shopping. Imagine being able to see how a piece of furniture looks in your living room before buying it. AR makes this possible. VR, on the other hand, can offer virtual store tours, allowing you to browse products as if you were in a physical store. These technologies make online shopping more interactive and engaging, helping customers make more informed decisions.": "AR and VR technologies are revolutionizing online shopping. Imagine being able to see how a piece of furniture looks in your living room before buying it. AR makes this possible. VR, on the other hand, can offer virtual store tours, allowing you to browse products as if you were in a physical store. These technologies make online shopping more interactive and engaging, helping customers make more informed decisions.",
                    "Voice Commerce": "Voice Commerce",
                    "The rise of smart speakers and voice assistants like Amazon Alexa and Google Assistant has paved the way for voice commerce. Consumers can now search for products, place orders, and track deliveries using voice commands. This hands-free shopping experience is not only convenient but also efficient, making it an appealing option for busy consumers. As voice recognition technology continues to improve, voice commerce is expected to play a larger role in the E Commerce ecosystem.": "The rise of smart speakers and voice assistants like Amazon Alexa and Google Assistant has paved the way for voice commerce. Consumers can now search for products, place orders, and track deliveries using voice commands. This hands-free shopping experience is not only convenient but also efficient, making it an appealing option for busy consumers. As voice recognition technology continues to improve, voice commerce is expected to play a larger role in the E Commerce ecosystem.",
                    "Sustainable Shopping": "Sustainable Shopping",
                    "Sustainability is becoming a top priority for many consumers. E Commerce companies are responding by adopting eco-friendly practices and offering sustainable products. This includes using recyclable packaging, reducing carbon footprints, and promoting products with ethical sourcing. Consumers are increasingly choosing brands that align with their values, and sustainability is becoming a key differentiator in the competitive E Commerce market.": "Sustainability is becoming a top priority for many consumers. E Commerce companies are responding by adopting eco-friendly practices and offering sustainable products. This includes using recyclable packaging, reducing carbon footprints, and promoting products with ethical sourcing. Consumers are increasingly choosing brands that align with their values, and sustainability is becoming a key differentiator in the competitive E Commerce market.",
                    "Mobile Commerce": "Mobile Commerce",
                    "Mobile commerce, or m-commerce, is on the rise as smartphones become the primary device for online shopping. Businesses are optimizing their websites and apps for mobile users, ensuring a seamless shopping experience on smaller screens. Features like one-click purchasing, mobile wallets, and app-exclusive discounts are driving the growth of m-commerce. As mobile technology continues to advance, we can expect even more innovation in this space, making shopping on the go easier and more convenient.": "Mobile commerce, or m-commerce, is on the rise as smartphones become the primary device for online shopping. Businesses are optimizing their websites and apps for mobile users, ensuring a seamless shopping experience on smaller screens. Features like one-click purchasing, mobile wallets, and app-exclusive discounts are driving the growth of m-commerce. As mobile technology continues to advance, we can expect even more innovation in this space, making shopping on the go easier and more convenient.",
                    "Artificial Intelligence and Chatbots": "Artificial Intelligence and Chatbots",
                    "AI-powered chatbots are transforming customer service in E Commerce. These intelligent bots can handle a wide range of tasks, from answering customer queries to processing orders and providing personalized recommendations. Chatbots offer 24/7 support, quick response times, and consistent service, enhancing the overall customer experience. As AI technology becomes more sophisticated, chatbots will become even more integral to e-commerce operations, providing human-like interactions that can improve customer satisfaction and operational efficiency.": "AI-powered chatbots are transforming customer service in E Commerce. These intelligent bots can handle a wide range of tasks, from answering customer queries to processing orders and providing personalized recommendations. Chatbots offer 24/7 support, quick response times, and consistent service, enhancing the overall customer experience. As AI technology becomes more sophisticated, chatbots will become even more integral to e-commerce operations, providing human-like interactions that can improve customer satisfaction and operational efficiency.",
                    "Social Commerce": "Social Commerce",
                    "Social media platforms are becoming powerful E Commerce channels. Features like shoppable posts, in-app checkout, and influencer partnerships are driving sales directly from social media. Platforms like Instagram, Facebook, and TikTok are integrating shopping functionalities, allowing users to discover and purchase products without leaving the app. Social commerce combines the reach and engagement of social media with the convenience of online shopping, creating new opportunities for businesses to connect with consumers in a more meaningful way.": "Social media platforms are becoming powerful E Commerce channels. Features like shoppable posts, in-app checkout, and influencer partnerships are driving sales directly from social media. Platforms like Instagram, Facebook, and TikTok are integrating shopping functionalities, allowing users to discover and purchase products without leaving the app. Social commerce combines the reach and engagement of social media with the convenience of online shopping, creating new opportunities for businesses to connect with consumers in a more meaningful way.",
                    "Subscription Services": "Subscription Services",
                    "Subscription services are gaining popularity as they offer convenience and value to consumers. From monthly beauty boxes to meal kits and streaming services, subscriptions provide a steady stream of products or content tailored to the customer’s preferences. This model fosters customer loyalty and provides businesses with predictable revenue streams. As more industries explore subscription options, we can expect this trend to continue growing, offering consumers new and exciting ways to access the products and services they love.": "Subscription services are gaining popularity as they offer convenience and value to consumers. From monthly beauty boxes to meal kits and streaming services, subscriptions provide a steady stream of products or content tailored to the customer’s preferences. This model fosters customer loyalty and provides businesses with predictable revenue streams. As more industries explore subscription options, we can expect this trend to continue growing, offering consumers new and exciting ways to access the products and services they love.",
                    "Conclusion": "Conclusion",
                    "The future of E Commerce is bright, with innovative technologies and evolving consumer preferences driving continuous change. Businesses that stay ahead of these trends and embrace new innovations will be well-positioned to thrive in the competitive e-commerce landscape. Personalization, sustainability, mobile optimization, and the integration of AR, VR, and AI are just a few of the exciting developments shaping the future of online shopping. As the e-commerce industry continues to evolve, one thing is certain: the best is yet to come.": "The future of E Commerce is bright, with innovative technologies and evolving consumer preferences driving continuous change. Businesses that stay ahead of these trends and embrace new innovations will be well-positioned to thrive in the competitive e-commerce landscape. Personalization, sustainability, mobile optimization, and the integration of AR, VR, and AI are just a few of the exciting developments shaping the future of online shopping. As the e-commerce industry continues to evolve, one thing is certain: the best is yet to come.",
                    "Full Package": "Full Package",
                    "Annual Revenue": "Annual Revenue",
                    "Revenue-Based Pricing, Full Access.": "Revenue-Based Pricing, Full Access.",
                    "We appreciate your interest in our services. Before getting started, please note that we offer four different packages, all with the same features. The pricing is based on your estimated total revenue over the last 12 months in USD.": "We appreciate your interest in our services. Before getting started, please note that we offer four different packages, all with the same features. The pricing is based on your estimated total revenue over the last 12 months in USD.",
                    "To proceed, select a package that best fits your revenue. If you have any questions, our team is ready to help.": "To proceed, select a package that best fits your revenue. If you have any questions, our team is ready to help.",
                    "Pay Annually and Get 2 Months Free!": "Pay Annually and Get 2 Months Free!",
                    "Annually": "Annually",
                    "Monthly": "Monthly",
                    "Annual Revenue": "Annual Revenue",
                    "0-500K USD": "0-500K USD",
                    "$149/month": "$149/month",
                    "Start 14-Day Free Trial": "Start 14-Day Free Trial",
                    "500K-1 M USD": "500K-1 M USD",
                    "$199/month": "$199/month",
                    "1-5M USD": "1-5M USD",
                    "$299/month": "$299/month",
                    "5M+ USD": "5M+ USD",
                    "$499/month": "$499/month",
                    "Plan includes:": "Plan includes:",
                    "All-in-One Dashboard": "All-in-One Dashboard",
                    "Pixel Tracking": "Pixel Tracking",
                    "Advanced Marketing Analytics": "Advanced Marketing Analytics",
                    "Automated Reporting": "Automated Reporting",
                    "Customer Journeys": "Customer Journeys",
                    "Visitor Insights/Logs": "Visitor Insights/Logs",
                    "Unlimited Devices": "Unlimited Devices",
                    "Customer Support": "Customer Support",

                    //Visitor Insights-Log i18n
                    "Visitor Insights": "Visitor Insights",
                    "Track Your LOG1": "Track Your",
                    "Visitors Flow Easily.": "Visitors Flow Easily.",
                    "Customer Flow": "Customer Flow",
                    "Gain insights into customer navigation and optimize their journey for a better user experience and increased conversions.": "Gain insights into customer navigation and optimize their journey for a better user experience and increased conversions.",
                    "Visitor Channels": "Visitor Channels",
                    "Identify top traffic sources, analyze their performance metrics, and refine marketing strategies to boost engagement.": "Identify top traffic sources, analyze their performance metrics, and refine marketing strategies to boost engagement.",
                    "Analyze Detailed User Behavior Patterns": "Analyze Detailed User Behavior Patterns",
                    "Gain a deep understanding of your website visitors' actions and preferences. Identify which pages are most engaging and where users drop off.": "Gain a deep understanding of your website visitors' actions and preferences. Identify which pages are most engaging and where users drop off.",
                    "Detailed Analytics": "Detailed Analytics",
                    "Use detailed analytics to uncover patterns in user behavior. Make data-driven decisions to enhance user experience and increase conversion rates.": "Use detailed analytics to uncover patterns in user behavior. Make data-driven decisions to enhance user experience and increase conversion rates.",
                    "Track Interactions": "Track Interactions",
                    "Track user interactions across different pages and devices. Optimize your website layout and content based on insights from user behavior analysis.": "Track user interactions across different pages and devices. Optimize your website layout and content based on insights from user behavior analysis.",
                    "Visitor Log": "Visitor Log",
                    "Track Your LOG2": "Track Your",
                    "Customer Visits and Actions.": "Customer Visits and Actions.",
                    "Visitor Profile": "Visitor Profile",
                    "See detailed visitor information such as their visit summary, device and location details, and their interaction history on your site.": "See detailed visitor information such as their visit summary, device and location details, and their interaction history on your site.",
                    "Visits in Real Time": "Visits in Real Time",
                    "Get up-to-the-minute data on your website visitors. See how many people are visiting your site in the last 24 hours and last 30 minutes.": "Get up-to-the-minute data on your website visitors. See how many people are visiting your site in the last 24 hours and last 30 minutes.",
                    "Improve Your Website with User Analytics": "Improve Your Website with User Analytics",
                    "Enhance your website by understanding your users. Use detailed analytics to improve user experience and increase conversions.": "Enhance your website by understanding your users. Use detailed analytics to improve user experience and increase conversions.",
                    "Behavior Analysis": "Behavior Analysis",
                    "Analyze visitor behavior to identify popular pages, user journeys, and potential drop-off points.": "Analyze visitor behavior to identify popular pages, user journeys, and potential drop-off points.",
                    "Actionable Insights": "Actionable Insights",
                    "Leverage actionable insights from visitor data to refine your marketing campaigns and boost engagement.": "Leverage actionable insights from visitor data to refine your marketing campaigns and boost engagement.",


                    //Dashboard and Insight Tools i18n
                    "Dashboard and Insight Tools": "Dashboard and Insight Tools",

                    "The Only Platform for All Your": "The Only Platform for All Your",
                    "E-commerce & Marketing Analytics": "E-commerce & Marketing Analytics",

                    "Campaigns Hub": "Campaigns Hub",
                    "Monitor and compare campaigns across platforms.": "Monitor and compare campaigns across platforms.",
                    "Essential Graphs": "Essential Graphs",
                    "Track your sales volume and advertising spent.": "Track your sales volume and advertising spent.",
                    "Notable Changes": "Notable Changes",
                    "Easily see changes in the graphs.": "Easily see changes in the graphs.",
                    "Create a Quick, Personalized Dashboard in Minutes": "Create a Quick, Personalized Dashboard in Minutes",
                    "Easily customize your dashboard to suit your unique business needs. Get started quickly and manage your data effortlessly.": "Easily customize your dashboard to suit your unique business needs. Get started quickly and manage your data effortlessly.",
                    "Seamless Integration": "Seamless Integration",
                    "Our platform offers seamless integration with your existing systems. Track your key metrics and gain valuable insights in real-time.": "Our platform offers seamless integration with your existing systems. Track your key metrics and gain valuable insights in real-time.",
                    "Insightful Analytics": "Insightful Analytics",
                    "Stay ahead of the competition with advanced analytics. Monitor performance, identify trends, and make data-driven decisions.": "Stay ahead of the competition with advanced analytics. Monitor performance, identify trends, and make data-driven decisions.",
                    "Monitor and Compare Campaigns Across Platforms": "Monitor and Compare Campaigns Across Platforms",
                    "Easily monitor and optimize your campaigns on platforms like Google Ads, Meta Ads, and Pinterest.": "Easily monitor and optimize your campaigns on platforms like Google Ads, Meta Ads, and Pinterest.",
                    "Comprehensive Analytics": "Comprehensive Analytics",
                    "Track ROAS, Conversion Rates, Total Spent, CPC, AOV, and CPM for every campaign.": "Track ROAS, Conversion Rates, Total Spent, CPC, AOV, and CPM for every campaign.",
                    "Platform Comparison": "Platform Comparison",
                    "Compare campaign performance across Google Ads, Meta Ads, and Pinterest.": "Compare campaign performance across Google Ads, Meta Ads, and Pinterest.",
                    "Export Functionality": "Export Functionality",
                    "Export data to XLS format for in-depth analysis and reporting.": "Export data to XLS format for in-depth analysis and reporting.",
                    "Because Every Click Tells a Story.": "Because Every Click Tells a Story.",

                    "Because Every Click": "Because Every Click",
                    "Tells a Story.": "Tells a Story.",

                    "Berry Pixel": "Berry Pixel",
                    "Integrate Berry Pixel into your online store for deep insights into visitor behavior. Capture every interaction from landing to leaving your site.": "Integrate Berry Pixel into your online store for deep insights into visitor behavior. Capture every interaction from landing to leaving your site.",
                    "Sales Attribution Model": "Sales Attribution Model",
                    "Berry Pixel tracks visitor journeys, helping you identify key sales-driving channels and refine your marketing strategies for better ROI.": "Berry Pixel tracks visitor journeys, helping you identify key sales-driving channels and refine your marketing strategies for better ROI.",
                    "Gain Deep Insights with Advanced Analytics": "Gain Deep Insights with Advanced Analytics",
                    "Analyze your data in depth and gain critical insights for your business. Enhance your performance with comprehensive reporting.": "Analyze your data in depth and gain critical insights for your business. Enhance your performance with comprehensive reporting.",
                    "Optimize Performance": "Optimize Performance",
                    "Use data-driven insights to optimize your processes and improve efficiency. Ensure your operations are running at peak performance with our detailed analysis.": "Use data-driven insights to optimize your processes and improve efficiency. Ensure your operations are running at peak performance with our detailed analysis.",
                    "Increase ROI": "Increase ROI",
                    "Leverage in-depth data analysis to enhance your return on investment. Make smarter investments based on comprehensive data insights and analytics.": "Leverage in-depth data analysis to enhance your return on investment. Make smarter investments based on comprehensive data insights and analytics.",
                    "Access from Anywhere": "Access from Anywhere",
                    "Unlimited Device Access": "Unlimited Device Access",
                    "Unlimited access to Roasberry from any device. Manage your ad tracking effortlessly, no matter where you are.": "Unlimited access to Roasberry from any device. Manage your ad tracking effortlessly, no matter where you are.",
                    "Mobile App Access": "Mobile App Access",
                    "Keep track of your campaigns and ad data even on the go. Always stay in control of your advertising strategy.": "Keep track of your campaigns and ad data even on the go. Always stay in control of your advertising strategy.",

                    "What is an All-in-One Marketing Tool?": "What is an All-in-One Marketing Tool?",
                    "Think of an all-in-one marketing tool as your marketing command center. It combines essential functions like campaign tracking, analytics, and customer relationship management into one seamless platform. This integration means you don’t have to juggle multiple tools—everything you need is right there at your fingertips.": "Think of an all-in-one marketing tool as your marketing command center. It combines essential functions like campaign tracking, analytics, and customer relationship management into one seamless platform. This integration means you don’t have to juggle multiple tools—everything you need is right there at your fingertips.",
                    "Why You’ll Love Using an All-in-One Marketing Tool": "Why You’ll Love Using an All-in-One Marketing Tool",
                    "1. Keep It Simple": "1. Keep It Simple",
                    "An all-in-one marketing tool is a game changer when it comes to simplifying your operations. Instead of managing multiple platforms for different marketing tasks, you have a single, unified interface. This means less time spent switching between apps and more time focusing on crafting compelling campaigns and understanding your audience.": "An all-in-one marketing tool is a game changer when it comes to simplifying your operations. Instead of managing multiple platforms for different marketing tasks, you have a single, unified interface. This means less time spent switching between apps and more time focusing on crafting compelling campaigns and understanding your audience.",
                    "Imagine planning a new campaign. With an all-in-one tool, you can coordinate your ad campaigns and access e-commerce data from one place. This streamlined approach reduces errors and ensures that all aspects of your marketing efforts are aligned and working together harmoniously. Plus, having everything in one place means your team can collaborate more effectively, saving time and effort.": "Imagine planning a new campaign. With an all-in-one tool, you can coordinate your ad campaigns and access e-commerce data from one place. This streamlined approach reduces errors and ensures that all aspects of your marketing efforts are aligned and working together harmoniously. Plus, having everything in one place means your team can collaborate more effectively, saving time and effort.",
                    "2. Teamwork Made Easy": "2. Teamwork Made Easy",
                    "Effective collaboration is at the heart of successful marketing. An all-in-one marketing tool provides a shared space where your team can access the same data, insights, and tools. This transparency fosters a collaborative environment where everyone is on the same page and can contribute to the campaign's success.": "Effective collaboration is at the heart of successful marketing. An all-in-one marketing tool provides a shared space where your team can access the same data, insights, and tools. This transparency fosters a collaborative environment where everyone is on the same page and can contribute to the campaign's success.",
                    "Picture your team working on a multi-channel campaign. With a centralized tool, everyone can easily access campaign performance data, share insights, and make data-driven decisions. This reduces misunderstandings and promotes a more cohesive strategy. Additionally, real-time updates mean that any changes or feedback are instantly visible to everyone, enhancing responsiveness and agility.": "Picture your team working on a multi-channel campaign. With a centralized tool, everyone can easily access campaign performance data, share insights, and make data-driven decisions. This reduces misunderstandings and promotes a more cohesive strategy. Additionally, real-time updates mean that any changes or feedback are instantly visible to everyone, enhancing responsiveness and agility.",
                    "3. Save Money": "3. Save Money",
                    "Using one platform instead of several can also save you some cash. Instead of paying for multiple subscriptions, you have just one. Plus, you save on the time and resources needed to train your team on different tools.": "Using one platform instead of several can also save you some cash. Instead of paying for multiple subscriptions, you have just one. Plus, you save on the time and resources needed to train your team on different tools.",
                    "4. See the Big Picture": "4. See the Big Picture",
                    "An integrated marketing tool provides a holistic view of your marketing efforts. With all your data in one place, you can easily track and analyze the entire customer journey, from the first point of contact to conversion and beyond. This comprehensive view enables you to make informed, data-driven decisions that improve your marketing strategies.": "An integrated marketing tool provides a holistic view of your marketing efforts. With all your data in one place, you can easily track and analyze the entire customer journey, from the first point of contact to conversion and beyond. This comprehensive view enables you to make informed, data-driven decisions that improve your marketing strategies.",
                    "Imagine being able to see how your ad campaigns impact customer engagement or how changes to your website affect conversion rates. With an all-in-one tool, you gain these insights effortlessly, allowing you to optimize your campaigns for maximum impact. You can identify trends, uncover opportunities, and address challenges more effectively, ensuring your marketing efforts are always aligned with your goals.": "Imagine being able to see how your ad campaigns impact customer engagement or how changes to your website affect conversion rates. With an all-in-one tool, you gain these insights effortlessly, allowing you to optimize your campaigns for maximum impact. You can identify trends, uncover opportunities, and address challenges more effectively, ensuring your marketing efforts are always aligned with your goals.",
                    "5. Make Your Customers Happy": "5. Make Your Customers Happy",
                    "In today’s competitive landscape, providing an exceptional customer experience is essential. An all-in-one marketing tool helps you deliver consistent, personalized communication across all channels, ensuring your customers feel valued and understood.": "In today’s competitive landscape, providing an exceptional customer experience is essential. An all-in-one marketing tool helps you deliver consistent, personalized communication across all channels, ensuring your customers feel valued and understood.",
                    "For example, by analyzing data from various touchpoints, you can tailor your messaging to resonate with different segments of your audience. This personalization fosters stronger connections and builds customer loyalty. Additionally, having a unified view of customer interactions allows you to quickly address any issues or concerns, enhancing customer satisfaction and retention.": "For example, by analyzing data from various touchpoints, you can tailor your messaging to resonate with different segments of your audience. This personalization fosters stronger connections and builds customer loyalty. Additionally, having a unified view of customer interactions allows you to quickly address any issues or concerns, enhancing customer satisfaction and retention.",
                    "Popular All-in-One Marketing Tools": "Popular All-in-One Marketing Tools",
                    "There are several well-known all-in-one marketing tools available that offer a range of features to help businesses manage their marketing efforts effectively. Here are a few popular options:": "There are several well-known all-in-one marketing tools available that offer a range of features to help businesses manage their marketing efforts effectively. Here are a few popular options:",
                    "Triple Whale: Known for its comprehensive analytics and insights tailored for e-commerce businesses, Triple Whale helps marketers make informed decisions by providing a clear view of performance metrics.": "Triple Whale: Known for its comprehensive analytics and insights tailored for e-commerce businesses, Triple Whale helps marketers make informed decisions by providing a clear view of performance metrics.",
                    "TrueRoas: Focused on tracking return on ad spend (ROAS) across various platforms, TrueRoas is designed to give marketers a detailed understanding of their ad campaign effectiveness, helping optimize investment.": "TrueRoas: Focused on tracking return on ad spend (ROAS) across various platforms, TrueRoas is designed to give marketers a detailed understanding of their ad campaign effectiveness, helping optimize investment.",
                    "Roasberry: Our very own all-in-one marketing tool at Roasberry is designed to provide comprehensive insights and management capabilities for your marketing campaigns. With a focus on user behavior, ad performance, and strategy optimization, Roasberry offers a powerful solution to take your marketing efforts to the next level.": "Roasberry: Our very own all-in-one marketing tool at Roasberry is designed to provide comprehensive insights and management capabilities for your marketing campaigns. With a focus on user behavior, ad performance, and strategy optimization, Roasberry offers a powerful solution to take your marketing efforts to the next level.",
                    "Why Roasberry is the Perfect Choice": "Why Roasberry is the Perfect Choice",
                    "At Roasberry, we understand the challenges marketers face in today’s fast-paced environment. Our all-in-one marketing tool is designed to simplify your efforts and amplify your success. Here’s why Roasberry stands out:": "At Roasberry, we understand the challenges marketers face in today’s fast-paced environment. Our all-in-one marketing tool is designed to simplify your efforts and amplify your success. Here’s why Roasberry stands out:",
                    "● Understand Your Audience: Roasberry provides deep insights into user behavior, helping you connect with your audience on a more personal level. Our analytics tools allow you to segment your audience, tailor your messaging, and deliver content that resonates.": "● Understand Your Audience: Roasberry provides deep insights into user behavior, helping you connect with your audience on a more personal level. Our analytics tools allow you to segment your audience, tailor your messaging, and deliver content that resonates.",
                    "● Boost Ad Performance: With Roasberry, you can easily track your ad campaigns and gain insights into what’s working and what needs improvement. Our platform provides detailed metrics and reports, allowing you to optimize your campaigns for better results.": "● Boost Ad Performance: With Roasberry, you can easily track your ad campaigns and gain insights into what’s working and what needs improvement. Our platform provides detailed metrics and reports, allowing you to optimize your campaigns for better results.",
                    "● Access E-commerce Data: Roasberry integrates seamlessly with your e-commerce platform, allowing you to access and analyze critical data. This helps you understand sales patterns, track inventory, and improve overall business performance.": "● Access E-commerce Data: Roasberry integrates seamlessly with your e-commerce platform, allowing you to access and analyze critical data. This helps you understand sales patterns, track inventory, and improve overall business performance.",
                    "● Optimize with Confidence: Our powerful analytics tools give you the confidence to refine your marketing strategies and achieve real results. By leveraging Roasberry’s insights, you can make data-driven decisions that drive growth and success.": "● Optimize with Confidence: Our powerful analytics tools give you the confidence to refine your marketing strategies and achieve real results. By leveraging Roasberry’s insights, you can make data-driven decisions that drive growth and success.",
                    "Choosing Roasberry means partnering with a platform that cares about your success. We’re committed to providing you with the tools and insights you need to excel in your marketing efforts. Visit roasberry.com to learn more and see how our platform can transform your marketing strategy.": "Choosing Roasberry means partnering with a platform that cares about your success. We’re committed to providing you with the tools and insights you need to excel in your marketing efforts. Visit roasberry.com to learn more and see how our platform can transform your marketing strategy.",
                    "Conclusion": "Conclusion",
                    "Using an all-in-one marketing tool is like having a trusty sidekick in your business journey. It simplifies your workflow, enhances team collaboration, and helps you make data-driven decisions. With everything in one place, you can focus on what really matters—building genuine connections with your audience and delivering outstanding experiences. So why not give it a try and see how an all-in-one tool can transform your business success?": "Using an all-in-one marketing tool is like having a trusty sidekick in your business journey. It simplifies your workflow, enhances team collaboration, and helps you make data-driven decisions. With everything in one place, you can focus on what really matters—building genuine connections with your audience and delivering outstanding experiences. So why not give it a try and see how an all-in-one tool can transform your business success?",
                    "NewsWriter": "NewsWriter",
                    "compareUs": "Compare Us",

                    // Roasberry vs Polar Analytics i18n
                    "Roasberry vs Polar Analytics": "Roasberry vs Polar Analytics",
                    "Polar Analytics is a great tool for marketers with many integrations from different platforms. A platform providing real-time insights, pixel tracking, AI assistance, different attribution models, a user-friendly interface, and quick integrations sounds incredible yet familiar doesn’t it? We provide all of the above at a fraction of the cost, with dedicated customer support to answer all your needs.": "Polar Analytics is a great tool for marketers with many integrations from different platforms. A platform providing real-time insights, pixel tracking, AI assistance, different attribution models, a user-friendly interface, and quick integrations sounds incredible yet familiar doesn’t it? We provide all of the above at a fraction of the cost, with dedicated customer support to answer all your needs.",
                    "Book a Demo": "Book a Demo",
                    "Wouldn't you like to make your ad tracking more effective with Roasberry?": "Wouldn't you like to make your ad tracking more effective with Roasberry?",
                    "Features": "Features",
                    "Pixel Tracking": "Pixel Tracking",
                    "Detailed Shopify Analytics": "Detailed Shopify Analytics",
                    "Google Ads & Meta Ads Integration": "Google Ads & Meta Ads Integration",
                    "RFM Analysis and Customer Segments": "RFM Analysis and Customer Segments",
                    "First Click, Last Click, Linear Attribution": "First Click, Last Click, Linear Attribution",
                    "Visitor History for Converted Visits": "Visitor History for Converted Visits",
                    "Visitor History for All Visits": "Visitor History for All Visits",
                    "Time-Decay Attribution": "Time-Decay Attribution",
                    "Position-Based Attribution": "Position-Based Attribution",
                    "Merchant Friendly Cost": "Merchant Friendly Cost",
                    "Yes": "Yes",
                    "No": "No",

                    //Roasberry vs Upstackified i18n
                    "Roasberry vs Upstackified": "Roasberry vs Upstackified",
                    "First things first, there is no order limit on Roasberry. We charge based on revenue, as it affects the cost, but not on order quantity. As our costs depend on ad spend and data we track, we aim to charge a very small fee to provide insights on data.": "First things first, there is no order limit on Roasberry. We charge based on revenue, as it affects the cost, but not on order quantity. As our costs depend on ad spend and data we track, we aim to charge a very small fee to provide insights on data.",
                    "Roasberry provides you insights on purchases, such as abandoned items, most viewed pages, on which pages users land and leave, how their flow looks so that you won’t need GA4 data, or you will not have to rely on Meta Pixel while Upstackified will push data back to Meta and Google.": "Roasberry provides you insights on purchases, such as abandoned items, most viewed pages, on which pages users land and leave, how their flow looks so that you won’t need GA4 data, or you will not have to rely on Meta Pixel while Upstackified will push data back to Meta and Google.",
                    "Roasberry has a built-in RFM analysis, which you can upload to platforms based on campaign goals. Plus, you can see how every visitor moves through your website to get insights on conversions, and how customers react on your website.": "Roasberry has a built-in RFM analysis, which you can upload to platforms based on campaign goals. Plus, you can see how every visitor moves through your website to get insights on conversions, and how customers react on your website.",
                    "Book a Demo": "Book a Demo",
                    "Wouldn't you like to make your ad tracking more effective with Roasberry?": "Wouldn't you like to make your ad tracking more effective with Roasberry?",
                    "Features": "Features",
                    "Pixel Tracking": "Pixel Tracking",
                    "Detailed Shopify Analytics": "Detailed Shopify Analytics",
                    "Google Ads & Meta Ads Integration": "Google Ads & Meta Ads Integration",
                    "RFM Analysis and Customer Segments": "RFM Analysis and Customer Segments",
                    "First Click, Last Click, Linear Attribution": "First Click, Last Click, Linear Attribution",
                    "Visitor History for Converted Visits": "Visitor History for Converted Visits",
                    "Visitor History for All Visits": "Visitor History for All Visits",
                    "Time-Decay Attribution": "Time-Decay Attribution",
                    "Position-Based Attribution": "Position-Based Attribution",
                    "Merchant Friendly Cost": "Merchant Friendly Cost",
                    "Yes": "Yes",
                    "No": "No",


                    //Roasberry vs Kendall i18n
                    "Roasberry vs Kendall": "Roasberry vs Kendall",
                    "Kendall is an affordable tool that provides great insights regarding your purchases. We believe in providing a more holistic view of your analytics including your website traffic and your users' checkout behavior at a similar cost.": "Kendall is an affordable tool that provides great insights regarding your purchases. We believe in providing a more holistic view of your analytics including your website traffic and your users' checkout behavior at a similar cost.",
                    "Our solution sets itself apart by providing a state-of-the-art RFM segmentation and many more detailed attribution models which are essential for brands making over half-a-million US Dollars annually.": "Our solution sets itself apart by providing a state-of-the-art RFM segmentation and many more detailed attribution models which are essential for brands making over half-a-million US Dollars annually.",
                    "Book a Demo": "Book a Demo",
                    "Wouldn't you like to make your ad tracking more effective with Roasberry?": "Wouldn't you like to make your ad tracking more effective with Roasberry?",
                    "Features": "Features",
                    "Pixel Tracking": "Pixel Tracking",
                    "Detailed Shopify Analytics": "Detailed Shopify Analytics",
                    "Google Ads & Meta Ads Integration": "Google Ads & Meta Ads Integration",
                    "RFM Analysis and Customer Segments": "RFM Analysis and Customer Segments",
                    "First Click, Last Click, Linear Attribution": "First Click, Last Click, Linear Attribution",
                    "Visitor History for Converted Visits": "Visitor History for Converted Visits",
                    "Visitor History for All Visits": "Visitor History for All Visits",
                    "Time-Decay Attribution": "Time-Decay Attribution",
                    "Position-Based Attribution": "Position-Based Attribution",
                    "Merchant Friendly Cost": "Merchant Friendly Cost",
                    "Yes": "Yes",
                    "No": "No",

                    //Roasberry vs Triple Whale i18n
                    "Roasberry vs Triple Whale": "Roasberry vs Triple Whale",
                    "With TripleWhale, you can keep track of your orders, use it for attributions. For example, you can see order history to view how customers interacted with your ads.": "With TripleWhale, you can keep track of your orders, use it for attributions. For example, you can see order history to view how customers interacted with your ads.",
                    "With Roasberry you can filter the visitors to see how people who abandon checkout interact with your website and ads, just as you can see only orders with TripleWhale.": "With Roasberry you can filter the visitors to see how people who abandon checkout interact with your website and ads, just as you can see only orders with TripleWhale.",
                    "Book a Demo": "Book a Demo",
                    "Wouldn't you like to make your ad tracking more effective with Roasberry?": "Wouldn't you like to make your ad tracking more effective with Roasberry?",
                    "Features": "Features",
                    "Pixel Tracking": "Pixel Tracking",
                    "Detailed Shopify Analytics": "Detailed Shopify Analytics",
                    "Google Ads & Meta Ads Integration": "Google Ads & Meta Ads Integration",
                    "RFM Analysis and Customer Segments": "RFM Analysis and Customer Segments",
                    "First Click, Last Click, Linear Attribution": "First Click, Last Click, Linear Attribution",
                    "Visitor History for Converted Visits": "Visitor History for Converted Visits",
                    "Visitor History for All Visits": "Visitor History for All Visits",
                    "Time-Decay Attribution": "Time-Decay Attribution",
                    "Position-Based Attribution": "Position-Based Attribution",
                    "Merchant Friendly Cost": "Merchant Friendly Cost",
                    "Yes": "Yes",
                    "No": "No",

                    //Roasberry vs Northbeam i18n
                    "Roasberry vs Northbeam": "Roasberry vs Northbeam",
                    "Northbeam is an amazing tool for brands spending more than $2.5 M annually on paid advertising. It provides multiple attribution models, and also works for physical retail too.": "Northbeam is an amazing tool for brands spending more than $2.5 M annually on paid advertising. It provides multiple attribution models, and also works for physical retail too.",
                    "We designed Roasberry to be effective for brands in an affordable way while being accurate. Our digital fingerprint system tracks every visit - not just order - to provide you insights on not only purchases, but also on abandoned checkouts, page views, and many more. We provide all the information for approximately 1/10th of the price.": "We designed Roasberry to be effective for brands in an affordable way while being accurate. Our digital fingerprint system tracks every visit - not just order - to provide you insights on not only purchases, but also on abandoned checkouts, page views, and many more. We provide all the information for approximately 1/10th of the price.",
                    "You can use time decay attribution model to check your conversion cycles, or switch between 6 different attribution models to use the best and most suitable one.": "You can use time decay attribution model to check your conversion cycles, or switch between 6 different attribution models to use the best and most suitable one.",
                    "Book a Demo": "Book a Demo",
                    "Wouldn't you like to make your ad tracking more effective with Roasberry?": "Wouldn't you like to make your ad tracking more effective with Roasberry?",
                    "Features": "Features",
                    "Pixel Tracking": "Pixel Tracking",
                    "Detailed Shopify Analytics": "Detailed Shopify Analytics",
                    "Google Ads & Meta Ads Integration": "Google Ads & Meta Ads Integration",
                    "RFM Analysis and Customer Segments": "RFM Analysis and Customer Segments",
                    "First Click, Last Click, Linear Attribution": "First Click, Last Click, Linear Attribution",
                    "Visitor History for Converted Visits": "Visitor History for Converted Visits",
                    "Visitor History for All Visits": "Visitor History for All Visits",
                    "Time-Decay Attribution": "Time-Decay Attribution",
                    "Position-Based Attribution": "Position-Based Attribution",
                    "Merchant Friendly Cost": "Merchant Friendly Cost",
                    "Yes": "Yes",
                    "No": "No",






                }

            },
            tr: {
                translation: {
                    "home": "Ana Sayfa",
                    "product": "Ürün",
                    "pricing": "Fiyatlandırma",
                    "resources": "Kaynaklar",
                    "login": "Giriş",
                    "bookDemo": "Demo Randevusu Al",
                    "cookieSettings": "Çerez Ayarları",
                    "cookieDescription": "İçeriği kişiselleştirmeye, reklamları şekillendirmeye ve ölçmeye ve daha iyi bir deneyim sunmaya yardımcı olmak için çerezleri ve benzer teknolojileri kullanıyoruz. Kabul et'e tıklayarak, Çerez Politikamızda belirtildiği gibi bunu kabul etmiş olursunuz.",
                    "acceptAll": "Hepsini Kabul Et",
                    "acceptEssential": "Sadece Temel Çerezleri Kabul Et",
                    "welcome": "Hoşgeldiniz",
                    "realTimeInsights": "Gerçek Zamanlı Veriler: Müşteri Odaklı Verilerle Kararlarınızı Güçlendirin",
                    "betterAdTracking": "Daha Akıllı Kararlar İçin Daha İyi Reklam Takibi",
                    "servicesWeOffer": "Sunduğumuz Hizmetler",
                    "servicesDescription": "Şirketinizin benzersiz ihtiyaçlarını karşılamak için özelleştirilmiş, yenilikçi ve verimli çözümlerle başarınızı artırıyoruz.",
                    "ourClients": "En İyi Müşterilerimiz ve Ortaklarımız",
                    "whatClientsSay": "Müşterilerimizin Söyledikleri",
                    "clientStories": "En iyi müşterilerimiz ve ortaklarımız tarafından paylaşılan başarı hikayeleri ve deneyimlerle ilham alın.",
                    "faq": "Sıkça Sorulan Sorular",
                    "faqDescription": "Ürünlerimiz ve hizmetlerimiz hakkında sıkça sorulan sorulara cevap veren rehberiniz.",
                    "faqQuestion1": "Etkileşimli panolarınızı kullanarak platformlar arasında reklam performansını nasıl izleyebilirim?",
                    "faqAnswer1": "Hemen bir demo talep edebilirsiniz ve biz de buradan devam ederiz! Kaydolduktan sonra, kuruluşunuzun farklı üyeleri kendi etkileşimli panolarını özelleştirerek giriş yapabilirler.",
                    "faqQuestion2": "Reklam kampanyalarını optimize etmeye yardımcı olmak için hangi gelişmiş metrikler sağlanmaktadır?",
                    "faqAnswer2": "Tıklama başına maliyet, edinim başına maliyet, tıklama oranı, ROAS, ortalama sipariş değeri, ömür boyu değer, ortalama oturum süresi ve gerçek dünya olaylarına, sektörünüzün genel durumuna ve ürünlerinizle ilgili trendlere göre ROAS'ı normalleştiren Roasberry metriği olan Ayarlanmış ROAS gibi veriler sağlıyoruz.",
                    "faqQuestion3": "Performans raporlaması için özelleştirme seçenekleri ve otomatik özellikleri açıklayabilir misiniz?",
                    "faqAnswer3": "Raporlarınızı zaman dilimleri, platformlar, metrikler ve raporun odak noktası olmak istediğiniz şeylere göre özelleştirebilirsiniz.",
                    "faqQuestion4": "Platformunuz reklam maliyetlerini izlerken nasıl şeffaflık sağlar?",
                    "faqAnswer4": "Tüm reklam maliyetlerinin, reklam harcamaları, platform ücretleri ve ek masraflar dahil olmak üzere ayrıntılı, ayrıntılı izlenmesini sağlıyoruz. Gelişmiş metriklerimiz ve raporlama seçeneklerimiz, reklam bütçenizin nereye tahsis edildiği ve her platformda nasıl performans gösterdiği konusunda size tam görünürlük sağlar.",
                    "adAnalytics": "Reklam Analitiği",
                    "adAnalyticsDescription": "Kampanyalarınızın hesabını tutmak için gelişmiş dijital pazarlama metrikleri.",
                    "adAnalyticsDetailedDescription": "Farklı platformlardaki tüm reklam kampanyalarınız, kullanımı kolay bir panoda bir araya geliyor. Reklamlarınızın detaylı performans metriklerini görün ve markanız üzerinde en çok istenen etkiyi yaratan kampanyaları anlayın. Reklam bütçenizin gerçek yaşam etkilerini görün ve harcamalarınızı buna göre ayarlayın.",
                    "pixelTracking": "Piksel Takibi",
                    "pixelTrackingDescription": "Mağazanızda kapsamlı ziyaretçi takibi ile verilerinizi en üst düzeye çıkarın.",
                    "pixelTrackingDetailedDescription": "Berry pikselini çevrimiçi mağazanıza uygulayın ve ziyaretçilerinizin web sitenize gelmeden önceki davranışlarını kaydedelim. Ayrıca, geri dönen ziyaretçileri tespit ederiz ve tüm kanallarda en bilgilendirici satış atıflarını gerçekleştiririz.",
                    "customerJourneys": "Müşteri Yolculukları",
                    "customerJourneysDescription": "Ziyaretçi davranışlarını ve çıkma oranlarını analiz ederek müşteri deneyimini geliştirin.",
                    "customerJourneysDetailedDescription": "Web sitenizde bir ziyaretçinin ortalama yolculuğunu görselleştirin ve her sayfanın çıkma oranını görün. Ziyaretçilerinizin en çok nerede zaman harcadığını, gezinirken hangi desenleri en çok ziyaretçilerin takip ettiğini ve hangi sayfanın en fazla ödeme gerçekleştirdiğini anlamak, müşteri deneyimini ve dolayısıyla geliri artırmak için çok önemlidir.",
                    "askBerry": "Berry'ye Sor",
                    "askBerryDescription": "Marka verilerinden kısa raporlar hazırlayarak pazarlama görevlerini basitleştirin.",
                    "askBerryDetailedDescription": "Pazarlama ekibi fazla mı çalışıyor? Biz size yardımcı oluruz. Berry'ye sor, markanızın verilerini analiz etmek ve farklı uzunluklarda ve detaylarda yönetici özetleri ve pazarlama raporları oluşturmak için tasarlanmış bir yapay zeka aracıdır.",
                    "clientDescription1": "“Roasberry, veri analizini basitleştiren sezgisel arayüzü ile rakipsiz veriler sunarak öne çıkıyor. Roasberry ile farklı kanallarda olan her şeyi kontrol altında tutabiliyoruz.”",
                    "clientDescription2": "“Atıf ve piksel takibi söz konusu olduğunda, Roasberry önde gidiyor. Hassasiyeti ve sezgisel arayüzü veri analizini kolaylaştırıyor ve geçmişte denediğimiz diğer platformları geride bırakan veriler sağlıyor.”",
                    "clientDescription3": "“Atıf ve piksel takibi Roasberry tarafından mükemmelleştirildi. Hassasiyeti ve kullanıcı dostu arayüzü rakiplerini geride bırakıyor, bizi daha akıllı kararlar almaya yönlendiriyor. Roasberry ile pazarlama hedeflerine ulaşmak sorunsuz oluyor.”",

                    //footer
                    "contactUs": "Bize Ulaşın",
                    "careers": "Kariyer",
                    "refundPolicy": "İade Politikası",
                    "privacyPolicy": "Gizlilik Politikası",
                    "subscribeToNewsletter": "Roasberry’nin Bültenine Abone Olun!",
                    "newsletterDescription": "Reklam takibi, optimizasyon ipuçları ve sektör trendleri hakkında en son bilgileri gelen kutunuza alın!",
                    "enterEmail": "E-posta Adresinizi Girin",
                    "subscribe": "Abone Ol",
                    "companyInfo": "Roasberry Bilişim Yazılım Geliştirme",
                    "companyAddress": "Mehmet Akif Mah. Okay Cad. Öztürk Apt No:5B Ümraniye/İstanbul",
                    "allRightsReserved": "© 2024 Roasberry Inc. Tüm hakları saklıdır.",

                    //PRODUCT PAGE
                    "allInOneMarketingPlatform": "Hepsi Bir Arada Pazarlama Platformu.",
                    "productDescription": "Roasberry, çağdaş markalara kampanyaları hakkında derin, kapsamlı ve özelleştirilebilir analizler sunmak için sofistike veri modellerinden yararlanan bir dijital pazarlama optimizasyon platformudur. ROAS'larını en üst düzeye çıkarmalarına ve operasyonlarını karlı bir şekilde genişletmelerine olanak tanır.",
                    "integrations": "Entegrasyonlar",
                    "createDashboard": "Hızlı, Kişiselleştirilmiş Bir Gösterge Tablosu Oluşturun",
                    "dashboardDescription": "Tüm Metriğiniz Gerçek Zamanlı Olarak Karıştırılabilir, Hesaplanabilir ve Analiz Edilebilir.",
                    "tailorDashboard": "Roasberry'nin gösterge tablosu sayfasını gereksinimlerinize göre uyarlayın ve gelişmeleri hızlı bir şekilde görmek için sınırsız geri dönüş süresini kullanın.",
                    "viewSuccess": "Özelleştirilmiş kitleleri kullanarak başarıyı görün ve en yüksek katılım ve AOV'ye sahip müşteri kümelerine dikkat çekin.",
                    "createMetrics": "Kendi birinci taraf verilerinizi kullanarak Roasberry'de oluşturduğunuz özel kitlelerle başarıyı izleyin.",
                    "meetBerry": "Berry ile Tanışın, 7/24 Reklam Takip Asistanınız",
                    "berryDescription": "Hızlı ve Özelleştirilmiş İçgörüler İçin Reklam Takip Asistanınız",
                    "customizeBerry": "Berry'yi ihtiyaçlarınıza mükemmel şekilde uyacak şekilde özelleştirin ve uzun vadeli ilerlemenizi izlemek için sınırsız tarihsel veri erişiminin keyfini çıkarın.",
                    "achieveSuccess": "Berry'nin güçlü yetenekleriyle başarıya ulaşın. Kendi ilk taraf verilerinizi kullanarak raporlama oluşturun, kampanyalarınızı güçlendirin ve zahmetsiz ROI optimizasyonu elde edin.",
                    "controlAdTracking": "Sadece birkaç tıklama ile çeşitli platformlardan 7/24 reklam takip verileriniz üzerinde kontrol sahibi olursunuz.",
                    "knowledgeOfCustomers": "Kim, Ne, Ne Zaman ve Nasıl Bilgisi",
                    "insightsDescription": "Müşterileriniz ve ürünleriniz hakkında önemli sorulara yanıt bulmanıza yardımcı olmak için Roasberry 10+ veri aracı sunar.",
                    "maximizeCLV": "Müşteri yaşam boyu değerini en üst düzeye çıkarın ve müşteri sadakatini artırmak için siparişler arasındaki ortalama zamandan yararlanın. En değerli kohortlarınızdan öğrenin.",
                    "identifyValuableProducts": "En değerli ürünlerinizi belirleyin, en çok satan paketleri tanıtın ve tekrar satın alımları teşvik eden kilit promosyonlara güvenin.",
                    "defineCustomerSegments": "Kişiselleştirilmiş deneyimler, hedefli iletişimler ve büyüme fırsatları yaratmak için müşteri segmentlerini tanımlayın.",

                    //PRICING PAGE
                    "pricingTitle": "Tek Fiyat, ",
                    "pricingDescription": "Merhaba, hizmetlerimize gösterdiğiniz ilgi için teşekkür ederiz. Başlamak için, aşağıdaki açılır menüyü kullanarak son 12 ay içindeki tahmini toplam gelirinizin USD cinsinden ne olduğunu bize bildirin. Sorularınız varsa, ekibimiz size yardımcı olmaktan memnuniyet duyacaktır.",
                    "annualRevenueQuestion": "Son 12 ay içindeki toplam geliriniz neydi (USD cinsinden)?",
                    "annualBillingMessage": "Yıllık Ödeyin, 2 Ay Ücretsiz!",
                    "billedMonthly": "Aylık Faturalandırma",
                    "billedAnnually": "Yıllık Faturalandırma",
                    "pricingFAQTitle": "Sıkça Sorulan Sorular",
                    "pricingFAQDescription": "Ürünlerimiz ve hizmetlerimiz hakkında sıkça sorulan sorulara cevap veren rehberiniz.",
                    "pricingFAQQuestion1": "Ücretsiz deneme süresi var mı?",
                    "pricingFAQAnswer1": "Evet, tabii ki! Ürünümüzün ihtiyaçlarınıza uygun olup olmadığını test etmek isteyen herkes için 14 günlük ücretsiz deneme süremiz var ve kredi kartı bilgisi gerekmemektedir.",
                    "pricingFAQQuestion2": "Herhangi bir kayıt ücreti veya gizli ücret var mı?",
                    "pricingFAQAnswer2": "Hayır, ek ücret yoktur. Tercihinize göre her faturalandırma döneminde bir kez ücretlendirilirsiniz.",
                    "pricingFAQQuestion3": "Yıllık üyeliklerde indirim var mı?",
                    "pricingFAQAnswer3": "Evet, yıllık faturalandırılan müşterilerimiz iki ay ücretsiz hizmet almaktadır.",
                    "pricingFAQQuestion4": "Planım şirketim içinde sınırsız kullanıcıya izin veriyor mu?",
                    "pricingFAQAnswer4": "Evet, “Tek fiyat, tam paket.” yaklaşımımız, kuruluşunuzdaki her kişinin platforma erişim sağlayabileceği anlamına gelen bir kurumsal lisans içermektedir.",
                    "pricingFAQQuestionRefund": "Geri ödeme politikası var mı?",
                    "pricingFAQAnswerRefund": "Evet, lütfen bu sayfanın altbilgi bölümüne bakın.",
                    "pricingFAQQuestionRevenue": "Neden gelir bilgilerini paylaşmam isteniyor?",
                    "pricingFAQAnswerRevenue": "Gelir verileriniz, şirketinizle ilgili veri depolama maliyetlerini tahmin etmek için kullanılmaktadır ve bu, içinde bulunduğunuz fiyatlandırma katmanını etkiler. Gelir verilerinin diğer kullanımları kesinlikle yasaktır - lütfen bu sayfanın altbilgi bölümüne bakın.",
                    "pricingFAQQuestionSupport": "Satış destek ekibinizle nasıl iletişime geçebilirim?",
                    "pricingFAQAnswerSupport": "Bize info@roasberry.com adresinden ulaşabilirsiniz.",
                    "pricingFAQQuestionChanges": "Ürünü kullanırken bulunduğum gelir katmanı değişirse ne olur?",
                    "pricingFAQAnswerChanges": "Öncelikle, tebrikler! Gelir artışları, Roasberry müşterileri arasında son derece yaygındır. Planınız, kaydolduktan 12 ay sonra güncellenecek, bu nedenle kaydolduktan sonra aynı planı 12 ay boyunca kullanmaya devam edebilirsiniz.",
                    "pixelTracking": "Piksel Takibi",
                    "allInOneDashboard": "Hepsi Bir Arada Gösterge Tablosu",
                    "customerJourneys": "Müşteri Yolculukları",
                    "automatedReporting": "Otomatik Raporlama",
                    "customerSupport": "Müşteri Desteği",
                    "unlimitedDevices": "Sınırsız Cihaz",
                    "advancedMarketingAnalytics": "Gelişmiş Pazarlama Analitiği",
                    "month": "ay",
                    "year": "yıl",
                    "start14DayFreeTrial": "14 Günlük Ücretsiz Denemeye Başlayın",
                    //RESOURCES PAGE
                    "Roasberry News": "Roasberry Haberler",
                    "We are transparent about our technology.": "Teknolojimiz konusunda şeffafız.",
                    "Understand how our process works and what we are doing to skyrocket your ecommerce brand's success.": "Sürecimizin nasıl çalıştığını ve e-ticaret markanızın başarısını artırmak için neler yaptığımızı anlayın.",
                    "No algorithm selected": "Hiçbir algoritma seçilmedi",
                    "Please click on an algorithm to see its details.": "Detaylarını görmek için bir algoritmaya tıklayın.",
                    "About Us": "Hakkımızda",
                    "Contact Information": "İletişim Bilgileri",
                    "Get in touch with the Roasberry Team": "Roasberry Ekibiyle İletişime Geçin",
                    "Send Message": "Mesaj Gönder",

                    "We are an international marketing intelligence SAAS (Software as a Service) company on a mission to provide cutting-edge solutions for the global marketing industry. While we have a strong presence in the United States and Turkey, our reach extends far beyond these borders.": "Biz, küresel pazarlama endüstrisi için son teknoloji çözümler sunmayı misyon edinmiş uluslararası bir pazarlama istihbaratı SAAS (Hizmet Olarak Yazılım) şirketiyiz. Amerika Birleşik Devletleri ve Türkiye'de güçlü bir varlığımız olmasına rağmen, etki alanımız bu sınırların çok ötesine uzanmaktadır.",

                    "Our core expertise lies in services such as Pixel Tracking and Data Analysis, essential components of modern marketing strategies. We cater to businesses seeking innovative ways to optimize their marketing efforts, enhance their customer targeting, and drive more effective campaigns.": "Ana uzmanlık alanımız, modern pazarlama stratejilerinin temel bileşenleri olan Piksel Takibi ve Veri Analizi gibi hizmetlerdir. Pazarlama çabalarını optimize etmek, müşteri hedeflemeyi iyileştirmek ve daha etkili kampanyalar yürütmek için yenilikçi yollar arayan işletmelere hizmet veriyoruz.",

                    "In today's dynamic and data-driven business environment, our solutions empower companies to harness the power of data analytics and pixel tracking to gain valuable insights into consumer behavior, campaign performance, and market trends. We are also committed to promoting ESG (Environmental, Social, and Governance) principles and EDI (Equity, Diversity, and Inclusion) in the marketing field, ensuring that our clients align with ethical and socially responsible practices.": "Bugünün dinamik ve veri odaklı iş ortamında, çözümlerimiz, şirketlerin tüketici davranışı, kampanya performansı ve pazar trendleri hakkında değerli bilgiler edinmek için veri analitiği ve piksel takibinin gücünden yararlanmalarını sağlar. Ayrıca pazarlama alanında ESG (Çevresel, Sosyal ve Yönetişim) ilkelerini ve EDI (Eşitlik, Çeşitlilik ve Dahil Etme) uygulamalarını teşvik etmeyi taahhüt ediyoruz, bu da müşterilerimizin etik ve sosyal olarak sorumlu uygulamalara uyum sağlamasını garanti eder.",

                    "Our dedication to innovation, ESG, and EDI, combined with our tailored services that cater to the unique demands of markets worldwide, allows us to keep our clients ahead of the competition and make informed decisions to maximize their ROI. We are firmly committed to staying at the forefront of marketing intelligence technology, continuously evolving our offerings to meet the ever-changing needs of our clients. This commitment to excellence, innovation, and customer satisfaction sets us apart as a trusted partner in the world of marketing intelligence.": "İnovasyona, ESG ve EDI'ye olan bağlılığımız, dünyanın dört bir yanındaki pazarların benzersiz taleplerini karşılayan özel hizmetlerimizle birleştiğinde, müşterilerimizi rekabetin önünde tutmamıza ve yatırım getirilerini maksimize etmek için bilinçli kararlar almalarına olanak tanır. Pazarlama istihbaratı teknolojisinin ön saflarında kalma, sunduğumuz hizmetleri sürekli olarak geliştirme ve müşterilerimizin sürekli değişen ihtiyaçlarını karşılama konusunda kararlıyız. Bu mükemmellik, inovasyon ve müşteri memnuniyeti taahhüdü, bizi pazarlama istihbaratı dünyasında güvenilir bir ortak olarak ayırır.",
                    "Marketing Metrics": "Pazarlama Metrikleri",
                    "Exploring beyond micro marketing measurements and considering the holistic view of various marketing efforts is crucial for brands in eCommerce. It is not sufficient to rely solely on channel-specific results or campaign ROAS when making major marketing decisions. To make the best decisions for your business, it is essential to examine case studies, delve into actionable strategies, and understand the overall customer journey. By doing so, brands can gain valuable insights into how different marketing initiatives contribute to their return on investment.": "Mikro pazarlama ölçümlerinin ötesine geçmek ve çeşitli pazarlama çabalarının bütünsel bir görünümünü dikkate almak, e-Ticaret markaları için çok önemlidir. Büyük pazarlama kararları alırken yalnızca kanal bazlı sonuçlara veya kampanya ROAS'ına güvenmek yeterli değildir. İşiniz için en iyi kararları vermek için, vaka çalışmalarını incelemek, uygulanabilir stratejilere dalmak ve genel müşteri yolculuğunu anlamak önemlidir. Bunu yaparak, markalar farklı pazarlama girişimlerinin yatırım getirilerine nasıl katkıda bulunduğuna dair değerli bilgiler edinebilirler.",
                    "Data Analytics": "Veri Analitiği",
                    "Through advanced data analytics, the dashboard leverages AI algorithms to analyze vast amounts of customer data, including purchase history, browsing behavior, demographics, and more. These algorithms identify patterns, trends, and correlations that may go unnoticed to human analysts, enabling businesses to make data-driven decisions.": "Gelişmiş veri analitiği yoluyla, kontrol paneli, satın alma geçmişi, gezinme davranışı, demografik bilgiler ve daha fazlası dahil olmak üzere büyük miktarda müşteri verisini analiz etmek için AI algoritmalarından yararlanır. Bu algoritmalar, insan analistlerin fark etmeyebileceği desenleri, trendleri ve korelasyonları belirleyerek işletmelerin veri odaklı kararlar almasını sağlar.",
                    "AI & ML Powered Dashboards": "AI ve ML Destekli Kontrol Panelleri",
                    "One of the key features of the AI and ML-powered dashboard is its ability to segment customers based on their preferences, behaviors, and purchase patterns. By segmenting customers effectively, businesses can tailor their marketing messages, promotions, and product recommendations to specific groups, leading to higher conversion rates and personalized experiences.": "AI ve ML destekli kontrol panelinin ana özelliklerinden biri, müşterileri tercihleri, davranışları ve satın alma desenlerine göre segmentlere ayırma yeteneğidir. Müşterileri etkili bir şekilde segmentlere ayırarak, işletmeler pazarlama mesajlarını, promosyonlarını ve ürün önerilerini belirli gruplara göre özelleştirebilir, bu da daha yüksek dönüşüm oranlarına ve kişiselleştirilmiş deneyimlere yol açar.",
                    "Algorithms": "Algoritmalar",
                    "The AI-powered dashboard utilizes K-means clustering to automatically identify meaningful customer segments, which can then be visualized and analyzed through intuitive dashboards and visualizations. These segments represent clusters of customers who exhibit similar characteristics or behaviors, allowing businesses to tailor their marketing strategies and offerings accordingly.": "AI destekli kontrol paneli, anlamlı müşteri segmentlerini otomatik olarak tanımlamak için K-means kümeleme algoritmasını kullanır, bu segmentler daha sonra sezgisel kontrol panelleri ve görselleştirmeler aracılığıyla görselleştirilebilir ve analiz edilebilir. Bu segmentler, benzer özellikler veya davranışlar sergileyen müşteri kümelerini temsil eder, bu da işletmelerin pazarlama stratejilerini ve tekliflerini buna göre uyarlamalarını sağlar.",
                    "Read about industry trends and stories surrounding digital marketing for e-commerce businesses and understand how Roasberry’s technological developments are helping enterprises position themselves for growth.": "E-ticaret işletmeleri için dijital pazarlamayı çevreleyen sektör trendleri ve hikayeleri okuyun ve Roasberry'nin teknolojik gelişmelerinin işletmelerin büyüme için nasıl konumlandıklarını anlamalarına yardımcı olduğunu öğrenin.",
                    "See content specific to your location and shop online by selecting another country or region.": "Konumunuza özel içerikleri görmek ve online alışveriş yapmak için başka bir ülkeyi veya bölgeyi seçin.",
                    "Continue": "Devam Et",
                    "learnMore": "Daha fazla bilgi",


                    "Uncover Hidden Profit Potential: Leverage Roasberry's Advanced Data Models to Unlock Growth": "Gizli Kar Potansiyelini Ortaya Çıkarın: Roasberry'nin Gelişmiş Veri Modellerini Kullanarak Büyümeyi Keşfedin",
                    "In the ever-evolving landscape of digital marketing, staying ahead of the competition requires more than just a creative campaign. It demands a deep understanding of your audience, precise targeting, and the ability to adapt swiftly to emerging trends. Roasberry, a cutting-edge digital marketing optimization platform, is revolutionizing the way contemporary brands approach their campaigns. By leveraging sophisticated data models, Roasberry provides a deep, thorough, and customizable analysis, empowering brands to maximize their Return on Ad Spend (ROAS) and unlock untapped growth potential.": "Dijital pazarlamanın sürekli değişen dünyasında, rekabetin önünde kalmak sadece yaratıcı bir kampanyadan fazlasını gerektirir. Bu, hedef kitlenizi derinlemesine anlamayı, kesin hedeflemeyi ve ortaya çıkan trendlere hızlı bir şekilde uyum sağlamayı gerektirir. Roasberry, modern markaların kampanyalarına yaklaşımını devrim niteliğinde değiştiren, ileri teknoloji bir dijital pazarlama optimizasyon platformudur. Gelişmiş veri modellerini kullanarak, Roasberry derin, kapsamlı ve özelleştirilebilir bir analiz sunar, markaların Reklam Harcama Getirisini (ROAS) maksimize etmelerini ve keşfedilmemiş büyüme potansiyelini ortaya çıkarmalarını sağlar.",
                    "Understanding the Power of Roasberry's Advanced Data Models:": "Roasberry'nin Gelişmiş Veri Modellerinin Gücünü Anlamak:",
                    "At the core of Roasberry's success lies its advanced data models, designed to sift through vast amounts of data to extract meaningful insights. These models go beyond conventional analytics, providing a comprehensive understanding of campaign performance, customer behavior, and market trends. The platform's ability to process and analyze data in real-time allows brands to make informed decisions on the fly, ensuring their marketing strategies remain agile and effective.": "Roasberry'nin başarısının temelinde, büyük miktarda veriyi anlamlı veriler elde etmek için taramak üzere tasarlanmış gelişmiş veri modelleri yatmaktadır. Bu modeller, kampanya performansı, müşteri davranışı ve pazar trendleri hakkında kapsamlı bir anlayış sunarak geleneksel analizlerin ötesine geçer. Platformun verileri gerçek zamanlı olarak işleyip analiz edebilme yeteneği, markaların anında bilinçli kararlar almasını sağlar, böylece pazarlama stratejileri esnek ve etkili kalır.",
                    "Customizable Analysis for Tailored Solutions:": "Özelleştirilmiş Çözümler İçin Özelleştirilebilir Analiz:",
                    "No two brands are identical, and Roasberry recognizes this diversity. The platform offers a high degree of customization, allowing brands to tailor the analysis to their unique needs and goals. Whether you're focused on lead generation, brand awareness, or e-commerce sales, Roasberry's data models can be fine-tuned to deliver insights that align with your specific objectives. This level of flexibility ensures that the platform is a valuable asset for brands across various industries.": "Hiçbir iki marka birbirinin aynı değildir ve Roasberry bu çeşitliliği tanır. Platform, analizleri markaların benzersiz ihtiyaçlarına ve hedeflerine göre özelleştirmelerine olanak tanıyan yüksek düzeyde özelleştirme sunar. İster potansiyel müşteri oluşturma, ister marka bilinirliği veya e-ticaret satışlarına odaklanıyor olun, Roasberry'nin veri modelleri, belirli hedeflerinizle uyumlu veriler sağlamak için ince ayar yapabilir. Bu düzeydeki esneklik, platformun çeşitli sektörlerdeki markalar için değerli bir varlık olmasını sağlar.",
                    "Maximizing ROAS for Profitable Growth:": "Karlılık İçin ROAS'ı Maksimize Etmek:",
                    "Return on Ad Spend (ROAS) is a key metric for measuring the success of digital marketing efforts. Roasberry goes beyond providing a basic ROAS calculation; it dives deep into the factors influencing your ROAS, uncovering opportunities for improvement.": "Reklam Harcama Getirisi (ROAS), dijital pazarlama çabalarının başarısını ölçmek için önemli bir ölçüttür. Roasberry, temel bir ROAS hesaplaması sağlamanın ötesine geçer; ROAS'ınızı etkileyen faktörleri derinlemesine inceler ve iyileştirme fırsatlarını ortaya çıkarır.",
                    "By identifying high-performing channels, optimizing ad creatives, and refining targeting parameters, Roasberry enables brands to maximize their ROAS and achieve sustainable, profitable growth.": "Yüksek performans gösteren kanalları belirleyerek, reklam yaratıcılarını optimize ederek ve hedefleme parametrelerini rafine ederek, Roasberry markaların ROAS'larını maksimize etmelerini ve sürdürülebilir, karlı büyümeye ulaşmalarını sağlar.",
                    "Real-time Insights for Agile Decision-Making:": "Çevik Karar Alma İçin Gerçek Zamanlı İçgörüler:",
                    "In the fast-paced world of digital marketing, timely decisions can make all the difference. Roasberry's real-time analytics capabilities ensure that brands have access to the latest insights when they need them most. Whether it's adjusting bidding strategies, reallocating budget to high-performing channels, or pivoting campaign messaging based on emerging trends, Roasberry empowers brands to stay one step ahead of the competition.": "Hızlı tempolu dijital pazarlama dünyasında, zamanında alınan kararlar tüm farkı yaratabilir. Roasberry'nin gerçek zamanlı analiz yetenekleri, markaların en çok ihtiyaç duydukları anda en son verilere erişimini sağlar. İster teklif stratejilerini ayarlamak, ister bütçeyi yüksek performans gösteren kanallara yeniden tahsis etmek veya ortaya çıkan trendlere göre kampanya mesajlarını değiştirmek olsun, Roasberry markaların rekabetin bir adım önünde kalmasını sağlar.",
                    "Unlocking Growth Opportunities:": "Büyüme Fırsatlarını Açığa Çıkarmak:",
                    "Roasberry isn't just a tool for optimizing existing campaigns; it's a catalyst for unlocking new growth opportunities. By providing a holistic view of your marketing ecosystem, Roasberry identifies areas where your brand can expand its reach, connect with untapped audiences, and capitalize on emerging market trends. The platform's forward-looking approach positions brands for long-term success in an ever-changing digital landscape.": "Roasberry, mevcut kampanyaları optimize etmek için sadece bir araç değildir; yeni büyüme fırsatlarını açığa çıkarmak için bir katalizördür. Pazarlama ekosisteminizin bütünsel bir görünümünü sunarak, Roasberry markanızın erişimini genişletebileceği, keşfedilmemiş kitlelerle bağlantı kurabileceği ve ortaya çıkan pazar trendlerinden yararlanabileceği alanları belirler. Platformun ileriye dönük yaklaşımı, markaları sürekli değişen dijital dünyada uzun vadeli başarı için konumlandırır.",
                    "Conclusion:": "Sonuç:",
                    "In the world of digital marketing, where success is measured in clicks and conversions, Roasberry stands out as a game-changer. Its advanced data models, customizable analysis, and focus on maximizing ROAS set it apart as a valuable asset for contemporary brands looking to unlock hidden profit potential and achieve sustainable growth. By leveraging the power of Roasberry, brands can navigate the complexities of the digital landscape with confidence, knowing that they have a partner dedicated to uncovering the insights that will drive their success.": "Tıklamalar ve dönüşümlerle ölçülen dijital pazarlama dünyasında, Roasberry bir oyun değiştirici olarak öne çıkıyor. Gelişmiş veri modelleri, özelleştirilebilir analizleri ve ROAS'ı maksimize etmeye odaklanması, gizli kar potansiyelini açığa çıkarmak ve sürdürülebilir büyümeye ulaşmak isteyen modern markalar için değerli bir varlık olarak öne çıkar. Roasberry'nin gücünden yararlanarak, markalar dijital dünyadaki karmaşıklıkları güvenle yönetebilir ve başarılarını yönlendirecek verileri ortaya çıkarmaya kendini adamış bir ortağa sahip olduklarını bilirler.",
                    "Gain a Strategic Edge with Roasberry's Customizable Campaign Analysis": "Roasberry'nin Özelleştirilebilir Kampanya Analizi ile Stratejik Bir Avantaj Elde Edin",
                    "In the ever-evolving landscape of digital marketing, staying ahead of the competition requires more than just intuition and creative flair. Brands that aim for sustained success understand the importance of data-driven decision-making. Enter Roasberry, a revolutionary digital marketing optimization platform that empowers contemporary brands with a strategic edge through its sophisticated Customizable Campaign Analysis.": "Dijital pazarlamanın sürekli değişen dünyasında, rekabetin önünde kalmak sadece sezgi ve yaratıcı yetenek gerektirmez. Sürekli başarıyı hedefleyen markalar, veri odaklı karar almanın önemini anlar. İşte Roasberry, modern markalara gelişmiş Özelleştirilebilir Kampanya Analizi aracılığıyla stratejik bir avantaj sağlayan devrim niteliğinde bir dijital pazarlama optimizasyon platformudur.",
                    "Unlocking the Power of Data:": "Verinin Gücünü Açığa Çıkarmak:",
                    "Roasberry doesn't just provide data; it transforms raw information into actionable insights. Leveraging cutting-edge data models, Roasberry enables brands to dive deep into the intricacies of their campaigns. The platform collects and analyzes a plethora of data points, offering a comprehensive view of the performance metrics that matter most.": "Roasberry sadece veri sağlamakla kalmaz; ham bilgiyi eyleme dönüştürülebilir verilere dönüştürür. İleri teknoloji veri modellerinden yararlanarak, Roasberry markaların kampanyalarının ayrıntılarına derinlemesine dalmalarını sağlar. Platform, birçok veri noktasını toplayarak ve analiz ederek en önemli performans metriklerine kapsamlı bir bakış sunar.",
                    "Customization for Precision:": "Hassasiyet İçin Özelleştirme:",
                    "One size does not fit all in the world of digital marketing, and Roasberry understands this implicitly. The platform's Customizable Campaign Analysis allows brands to tailor their data analysis to align with their unique goals and objectives. Whether you're focused on increasing brand awareness, driving conversions, or optimizing ad spend efficiency, Roasberry adapts to your specific needs.": "Dijital pazarlama dünyasında tek beden herkese uymaz ve Roasberry bunu zımnen anlar. Platformun Özelleştirilebilir Kampanya Analizi, markaların veri analizlerini benzersiz hedef ve amaçlarıyla uyumlu olacak şekilde özelleştirmelerine olanak tanır. İster marka bilinirliğini artırmaya, ister dönüşümleri artırmaya veya reklam harcama verimliliğini optimize etmeye odaklanıyor olun, Roasberry sizin özel ihtiyaçlarınıza uyum sağlar.",
                    "Through Analysis for Informed Decision-Making:": "Bilgilendirilmiş Karar Verme İçin Kapsamlı Analiz:",
                    "The key to successful marketing lies in making informed decisions, and Roasberry equips brands with the tools to do just that. The platform goes beyond surface-level metrics, providing a nuanced and thorough analysis of campaign performance. From customer demographics and behavior patterns to channel effectiveness and creative performance, Roasberry leaves no stone unturned.": "Başarılı pazarlamanın anahtarı bilinçli kararlar almaktır ve Roasberry markaları bunu yapacak araçlarla donatır. Platform, yüzeysel metriklerin ötesine geçerek kampanya performansının ayrıntılı ve kapsamlı bir analizini sunar. Müşteri demografisinden davranış kalıplarına, kanal etkinliğinden yaratıcı performansa kadar, Roasberry hiçbir taşı bırakmaz.",
                    "Maximizing ROAS for Sustainable Growth:": "Sürdürülebilir Büyüme İçin ROAS'ı Maksimize Etmek:",
                    "Return on Ad Spend (ROAS) is the heartbeat of any successful marketing campaign, and Roasberry understands its pivotal role. By delving into the intricacies of your campaigns, the platform identifies areas of improvement and optimization, allowing brands to maximize their ROAS. This not only enhances short-term profitability but also lays the foundation for sustainable, long-term growth.": "Reklam Harcama Getirisi (ROAS), başarılı bir pazarlama kampanyasının kalp atışıdır ve Roasberry bunun merkezi rolünü anlar. Kampanyalarınızın ayrıntılarına inerek, platform iyileştirme ve optimizasyon alanlarını belirler, bu da markaların ROAS'larını maksimize etmelerini sağlar. Bu sadece kısa vadeli karlılığı artırmakla kalmaz, aynı zamanda sürdürülebilir, uzun vadeli büyüme için temel oluşturur.",
                    "Expanding Operations with Confidence:": "Operasyonları Güvenle Genişletmek:",
                    "Armed with the insights gained from Roasberry's Customizable Campaign Analysis, brands can confidently expand their operations. Whether entering new markets, scaling existing campaigns, or diversifying advertising channels, Roasberry's data-driven approach provides a solid foundation for strategic decision-making.": "Roasberry'nin Özelleştirilebilir Kampanya Analizinden elde edilen verilerle donanmış olarak, markalar operasyonlarını güvenle genişletebilir. Yeni pazarlara girme, mevcut kampanyaları ölçeklendirme veya reklam kanallarını çeşitlendirme olsun, Roasberry'nin veri odaklı yaklaşımı stratejik karar alma için sağlam bir temel sağlar.",
                    "Real Results, Real Impact:": "Gerçek Sonuçlar, Gerçek Etki:",
                    "Roasberry isn't just a tool; it's a partner in your journey towards marketing excellence. By harnessing the power of data, customization, and thorough analysis, Roasberry empowers brands to achieve real results and make a tangible impact on their bottom line.": "Roasberry sadece bir araç değil; pazarlama mükemmelliğine giden yolculuğunuzda bir ortaktır. Verinin, özelleştirmenin ve kapsamlı analizin gücünden yararlanarak, Roasberry markaların gerçek sonuçlar elde etmelerini ve kârlarında somut bir etki yaratmalarını sağlar.",
                    "In conclusion, in a digital landscape where precision and adaptability are paramount, Roasberry stands out as a beacon of innovation. Gain a strategic edge with Roasberry's Customizable Campaign Analysis and propel your brand towards unprecedented success in the dynamic world of digital marketing. Your data-driven future starts here.": "Sonuç olarak, kesinlik ve uyumun ön planda olduğu bir dijital ortamda, Roasberry bir inovasyon ışığı olarak öne çıkar. Roasberry'nin Özelleştirilebilir Kampanya Analizi ile stratejik bir avantaj elde edin ve markanızı dijital pazarlamanın dinamik dünyasında benzeri görülmemiş bir başarıya yönlendirin. Veri odaklı geleceğiniz burada başlıyor.",
                    "Unleash your Marketing Potential: Explore the Untapped Opportunities with Roasberry's Comprehensive Analysis": "Pazarlama Potansiyelinizi Açığa Çıkarın: Roasberry'nin Kapsamlı Analizi ile Keşfedilmemiş Fırsatları Keşfedin",
                    "In the rapidly evolving world of digital marketing, navigating the intricacies of data has become synonymous with success. Roasberry, a digital marketing optimization platform, takes center stage as a transformative force for contemporary brands. By employing sophisticated data models, Roasberry offers a profound analysis of campaigns, providing brands with the tools needed to maximize Return on Ad Spend (ROAS) and drive profitable expansion.": "Hızla değişen dijital pazarlama dünyasında, verinin karmaşıklıklarını yönetmek başarı ile eş anlamlı hale gelmiştir. Roasberry, modern markalar için dönüştürücü bir güç olarak sahneye çıkan bir dijital pazarlama optimizasyon platformudur. Gelişmiş veri modelleri kullanarak, Roasberry kampanyaların derinlemesine analizini sunar ve markalara Reklam Harcama Getirisini (ROAS) maksimize etmek ve karlı genişlemeyi sağlamak için gerekli araçları sağlar.",
                    "Decoding Data for Strategic Insights:": "Stratejik İçgörüler İçin Veriyi Çözmek:",
                    "In the age of information, successful marketing hinges on the ability to decode data and derive actionable insights. Roasberry excels at this, utilizing advanced data models to delve deep into the intricacies of campaigns. This approach ensures that brands gain valuable insights, recognize patterns, and make informed decisions based on a thorough understanding of their marketing landscape.": "Bilgi çağında, başarılı pazarlama veriyi çözme ve eyleme dönüştürülebilir veriler elde etme yeteneğine dayanır. Roasberry bu konuda mükemmeldir; kampanyaların ayrıntılarına derinlemesine dalmak için gelişmiş veri modellerini kullanır. Bu yaklaşım, markaların değerli veriler elde etmelerini, kalıpları tanımalarını ve pazarlama ortamlarını kapsamlı bir şekilde anlamalarına dayalı bilinçli kararlar almalarını sağlar.",
                    "Customization for Precision:": "Hassasiyet İçin Özelleştirme:",
                    "One size does not fit all in the world of digital marketing, and Roasberry understands this implicitly. The platform's Customizable Campaign Analysis allows brands to tailor their data analysis to align with their unique goals and objectives. Whether you're focused on increasing brand awareness, driving conversions, or optimizing ad spend efficiency, Roasberry adapts to your specific needs.": "Dijital pazarlama dünyasında tek beden herkese uymaz ve Roasberry bunu zımnen anlar. Platformun Özelleştirilebilir Kampanya Analizi, markaların veri analizlerini benzersiz hedef ve amaçlarıyla uyumlu olacak şekilde özelleştirmelerine olanak tanır. İster marka bilinirliğini artırmaya, ister dönüşümleri artırmaya veya reklam harcama verimliliğini optimize etmeye odaklanıyor olun, Roasberry sizin özel ihtiyaçlarınıza uyum sağlar.",
                    "Tailored Analysis for Targeted Impact:": "Hedeflenmiş Etki İçin Özelleştirilmiş Analiz:",
                    "Understanding the unique identity of each brand, Roasberry provides a tailored analysis that aligns with specific needs and goals. Whether optimizing social media advertising, fine-tuning search engine marketing, or refining email campaigns, Roasberry's comprehensive analysis adapts to deliver insights that are crucial for each brand's success.": "Her markanın benzersiz kimliğini anlayan Roasberry, belirli ihtiyaçlar ve hedeflerle uyumlu özelleştirilmiş bir analiz sunar. İster sosyal medya reklamcılığını optimize etmek, ister arama motoru pazarlamasını ince ayar yapmak veya e-posta kampanyalarını rafine etmek olsun, Roasberry'nin kapsamlı analizi her markanın başarısı için kritik veriler sunacak şekilde uyum sağlar.",
                    "Strategic Budgeting for Maximum ROAS:": "Maksimum ROAS İçin Stratejik Bütçeleme:",
                    "ROAS is a critical metric, and Roasberry goes beyond basic calculations by offering a nuanced understanding of the factors influencing returns. Through the analysis of each marketing channel's performance, audience segmentation, and creative impact, Roasberry empowers brands to strategically allocate budgets, ensuring maximum returns and sustainable growth.": "ROAS kritik bir ölçüttür ve Roasberry, getirileri etkileyen faktörler hakkında ayrıntılı bir anlayış sunarak temel hesaplamaların ötesine geçer. Her pazarlama kanalının performansının, kitle segmentasyonunun ve yaratıcı etkinin analiz edilmesi yoluyla, Roasberry markaların bütçeleri stratejik olarak tahsis etmelerini sağlar, böylece maksimum getiriler ve sürdürülebilir büyüme sağlanır.",
                    "Discovering Hidden Gems:": "Gizli Cevherleri Keşfetmek:",
                    "Within the vast expanse of digital marketing, hidden opportunities often await discovery. Roasberry serves as a guide, directing brands towards unexplored territories through its in-depth analysis. Whether it's untapped audience segments, overlooked channels, or emerging trends, Roasberry ensures brands seize potential growth opportunities.": "Dijital pazarlamanın geniş alanında, gizli fırsatlar genellikle keşfedilmeyi bekler. Roasberry, derinlemesine analizi yoluyla markaları keşfedilmemiş bölgelere yönlendiren bir rehber olarak hizmet eder. İster keşfedilmemiş kitle segmentleri, ister gözden kaçan kanallar veya ortaya çıkan trendler olsun, Roasberry markaların potansiyel büyüme fırsatlarını yakalamasını sağlar.",
                    "Staying Ahead with Adaptive Insights:": "Uyum Sağlayıcı İçgörülerle Önde Kalmak:",
                    "The digital marketing landscape is dynamic, and success requires adaptability. Roasberry keeps brands ahead of the curve by continually updating its data models to align with changing trends and consumer behavior. This commitment ensures that marketing strategies remain in sync with the latest industry insights, providing brands with a competitive edge.": "Dijital pazarlama ortamı dinamiktir ve başarı uyum gerektirir. Roasberry, veri modellerini sürekli olarak değişen trendler ve tüketici davranışları ile uyumlu hale getirerek markaları önde tutar. Bu taahhüt, pazarlama stratejilerinin en son sektör verileriyle uyumlu kalmasını sağlar ve markalara rekabet avantajı sağlar.",
                    "Roasberry emerges as a catalyst for marketing excellence, offering a transformative approach to navigating the complexities of the digital realm. Through its comprehensive analysis, powered by advanced data models, Roasberry becomes the key to unlocking your marketing potential. Maximizing ROAS, uncovering untapped opportunities, and staying ahead of industry trends, Roasberry positions itself as an indispensable ally in the dynamic world of digital marketing. Thrive in the digital landscape with Roasberry by your side, and let your marketing potential flourish.": "Roasberry, dijital alemin karmaşıklıklarında gezinmek için dönüştürücü bir yaklaşım sunarak pazarlama mükemmelliği için bir katalizör olarak ortaya çıkıyor. Gelişmiş veri modelleri tarafından desteklenen kapsamlı analizi ile Roasberry, pazarlama potansiyelinizi açığa çıkarmanın anahtarı haline gelir. ROAS'ı maksimize etmek, keşfedilmemiş fırsatları açığa çıkarmak ve sektör trendlerinin önünde kalmak, Roasberry'yi dinamik dijital pazarlama dünyasında vazgeçilmez bir müttefik olarak konumlandırır. Dijital ortamda Roasberry'nin yanınızda olmasıyla gelişin ve pazarlama potansiyelinizin parlamasına izin verin.",
                    "Stay Agile in a Dynamic Market: Roasberry's Thorough Analysis Keeps You Ahead": "Dinamik Bir Pazar İçinde Çevik Kalın: Roasberry'nin Kapsamlı Analizi Sizi Önde Tutar",
                    "In the fast-paced and ever-evolving landscape of digital marketing, staying ahead requires not just agility but a keen understanding of your campaigns and their impact. Contemporary brands navigating this dynamic market need a partner that goes beyond the basics, offering a level of analysis that is both thorough and adaptable. This is where Roasberry steps in, providing a sophisticated digital marketing optimization platform that ensures your brand stays agile in the face of constant change.": "Hızlı tempolu ve sürekli değişen dijital pazarlama ortamında önde kalmak sadece çeviklik değil, kampanyalarınızın ve etkilerinin keskin bir anlayışını gerektirir. Bu dinamik pazarda gezinmekte olan modern markalar, hem kapsamlı hem de uyumlu bir analiz sunan bir ortağa ihtiyaç duyar. İşte burada Roasberry devreye girer; markanızın sürekli değişim karşısında çevik kalmasını sağlayan sofistike bir dijital pazarlama optimizasyon platformu sunar.",
                    "Adaptability in a Shifting Landscape:": "Değişen Bir Ortamda Uyumluluk:",
                    "The digital market is inherently dynamic, with trends, consumer behaviors, and algorithms evolving at a rapid pace. To thrive in such an environment, brands must be agile – ready to pivot strategies and seize new opportunities as they arise. Roasberry recognizes the importance of adaptability and equips brands with a thorough analysis that acts as a compass in navigating the complexities of the digital landscape.": "Dijital pazar doğası gereği dinamiktir; trendler, tüketici davranışları ve algoritmalar hızla gelişir. Böyle bir ortamda başarılı olmak için markaların çevik olması gerekir – stratejileri değiştirmeye ve ortaya çıkan yeni fırsatları yakalamaya hazır olmalıdırlar. Roasberry, uyum sağlamanın önemini kabul eder ve markaları dijital ortamın karmaşıklıklarında yön bulmak için bir pusula görevi gören kapsamlı bir analizle donatır.",
                    "Uncover Insights Beyond the Surface:": "Yüzeyin Ötesindeki İçgörüleri Ortaya Çıkarın:",
                    "While surface-level metrics provide a basic understanding of campaign performance, Roasberry takes it a step further. Its sophisticated data models allow brands to uncover insights that go beyond the obvious, diving deep into the intricacies of each campaign. This level of analysis is crucial for identifying emerging trends, understanding audience behaviors, and making data-driven decisions that set the stage for success.": "Yüzey seviyesindeki metrikler kampanya performansının temel bir anlayışını sağlarken, Roasberry bir adım öteye gider. Sofistike veri modelleri, markaların bariz olanın ötesine geçen verileri ortaya çıkarmalarını sağlar, her kampanyanın ayrıntılarına derinlemesine dalar. Bu düzeydeki analiz, ortaya çıkan trendleri belirlemek, kitle davranışlarını anlamak ve başarıyı sağlamlaştıran veri odaklı kararlar almak için hayati öneme sahiptir.",
                    "Customizable Solutions for Your Unique Challenges:": "Benzersiz Zorluklarınız İçin Özelleştirilebilir Çözümler:",
                    "No two brands face identical challenges, and Roasberry understands the need for tailored solutions. The platform's customizable analysis ensures that your brand's unique requirements are not just acknowledged but addressed with precision. Whether it's adjusting targeting parameters, refining ad creatives, or optimizing across different channels, Roasberry's adaptability empowers brands to tackle their specific challenges head-on.": "Hiçbir iki marka aynı zorluklarla karşılaşmaz ve Roasberry özel çözümlere olan ihtiyacı anlar. Platformun özelleştirilebilir analizi, markanızın benzersiz gereksinimlerinin sadece kabul edilmesini değil, aynı zamanda hassasiyetle ele alınmasını sağlar. İster hedefleme parametrelerini ayarlamak, ister reklam yaratıcılarını rafine etmek veya farklı kanallarda optimize etmek olsun, Roasberry'nin uyum yeteneği markaların özel zorluklarını doğrudan ele almalarını sağlar.",
                    "Maximize ROAS and Expand Operations Smartly:": "ROAS'ı Maksimize Edin ve Operasyonları Akıllıca Genişletin:",
                    "In the competitive world of digital marketing, Return on Ad Spend (ROAS) is a crucial metric. Roasberry doesn't just focus on ROAS; it maximizes it by providing insights that lead to informed decisions. The platform's thorough analysis acts as a strategic guide, helping brands allocate budgets effectively, identify high-performing channels, and optimize campaigns for profitability.": "Rekabetçi dijital pazarlama dünyasında, Reklam Harcama Getirisi (ROAS) kritik bir ölçüttür. Roasberry sadece ROAS'a odaklanmaz; bilinçli kararlar alınmasına yol açan veriler sağlayarak bunu maksimize eder. Platformun kapsamlı analizi stratejik bir rehber olarak hareket eder, markaların bütçeleri etkili bir şekilde tahsis etmelerine, yüksek performans gösteren kanalları belirlemelerine ve kampanyaları karlılık için optimize etmelerine yardımcı olur.",
                    "Expanding operations profitably requires a nuanced understanding of market dynamics, and Roasberry's analysis serves as the compass for smart expansion. By uncovering opportunities and mitigating risks, the platform ensures that your brand grows not just rapidly but sustainably in the ever-shifting digital landscape.": "Operasyonları karlı bir şekilde genişletmek, pazar dinamiklerini ayrıntılı bir şekilde anlamayı gerektirir ve Roasberry'nin analizi akıllıca genişleme için bir pusula görevi görür. Fırsatları açığa çıkararak ve riskleri hafifleterek, platform markanızın sadece hızlı değil, aynı zamanda sürekli değişen dijital ortamda sürdürülebilir bir şekilde büyümesini sağlar.",
                    "In a market that demands constant adaptation, Roasberry stands as a beacon of agility and precision. Its commitment to providing brands with a thorough, customizable analysis is not just a service; it's a strategic advantage in the pursuit of digital marketing excellence.": "Sürekli uyum gerektiren bir pazarda, Roasberry çeviklik ve hassasiyetin bir işareti olarak öne çıkar. Markalara kapsamlı, özelleştirilebilir bir analiz sunma taahhüdü sadece bir hizmet değil; dijital pazarlama mükemmelliğine giden yolda stratejik bir avantajdır.",
                    "Stay ahead of the curve with Roasberry's comprehensive insights, adapt your strategies to the evolving market, and watch your brand not only survive but thrive in the dynamic digital landscape. In a world where staying relevant is key, Roasberry ensures your brand not only stays relevant but stays ahead. Embrace the power of thorough analysis – stay agile with Roasberry.": "Roasberry'nin kapsamlı verileri ile önde kalın, stratejilerinizi gelişen pazara uyarlayın ve markanızın sadece hayatta kalmasını değil, aynı zamanda dinamik dijital ortamda gelişmesini izleyin. Relevan kalmanın anahtar olduğu bir dünyada, Roasberry markanızın sadece ilgili kalmasını değil, aynı zamanda önde kalmasını sağlar. Kapsamlı analizin gücünü kucaklayın – Roasberry ile çevik kalın.",
                    "Unlock the Secrets to Campaign Success: Roasberry's Innovative Platform Maximizes ROAS": "Kampanya Başarısının Sırlarını Açığa Çıkarın: Roasberry'nin Yenilikçi Platformu ROAS'ı Maksimize Ediyor",
                    "In the intricate world of digital marketing, success is often seen as a code waiting to be cracked. For contemporary brands seeking not just success but excellence in their campaigns, Roasberry emerges as the master key. This innovative digital marketing optimization platform goes beyond conventional approaches, employing sophisticated data models to unlock the secrets of campaign success and maximize Return on Ad Spend (ROAS).": "Dijital pazarlamanın karmaşık dünyasında, başarı genellikle çözülmeyi bekleyen bir kod olarak görülür. Kampanyalarında sadece başarıyı değil, mükemmelliği arayan modern markalar için Roasberry anahtar olarak ortaya çıkar. Bu yenilikçi dijital pazarlama optimizasyon platformu, geleneksel yaklaşımların ötesine geçer ve kampanya başarısının sırlarını açığa çıkarmak ve Reklam Harcama Getirisini (ROAS) maksimize etmek için gelişmiş veri modellerini kullanır.",
                    "Decoding Success with Data-Driven Innovation:": "Veri Odaklı İnovasyonla Başarıyı Çözmek:",
                    "Roasberry understands that successful campaigns are built on a foundation of data-driven innovation. The platform leverages cutting-edge data models that go beyond traditional analytics, providing brands with a comprehensive understanding of their campaigns. This innovative approach is the key to cracking the code to campaign success, empowering brands to make informed decisions that drive results.": "Roasberry, başarılı kampanyaların veri odaklı inovasyon temeli üzerine inşa edildiğini anlar. Platform, geleneksel analizlerin ötesine geçen ileri teknoloji veri modellerinden yararlanarak markalara kampanyalarını kapsamlı bir şekilde anlama olanağı sağlar. Bu yenilikçi yaklaşım, kampanya başarısının kodunu çözmenin anahtarıdır ve markaların sonuçları yönlendiren bilinçli kararlar almalarını sağlar.",
                    "Unleashing the Power of Personalized Insights:": "Kişiselleştirilmiş İçgörülerin Gücünü Açığa Çıkarmak:",
                    "In the quest for success, one size does not fit all. Roasberry acknowledges the uniqueness of each brand and campaign, offering a personalized analysis that uncovers insights tailored to specific needs. This personalization is not just a feature; it's a philosophy that runs through the core of Roasberry's platform, ensuring that brands receive insights that resonate with their unique identity and goals.": "Başarı arayışında, tek beden herkese uymaz. Roasberry, her markanın ve kampanyanın benzersizliğini kabul eder ve belirli ihtiyaçlara göre özelleştirilmiş verileri ortaya çıkaran kişiselleştirilmiş bir analiz sunar. Bu kişiselleştirme sadece bir özellik değil; Roasberry platformunun özünde yer alan bir felsefedir ve markaların benzersiz kimlikleri ve hedefleri ile uyumlu veriler elde etmelerini sağlar.",
                    "Maximizing ROAS: More Than a Metric, It's a Mission:": "ROAS'ı Maksimize Etmek: Bir Metrikten Daha Fazlası, Bu Bir Misyon:",
                    "Return on Ad Spend (ROAS) is more than just a metric; it's a mission for brands seeking to maximize the impact of their campaigns. Roasberry doesn't just aim to improve ROAS; it strives to maximize it. The platform's innovative analysis identifies areas for optimization, hones in on high-performing channels, and guides brands toward strategies that ensure every advertising dollar delivers maximum value.": "Reklam Harcama Getirisi (ROAS) sadece bir metrikten daha fazlasıdır; kampanyalarının etkisini maksimize etmeyi amaçlayan markalar için bir misyondur. Roasberry sadece ROAS'ı iyileştirmeyi amaçlamaz; onu maksimize etmeye çalışır. Platformun yenilikçi analizi, optimizasyon alanlarını belirler, yüksek performans gösteren kanallara odaklanır ve markaları her reklam dolarının maksimum değer sağladığı stratejilere yönlendirir.",
                    "Innovative Solutions for a Competitive Edge:": "Rekabet Avantajı İçin Yenilikçi Çözümler:",
                    "In a digital landscape teeming with competition, innovation is the differentiator. Roasberry provides brands with innovative solutions that set them apart from the crowd. From advanced audience targeting strategies to creative optimization techniques, the platform equips brands with the tools they need to stay ahead in the ever-evolving digital marketing arena.": "Rekabetle dolu bir dijital ortamda, yenilik farklılaştırıcıdır. Roasberry, markalara onları kalabalıktan ayıran yenilikçi çözümler sunar. Gelişmiş kitle hedefleme stratejilerinden yaratıcı optimizasyon tekniklerine kadar, platform markaların sürekli gelişen dijital pazarlama arenasında önde kalmaları için ihtiyaç duydukları araçlarla donatır.",
                    "Roasberry – Your Key to Unlocked Success": "Roasberry – Başarının Anahtarı",
                    "In the quest for campaign success, Roasberry stands out as the key to unlocking the full potential of digital marketing endeavors. Its innovative platform, driven by sophisticated data models and a commitment to personalized insights, ensures that brands not only crack the code but rewrite it in their favor.": "Kampanya başarısı arayışında, Roasberry dijital pazarlama çabalarının tam potansiyelini açığa çıkarmanın anahtarı olarak öne çıkar. Gelişmiş veri modelleri ve kişiselleştirilmiş verilere bağlılıkla yönlendirilen yenilikçi platformu, markaların sadece kodu çözmelerini değil, aynı zamanda lehlerine yeniden yazmalarını sağlar.",
                    "Maximize your ROAS, unleash the power of data-driven innovation, and elevate your campaigns to new heights with Roasberry. In a world where success is elusive, Roasberry provides the code that transforms aspirations into achievements. Crack the code with Roasberry – your pathway to campaign success in the digital age.": "ROAS'ınızı maksimize edin, veri odaklı inovasyonun gücünü açığa çıkarın ve kampanyalarınızı Roasberry ile yeni zirvelere taşıyın. Başarının zor olduğu bir dünyada, Roasberry arzuları başarıya dönüştüren kodu sağlar. Roasberry ile kodu çözün – dijital çağda kampanya başarısına giden yolunuz.",
                    "Drive Growth, Amplify Impact! Roasberry's Advanced Analysis Paves the Way for Digital Triumph": "Büyümeyi Teşvik Edin, Etkiyi Artırın! Roasberry'nin Gelişmiş Analizi Dijital Zafer İçin Yolu Açar",
                    "In the fast-evolving realm of digital marketing, the pursuit of growth and impact is an ongoing challenge for brands striving to stand out in the digital landscape. Roasberry, a cutting-edge digital marketing optimization platform, has emerged as a catalyst for success, offering advanced analysis tools that pave the way for unparalleled digital triumph. This article explores how Roasberry's sophisticated Advanced Analysis empowers brands to drive growth and amplify their impact in the digital sphere.": "Hızla gelişen dijital pazarlama alanında, büyüme ve etki arayışı, dijital ortamda öne çıkmaya çalışan markalar için sürekli bir meydan okumadır. İleri teknoloji bir dijital pazarlama optimizasyon platformu olan Roasberry, başarı için bir katalizör olarak ortaya çıkmış ve benzersiz dijital zafer için yolu açan gelişmiş analiz araçları sunmaktadır. Bu makale, Roasberry'nin sofistike Gelişmiş Analizinin markaları büyümeyi teşvik etme ve dijital alanda etkilerini artırma konusunda nasıl güçlendirdiğini inceliyor.",
                    "Unleashing the Power of Advanced Analysis:": "Gelişmiş Analizin Gücünü Açığa Çıkarmak:",
                    "Roasberry's Advanced Analysis is a beacon for brands navigating the complexities of digital marketing. Tailored for the contemporary marketer, this feature goes beyond traditional analytics, offering an in-depth examination of campaigns, consumer behavior, and market trends. Let's delve into the key elements that make Roasberry's Advanced Analysis a game-changer:": "Roasberry'nin Gelişmiş Analizi, dijital pazarlamanın karmaşıklıklarında gezinmekte olan markalar için bir işaret ışığıdır. Modern pazarlamacılar için tasarlanmış bu özellik, geleneksel analizlerin ötesine geçerek kampanyaların, tüketici davranışlarının ve pazar trendlerinin derinlemesine incelenmesini sağlar. Roasberry'nin Gelişmiş Analizini oyun değiştirici yapan temel unsurlara bir göz atalım:",
                    "Comprehensive Campaign Evaluation:": "Kapsamlı Kampanya Değerlendirmesi:",
                    "Roasberry's Advanced Analysis doesn't just scratch the surface; it delves deep into the intricacies of your campaigns. By evaluating performance metrics, audience engagement, and conversion data, brands gain a comprehensive understanding of what works and what needs refinement, providing the groundwork for strategic decision-making.": "Roasberry'nin Gelişmiş Analizi, yüzeyde kalmaz; kampanyalarınızın ayrıntılarına derinlemesine iner. Performans metriklerini, kitle etkileşimini ve dönüşüm verilerini değerlendirerek markalar, neyin işe yaradığını ve neyin rafine edilmesi gerektiğini kapsamlı bir şekilde anlar, stratejik karar alma için temel sağlar.",
                    "Dynamic Consumer Behavior Insights:": "Dinamik Tüketici Davranışı İçgörüleri:",
                    "Understanding consumer behavior is crucial in the digital age. Roasberry's Advanced Analysis dissects consumer interactions across various touchpoints, unveiling insights that enable brands to tailor their messaging, offerings, and user experiences for maximum resonance and impact.": "Dijital çağda tüketici davranışını anlamak hayati öneme sahiptir. Roasberry'nin Gelişmiş Analizi, çeşitli temas noktalarında tüketici etkileşimlerini inceleyerek, markaların mesajlarını, tekliflerini ve kullanıcı deneyimlerini maksimum yankı ve etki için özelleştirmelerini sağlayan verileri ortaya çıkarır.",
                    "Market Trend Anticipation:": "Pazar Trendlerini Tahmin Etme:",
                    "Staying ahead of market trends is the key to staying relevant. Roasberry's Advanced Analysis utilizes predictive analytics to anticipate shifts in the market, enabling brands to proactively adapt their strategies and capitalize on emerging opportunities, ensuring sustained growth in a rapidly changing landscape.": "Pazar trendlerinin önünde kalmak, güncel kalmanın anahtarıdır. Roasberry'nin Gelişmiş Analizi, pazardaki değişimleri öngörmek için tahmine dayalı analizleri kullanır, markaların stratejilerini proaktif olarak uyarlamalarını ve ortaya çıkan fırsatlardan yararlanmalarını sağlayarak, hızla değişen bir ortamda sürdürülebilir büyümeyi garanti eder.",
                    "Multi-Channel Optimization:": "Çok Kanallı Optimizasyon:",
                    "The digital ecosystem is diverse, and Roasberry's Advanced Analysis recognizes the importance of a multi-channel approach. By analyzing the performance of campaigns across various channels, brands can optimize their presence where it matters most, ensuring a holistic and effective digital marketing strategy.": "Dijital ekosistem çeşitlidir ve Roasberry'nin Gelişmiş Analizi çok kanallı bir yaklaşımın önemini kabul eder. Çeşitli kanallarda kampanyaların performansını analiz ederek, markalar varlıklarını en önemli olduğu yerde optimize edebilir, kapsamlı ve etkili bir dijital pazarlama stratejisi sağlayabilir.",
                    "ROI Maximization Through Data-Driven Decisions:": "Veri Odaklı Kararlarla Yatırım Getirisini Maksimize Etme:",
                    "Roasberry's Advanced Analysis empowers brands to make data-driven decisions that go beyond intuition. By identifying high-performing channels, refining targeting parameters, and optimizing ad creatives, brands can maximize their Return on Investment (ROI) and drive growth in a cost-efficient manner.": "Roasberry'nin Gelişmiş Analizi, markaların sezginin ötesine geçen veri odaklı kararlar almalarını sağlar. Yüksek performans gösteren kanalları belirleyerek, hedefleme parametrelerini rafine ederek ve reklam yaratıcılarını optimize ederek, markalar Yatırım Getirilerini (ROI) maksimize edebilir ve maliyet etkin bir şekilde büyümeyi teşvik edebilir.",
                    "In the era of digital dominance, the ability to drive growth and amplify impact is a testament to a brand's adaptability and strategic acumen. Roasberry's Advanced Analysis serves as a beacon, providing brands with the tools they need to navigate the complexities of the digital landscape successfully. By offering comprehensive campaign evaluation, dynamic consumer behavior insights, market trend anticipation, multi-channel optimization, and ROI maximization, Roasberry propels brands towards digital triumph. Embrace the power of advanced analysis with Roasberry and unlock new dimensions of growth and impact in the digital realm!": "Dijital hakimiyet çağında, büyümeyi teşvik etme ve etkiyi artırma yeteneği, bir markanın uyum sağlama yeteneğinin ve stratejik becerisinin bir kanıtıdır. Roasberry'nin Gelişmiş Analizi, markaların dijital ortamın karmaşıklıklarını başarıyla yönetmeleri için ihtiyaç duydukları araçları sağlayan bir işaret ışığı olarak hizmet eder. Kapsamlı kampanya değerlendirmesi, dinamik tüketici davranışı verileri, pazar trendlerini tahmin etme, çok kanallı optimizasyon ve yatırım getirisini maksimize etme sunarak, Roasberry markaları dijital zafer yolunda ileriye taşır. Roasberry ile gelişmiş analizin gücünü kucaklayın ve dijital alanda büyüme ve etki için yeni boyutları keşfedin!",
                    "The Future of E-Commerce: Trends and Innovations": "The Future of E-Commerce: Trends and Innovations",

                    //BLOG31
                    "The Future of E Commerce: Trends and Innovations": "E Ticaretin Geleceği: Trendler ve Yenilikler",
                    "E Commerce is evolving rapidly, shaped by new technologies and shifting consumer behaviors. As we look to the future, several key trends and innovations are set to transform online shopping. Let's dive into these exciting developments that promise to make e commerce more engaging for both businesses and consumers.": "E Ticaret, yeni teknolojiler ve değişen tüketici davranışlarıyla hızla gelişiyor. Geleceğe baktığımızda, çevrimiçi alışverişi dönüştürecek birkaç önemli trend ve yenilik var. Gelin, hem işletmeler hem de tüketiciler için e ticareti daha ilgi çekici hale getirecek bu heyecan verici gelişmeleri inceleyelim.",
                    "What is E Commerce?": "E Ticaret Nedir?",
                    "E Commerce, short for electronic commerce, is all about buying and selling goods and services online. It covers a vast array of online business activities, from shopping for clothes and electronics to banking, investing, and even renting apartments. E Commerce has completely changed the way we do business and shop. It offers incredible convenience, a wider variety of choices, and often better prices. Instead of driving to a store, we can now shop from the comfort of our homes, compare prices with a few clicks, and have items delivered right to our doorsteps. It's a modern way of doing business that fits perfectly into our busy, digital lives.": "E Ticaret, elektronik ticaretin kısaltması olup, çevrimiçi olarak mal ve hizmet alım satımını kapsar. Giysi ve elektronik alışverişinden bankacılık, yatırım ve hatta daire kiralamaya kadar geniş bir çevrimiçi iş faaliyetlerini içerir. E Ticaret, iş yapma ve alışveriş şeklimizi tamamen değiştirdi. İnanılmaz bir rahatlık, daha geniş bir seçenek yelpazesi ve genellikle daha iyi fiyatlar sunar. Mağazaya gitmek yerine, artık evimizin konforunda alışveriş yapabilir, birkaç tıklama ile fiyatları karşılaştırabilir ve ürünleri kapımıza kadar teslim alabiliriz. Yoğun, dijital yaşam tarzımıza mükemmel uyum sağlayan modern bir iş yapma şeklidir.",
                    "Personalization and Customer Experience": "Kişiselleştirme ve Müşteri Deneyimi",
                    "One of the biggest trends in E Commerce is personalization. Today's shoppers expect tailored experiences that cater to their unique preferences and needs. With advances in artificial intelligence (AI) and machine learning, e-commerce platforms can now offer highly personalized product recommendations, dynamic pricing, and customized marketing messages. By analyzing data like browsing history and purchase behavior, businesses can create unique experiences for each customer, boosting satisfaction and loyalty.": "E Ticaretteki en büyük trendlerden biri kişiselleştirmedir. Bugünün alışverişçileri, benzersiz tercih ve ihtiyaçlarına hitap eden kişiselleştirilmiş deneyimler bekliyor. Yapay zeka (AI) ve makine öğrenimindeki gelişmeler sayesinde e-ticaret platformları artık yüksek derecede kişiselleştirilmiş ürün önerileri, dinamik fiyatlandırma ve özelleştirilmiş pazarlama mesajları sunabiliyor. Tarama geçmişi ve satın alma davranışı gibi verileri analiz ederek, işletmeler her müşteri için benzersiz deneyimler oluşturabilir, memnuniyeti ve sadakati artırabilir.",
                    "Augmented Reality (AR) and Virtual Reality (VR)": "Artırılmış Gerçeklik (AR) ve Sanal Gerçeklik (VR)",
                    "AR and VR technologies are revolutionizing online shopping. Imagine being able to see how a piece of furniture looks in your living room before buying it. AR makes this possible. VR, on the other hand, can offer virtual store tours, allowing you to browse products as if you were in a physical store. These technologies make online shopping more interactive and engaging, helping customers make more informed decisions.": "AR ve VR teknolojileri çevrimiçi alışverişi devrim niteliğinde değiştiriyor. Bir mobilya parçasının oturma odanızda nasıl görüneceğini satın almadan önce görebildiğinizi hayal edin. AR bunu mümkün kılıyor. VR ise sanal mağaza turları sunarak, fiziksel bir mağazada gibi ürünlere göz atmanızı sağlıyor. Bu teknolojiler, çevrimiçi alışverişi daha interaktif ve ilgi çekici hale getirerek müşterilerin daha bilinçli kararlar vermesine yardımcı oluyor.",
                    "Voice Commerce": "Sesli Ticaret",
                    "The rise of smart speakers and voice assistants like Amazon Alexa and Google Assistant has paved the way for voice commerce. Consumers can now search for products, place orders, and track deliveries using voice commands. This hands-free shopping experience is not only convenient but also efficient, making it an appealing option for busy consumers. As voice recognition technology continues to improve, voice commerce is expected to play a larger role in the E Commerce ecosystem.": "Akıllı hoparlörler ve sesli asistanlar (Amazon Alexa ve Google Assistant gibi) sesli ticaretin yolunu açtı. Tüketiciler artık sesli komutlarla ürün arayabilir, sipariş verebilir ve teslimatları takip edebilir. Bu eller serbest alışveriş deneyimi sadece kullanışlı değil, aynı zamanda verimli olup, meşgul tüketiciler için çekici bir seçenek haline geliyor. Ses tanıma teknolojisi geliştikçe, sesli ticaretin e-ticaret ekosisteminde daha büyük bir rol oynaması bekleniyor.",
                    "Sustainable Shopping": "Sürdürülebilir Alışveriş",
                    "Sustainability is becoming a top priority for many consumers. E Commerce companies are responding by adopting eco-friendly practices and offering sustainable products. This includes using recyclable packaging, reducing carbon footprints, and promoting products with ethical sourcing. Consumers are increasingly choosing brands that align with their values, and sustainability is becoming a key differentiator in the competitive E Commerce market.": "Sürdürülebilirlik, birçok tüketici için en öncelikli konu haline geliyor. E Ticaret şirketleri, çevre dostu uygulamaları benimseyerek ve sürdürülebilir ürünler sunarak yanıt veriyor. Bu, geri dönüştürülebilir ambalajların kullanılması, karbon ayak izlerinin azaltılması ve etik kaynaklı ürünlerin teşvik edilmesi gibi uygulamaları içeriyor. Tüketiciler giderek daha fazla kendi değerleriyle uyumlu markaları tercih ediyor ve sürdürülebilirlik, rekabetçi e-ticaret pazarında önemli bir farklılaştırıcı haline geliyor.",
                    "Mobile Commerce": "Mobil Ticaret",
                    "Mobile commerce, or m-commerce, is on the rise as smartphones become the primary device for online shopping. Businesses are optimizing their websites and apps for mobile users, ensuring a seamless shopping experience on smaller screens. Features like one-click purchasing, mobile wallets, and app-exclusive discounts are driving the growth of m-commerce. As mobile technology continues to advance, we can expect even more innovation in this space, making shopping on the go easier and more convenient.": "Mobil ticaret veya m-ticaret, akıllı telefonların çevrimiçi alışveriş için birincil cihaz haline gelmesiyle yükselişte. İşletmeler, mobil kullanıcılar için web sitelerini ve uygulamalarını optimize ederek daha küçük ekranlarda sorunsuz bir alışveriş deneyimi sağlıyor. Tek tıklama ile satın alma, mobil cüzdanlar ve uygulamaya özel indirimler gibi özellikler, m-ticareti büyütüyor. Mobil teknoloji ilerledikçe, bu alanda daha fazla yenilik bekleyebiliriz, bu da hareket halindeyken alışveriş yapmayı daha kolay ve kullanışlı hale getirecek.",
                    "Artificial Intelligence and Chatbots": "Yapay Zeka ve Chatbotlar",
                    "AI-powered chatbots are transforming customer service in E Commerce. These intelligent bots can handle a wide range of tasks, from answering customer queries to processing orders and providing personalized recommendations. Chatbots offer 24/7 support, quick response times, and consistent service, enhancing the overall customer experience. As AI technology becomes more sophisticated, chatbots will become even more integral to e-commerce operations, providing human-like interactions that can improve customer satisfaction and operational efficiency.": "AI destekli chatbotlar, e ticarette müşteri hizmetlerini dönüştürüyor. Bu akıllı botlar, müşteri sorgularını yanıtlamaktan siparişleri işlemeye ve kişiselleştirilmiş öneriler sunmaya kadar geniş bir yelpazede görevleri yerine getirebiliyor. Chatbotlar, 7/24 destek, hızlı yanıt süreleri ve tutarlı hizmet sunarak genel müşteri deneyimini artırıyor. AI teknolojisi daha sofistike hale geldikçe, chatbotlar e-ticaret operasyonlarında daha da önemli hale gelecek ve müşteri memnuniyetini ve operasyonel verimliliği artıracak insan benzeri etkileşimler sağlayacak.",
                    "Social Commerce": "Sosyal Ticaret",
                    "Social media platforms are becoming powerful E Commerce channels. Features like shoppable posts, in-app checkout, and influencer partnerships are driving sales directly from social media. Platforms like Instagram, Facebook, and TikTok are integrating shopping functionalities, allowing users to discover and purchase products without leaving the app. Social commerce combines the reach and engagement of social media with the convenience of online shopping, creating new opportunities for businesses to connect with consumers in a more meaningful way.": "Sosyal medya platformları güçlü e ticaret kanalları haline geliyor. Satın alınabilir gönderiler, uygulama içi ödeme ve influencer ortaklıkları gibi özellikler, sosyal medyadan doğrudan satışları artırıyor. Instagram, Facebook ve TikTok gibi platformlar alışveriş işlevlerini entegre ederek kullanıcıların uygulamadan çıkmadan ürünleri keşfetmelerini ve satın almalarını sağlıyor. Sosyal ticaret, sosyal medyanın erişimini ve etkileşimini çevrimiçi alışverişin rahatlığı ile birleştirerek, işletmelerin tüketicilerle daha anlamlı bir şekilde bağlantı kurmaları için yeni fırsatlar yaratıyor.",
                    "Subscription Services": "Abonelik Hizmetleri",
                    "Subscription services are gaining popularity as they offer convenience and value to consumers. From monthly beauty boxes to meal kits and streaming services, subscriptions provide a steady stream of products or content tailored to the customer’s preferences. This model fosters customer loyalty and provides businesses with predictable revenue streams. As more industries explore subscription options, we can expect this trend to continue growing, offering consumers new and exciting ways to access the products and services they love.": "Abonelik hizmetleri, tüketicilere rahatlık ve değer sundukları için popülerlik kazanıyor. Aylık güzellik kutularından yemek kitlerine ve akış hizmetlerine kadar, abonelikler müşterinin tercihlerine göre uyarlanmış ürün veya içerik akışı sağlar. Bu model müşteri sadakatini artırır ve işletmelere öngörülebilir gelir akışları sağlar. Daha fazla endüstri abonelik seçeneklerini keşfettikçe, bu trendin büyümeye devam etmesini ve tüketicilere sevdikleri ürün ve hizmetlere erişmenin yeni ve heyecan verici yollarını sunmasını bekleyebiliriz.",
                    "Conclusion": "Sonuç",
                    "The future of E Commerce is bright, with innovative technologies and evolving consumer preferences driving continuous change. Businesses that stay ahead of these trends and embrace new innovations will be well-positioned to thrive in the competitive e-commerce landscape. Personalization, sustainability, mobile optimization, and the integration of AR, VR, and AI are just a few of the exciting developments shaping the future of online shopping. As the e-commerce industry continues to evolve, one thing is certain: the best is yet to come.": "E Ticaretin geleceği parlak, yenilikçi teknolojiler ve değişen tüketici tercihlerinin sürekli değişim sağladığı bir alan. Bu trendlerin önünde kalan ve yeni yenilikleri benimseyen işletmeler, rekabetçi e ticaret ortamında başarılı olmak için iyi bir konumda olacaklar. Kişiselleştirme, sürdürülebilirlik, mobil optimizasyon ve AR, VR ve AI entegrasyonu, çevrimiçi alışverişin geleceğini şekillendiren heyecan verici gelişmelerden sadece birkaçı. E-ticaret endüstrisi evrilmeye devam ettikçe, bir şey kesin: en iyisi henüz gelmedi.",
                    "Full Package": " Tam Paket",
                    "Annual Revenue": "Yıllık Gelir",
                    "Revenue-Based Pricing, Full Access.": "Gelire Dayalı Fiyatlandırma, Tam Erişim.",
                    "We appreciate your interest in our services. Before getting started, please note that we offer four different packages, all with the same features.": "Hizmetlerimize gösterdiğiniz ilgi için teşekkür ederiz. Başlamadan önce, tüm aynı özelliklere sahip dört farklı paket sunduğumuzu lütfen unutmayın",
                    "The pricing is based on your estimated total revenue over the last 12 months in USD.": "Fiyatlandırma, son 12 ay içindeki tahmini toplam gelirinize dayanmaktadır.",
                    "To proceed, select a package that best fits your revenue. If you have any questions, our team is ready to help.": "Devam etmek için, gelirinize en uygun paketi seçin. Herhangi bir sorunuz varsa, ekibimiz size yardımcı olmaya hazır.",
                    "Pay Annually and Get 2 Months Free!": "Yıllık Ödeyin ve 2 Ay Ücretsiz Kullanın!",
                    "Annually": "Yıllık",
                    "Monthly": "Aylık",
                    "Annual Revenue": "Yıllık Gelir",
                    "0-500K USD": "0-500K USD",
                    "$149/month": "$149/ay",
                    "Start 14-Day Free Trial": "14 Günlük Ücretsiz Deneme",
                    "500K-1 M USD": "500K-1 M USD",
                    "$199/month": "$199/ay",
                    "1-5M USD": "1-5M USD",
                    "$299/month": "$299/ay",
                    "5M+ USD": "5M+ USD",
                    "$499/month": "$499/ay",
                    "Plan includes:": "Plan İçeriği:",
                    "All-in-One Dashboard": "Hepsi Bir Arada Kontrol Paneli",
                    "Pixel Tracking": "Piksel Takibi",
                    "Advanced Marketing Analytics": "Gelişmiş Pazarlama Analitiği",
                    "Automated Reporting": "Otomatik Raporlama",
                    "Customer Journeys": "Müşteri Yolculukları",
                    "Visitor Insights/Logs": "Ziyaretçi İçgörüleri/Kayıtları",
                    "Unlimited Devices": "Sınırsız Cihaz",
                    "Customer Support": "Müşteri Desteği",

                    //Visitor Insights-Log i18n
                    "Visitor Insights": "Ziyaretçi İçgörüleri",

                    "Track Your LOG1": "Ziyaretçi Akışınızı ",
                    "Visitors Flow Easily.": "Kolayca İzleyin.",

                    "Customer Flow": "Müşteri Akışı",
                    "Gain insights into customer navigation and optimize their journey for a better user experience and increased conversions.": "Müşteri gezinmesi hakkında içgörüler edinin ve daha iyi bir kullanıcı deneyimi ve artırılmış dönüşümler için yolculuklarını optimize edin.",
                    "Visitor Channels": "Ziyaretçi Kanalları",
                    "Identify top traffic sources, analyze their performance metrics, and refine marketing strategies to boost engagement.": "En iyi trafik kaynaklarını belirleyin, performans metriklerini analiz edin ve etkileşimi artırmak için pazarlama stratejilerini geliştirin.",
                    "Analyze Detailed User Behavior Patterns": "Detaylı Kullanıcı Davranış Modellerini Analiz Edin",
                    "Gain a deep understanding of your website visitors' actions and preferences. Identify which pages are most engaging and where users drop off.": "Web sitesi ziyaretçilerinizin eylemleri ve tercihleri hakkında derinlemesine bilgi edinin. En çok hangi sayfaların ilgi çekici olduğunu ve kullanıcıların nerede ayrıldığını belirleyin.",
                    "Detailed Analytics": "Detaylı Analitik",
                    "Use detailed analytics to uncover patterns in user behavior. Make data-driven decisions to enhance user experience and increase conversion rates.": "Kullanıcı davranışlarındaki kalıpları ortaya çıkarmak için detaylı analitik kullanın. Kullanıcı deneyimini geliştirmek ve dönüşüm oranlarını artırmak için veriye dayalı kararlar alın.",
                    "Track Interactions": "Etkileşimleri İzleyin",
                    "Track user interactions across different pages and devices. Optimize your website layout and content based on insights from user behavior analysis.": "Kullanıcı etkileşimlerini farklı sayfalar ve cihazlar arasında izleyin. Kullanıcı davranış analizi içgörülerine dayalı olarak web sitenizin düzenini ve içeriğini optimize edin.",
                    "Visitor Log": "Ziyaretçi Kaydı",

                    "Track Your LOG2": "Müşteri Ziyaretlerinizi",
                    "Customer Visits and Actions.": "ve Eylemlerinizi İzleyin.",

                    "Visitor Profile": "Ziyaretçi Profili",
                    "See detailed visitor information such as their visit summary, device and location details, and their interaction history on your site.": "Ziyaretlerinin özeti, cihaz ve konum bilgileri ve sitenizdeki etkileşim geçmişi gibi detaylı ziyaretçi bilgilerini görün.",
                    "Visits in Real Time": "Gerçek Zamanlı Ziyaretler",
                    "Get up-to-the-minute data on your website visitors. See how many people are visiting your site in the last 24 hours and last 30 minutes.": "Web sitenizin ziyaretçileri hakkında dakika dakika veri alın. Son 24 saat ve son 30 dakika içinde kaç kişinin sitenizi ziyaret ettiğini görün.",
                    "Improve Your Website with User Analytics": "Kullanıcı Analitiği ile Web Sitenizi Geliştirin",
                    "Enhance your website by understanding your users. Use detailed analytics to improve user experience and increase conversions.": "Kullanıcılarınızı anlayarak web sitenizi geliştirin. Kullanıcı deneyimini iyileştirmek ve dönüşümleri artırmak için detaylı analitik kullanın.",
                    "Behavior Analysis": "Davranış Analizi",
                    "Analyze visitor behavior to identify popular pages, user journeys, and potential drop-off points.": "Popüler sayfaları, kullanıcı yolculuklarını ve potansiyel terk noktalarını belirlemek için ziyaretçi davranışlarını analiz edin.",
                    "Actionable Insights": "Eyleme Dönük İçgörüler",
                    "Leverage actionable insights from visitor data to refine your marketing campaigns and boost engagement.": "Ziyaretçi verilerinden elde edilen eyleme dönük içgörülerden yararlanarak pazarlama kampanyalarınızı geliştirin ve etkileşimi artırın.",


                    //Dashboard and Insight Tools i18n
                    "Dashboard and Insight Tools": "Kontrol Paneli ve İçgörü Araçları",

                    "The Only Platform for All Your": "Tüm E-ticaret ve Pazarlama Analitiği",
                    "E-commerce & Marketing Analytics": "Tüm E-ticaret ve Pazarlama Analitiği için Tek Platform",

                    "Campaigns Hub": "Kampanya Merkezi",
                    "Monitor and compare campaigns across platforms.": "Kampanyaları platformlar arasında izleyin ve karşılaştırın.",
                    "Essential Graphs": "Önemli Grafikler",
                    "Track your sales volume and advertising spent.": "Satış hacminizi ve reklam harcamalarınızı izleyin.",
                    "Notable Changes": "Dikkate Değer Değişiklikler",
                    "Easily see changes in the graphs.": "Grafiklerdeki değişiklikleri kolayca görün.",
                    "Create a Quick, Personalized Dashboard in Minutes": "Dakikalar İçinde Hızlı, Kişiselleştirilmiş Bir Kontrol Paneli Oluşturun",
                    "Easily customize your dashboard to suit your unique business needs. Get started quickly and manage your data effortlessly.": "Kontrol panelinizi benzersiz iş ihtiyaçlarınıza göre kolayca özelleştirin. Hızlıca başlayın ve verilerinizi zahmetsizce yönetin.",
                    "Seamless Integration": "Sorunsuz Entegrasyon",
                    "Our platform offers seamless integration with your existing systems. Track your key metrics and gain valuable insights in real-time.": "Platformumuz mevcut sistemlerinizle sorunsuz entegrasyon sunar. Anahtar metriklerinizi izleyin ve gerçek zamanlı değerli içgörüler elde edin.",
                    "Insightful Analytics": "Ayrıntılı Analitik",
                    "Stay ahead of the competition with advanced analytics. Monitor performance, identify trends, and make data-driven decisions.": "Gelişmiş analitiklerle rekabetin önünde kalın. Performansı izleyin, trendleri belirleyin ve veriye dayalı kararlar alın.",
                    "Monitor and Compare Campaigns Across Platforms": "Kampanyaları Platformlar Arasında İzleyin ve Karşılaştırın",
                    "Easily monitor and optimize your campaigns on platforms like Google Ads, Meta Ads, and Pinterest.": "Google Ads, Meta Ads ve Pinterest gibi platformlardaki kampanyalarınızı kolayca izleyin ve optimize edin.",
                    "Comprehensive Analytics": "Kapsamlı Analitik",
                    "Track ROAS, Conversion Rates, Total Spent, CPC, AOV, and CPM for every campaign.": "Her kampanya için ROAS, Dönüşüm Oranları, Toplam Harcama, CPC, AOV ve CPM'i izleyin.",
                    "Platform Comparison": "Platform Karşılaştırması",
                    "Compare campaign performance across Google Ads, Meta Ads, and Pinterest.": "Kampanya performansını Google Ads, Meta Ads ve Pinterest arasında karşılaştırın.",
                    "Export Functionality": "Dışa Aktarma Özelliği",
                    "Export data to XLS format for in-depth analysis and reporting.": "Ayrıntılı analiz ve raporlama için verileri XLS formatına dışa aktarın.",

                    "Because Every Click": "Çünkü Her Tık ",
                    "Tells a Story.": "Bir Hikaye Anlatır.",

                    "Berry Pixel": "Berry Pixel",
                    "Integrate Berry Pixel into your online store for deep insights into visitor behavior. Capture every interaction from landing to leaving your site.": "Ziyaretçi davranışları hakkında derinlemesine içgörüler elde etmek için Berry Pixel'i çevrimiçi mağazanıza entegre edin. Her etkileşimi inişten ayrılışa kadar yakalayın.",
                    "Sales Attribution Model": "Satış Atıf Modeli",
                    "Berry Pixel tracks visitor journeys, helping you identify key sales-driving channels and refine your marketing strategies for better ROI.": "Berry Pixel, ziyaretçi yolculuklarını izler, satışları artıran ana kanalları belirlemenize ve daha iyi ROI için pazarlama stratejilerinizi geliştirmenize yardımcı olur.",
                    "Gain Deep Insights with Advanced Analytics": "Gelişmiş Analitik ile Derin İçgörüler Elde Edin",
                    "Analyze your data in depth and gain critical insights for your business. Enhance your performance with comprehensive reporting.": "Verilerinizi derinlemesine analiz edin ve işiniz için kritik içgörüler elde edin. Kapsamlı raporlamayla performansınızı artırın.",
                    "Optimize Performance": "Performansı Optimize Edin",
                    "Use data-driven insights to optimize your processes and improve efficiency. Ensure your operations are running at peak performance with our detailed analysis.": "Veriye dayalı içgörülerle süreçlerinizi optimize edin ve verimliliği artırın. Ayrıntılı analizimizle operasyonlarınızın en yüksek performansta çalışmasını sağlayın.",
                    "Increase ROI": "ROI'yi Artırın",
                    "Leverage in-depth data analysis to enhance your return on investment. Make smarter investments based on comprehensive data insights and analytics.": "Yatırım getirinizi artırmak için derinlemesine veri analizinden yararlanın. Kapsamlı veri içgörüleri ve analizlere dayalı olarak daha akıllı yatırımlar yapın.",
                    "Access from Anywhere": "Her Yerden Erişim",
                    "Unlimited Device Access": "Sınırsız Cihaz Erişimi",
                    "Unlimited access to Roasberry from any device. Manage your ad tracking effortlessly, no matter where you are.": "Herhangi bir cihazdan Roasberry'ye sınırsız erişim. Reklam takibinizi zahmetsizce yönetin, nerede olursanız olun.",
                    "Mobile App Access": "Mobil Uygulama Erişimi",
                    "Keep track of your campaigns and ad data even on the go. Always stay in control of your advertising strategy.": "Kampanyalarınızı ve reklam verilerinizi hareket halindeyken bile takip edin. Reklam stratejinizin kontrolünü her zaman elinizde tutun.",



                    "What is an All-in-One Marketing Tool?": "Hepsi Bir Arada Pazarlama Aracı Nedir?",
                    "Think of an all-in-one marketing tool as your marketing command center. It combines essential functions like campaign tracking, analytics, and customer relationship management into one seamless platform. This integration means you don’t have to juggle multiple tools—everything you need is right there at your fingertips.": "Hepsi bir arada pazarlama aracını pazarlama komuta merkeziniz olarak düşünün. Kampanya takibi, analizler ve müşteri ilişkileri yönetimi gibi temel işlevleri tek bir sorunsuz platformda birleştirir. Bu entegrasyon sayesinde birden fazla aracı yönetmek zorunda kalmazsınız—ihtiyacınız olan her şey elinizin altında olur.",
                    "Why You’ll Love Using an All-in-One Marketing Tool": "Neden Hepsi Bir Arada Pazarlama Aracı Kullanmayı Seveceksiniz",
                    "1. Keep It Simple": "1. Basit Tutun",
                    "An all-in-one marketing tool is a game changer when it comes to simplifying your operations. Instead of managing multiple platforms for different marketing tasks, you have a single, unified interface. This means less time spent switching between apps and more time focusing on crafting compelling campaigns and understanding your audience.": "Hepsi bir arada pazarlama aracı, operasyonlarınızı basitleştirme konusunda oyun değiştiricidir. Farklı pazarlama görevleri için birden fazla platformu yönetmek yerine, tek bir birleşik arayüze sahip olursunuz. Bu, uygulamalar arasında geçiş yaparken harcanan zamanı azaltır ve çekici kampanyalar oluşturup kitlenizi anlamaya daha fazla zaman ayırmanızı sağlar.",
                    "Imagine planning a new campaign. With an all-in-one tool, you can coordinate your ad campaigns and access e-commerce data from one place. This streamlined approach reduces errors and ensures that all aspects of your marketing efforts are aligned and working together harmoniously. Plus, having everything in one place means your team can collaborate more effectively, saving time and effort.": "Yeni bir kampanya planladığınızı hayal edin. Hepsi bir arada bir araçla, reklam kampanyalarınızı koordine edebilir ve e-ticaret verilerine tek bir yerden erişebilirsiniz. Bu sadeleştirilmiş yaklaşım hataları azaltır ve pazarlama çabalarınızın tüm yönlerinin uyum içinde çalışmasını sağlar. Ayrıca, her şeyin tek bir yerde olması, ekibinizin daha etkili bir şekilde iş birliği yapmasını sağlar ve zaman ile çaba tasarrufu yapar.",
                    "2. Teamwork Made Easy": "2. Takım Çalışması Kolaylaştırıldı",
                    "Effective collaboration is at the heart of successful marketing. An all-in-one marketing tool provides a shared space where your team can access the same data, insights, and tools. This transparency fosters a collaborative environment where everyone is on the same page and can contribute to the campaign's success.": "Etkili iş birliği, başarılı pazarlamanın merkezindedir. Hepsi bir arada pazarlama aracı, ekibinizin aynı verilere, içgörülere ve araçlara erişebileceği ortak bir alan sağlar. Bu şeffaflık, herkesin aynı sayfada olduğu ve kampanyanın başarısına katkıda bulunabileceği bir iş birliği ortamını teşvik eder.",
                    "Picture your team working on a multi-channel campaign. With a centralized tool, everyone can easily access campaign performance data, share insights, and make data-driven decisions. This reduces misunderstandings and promotes a more cohesive strategy. Additionally, real-time updates mean that any changes or feedback are instantly visible to everyone, enhancing responsiveness and agility.": "Ekibinizin çok kanallı bir kampanya üzerinde çalıştığını hayal edin. Merkezi bir araçla, herkes kampanya performans verilerine kolayca erişebilir, içgörüleri paylaşabilir ve veri odaklı kararlar alabilir. Bu, yanlış anlamaları azaltır ve daha uyumlu bir stratejiyi teşvik eder. Ayrıca, gerçek zamanlı güncellemeler, herhangi bir değişikliğin veya geri bildirimin anında herkes tarafından görülebileceği anlamına gelir, bu da yanıt verme ve çevikliği artırır.",
                    "3. Save Money": "3. Para Tasarrufu",
                    "Using one platform instead of several can also save you some cash. Instead of paying for multiple subscriptions, you have just one. Plus, you save on the time and resources needed to train your team on different tools.": "Birden fazla platform yerine tek bir platform kullanmak, ayrıca size para tasarrufu sağlayabilir. Birden fazla abonelik için ödeme yapmak yerine, sadece bir tane ödemeniz yeterlidir. Ayrıca, ekibinizi farklı araçlar konusunda eğitmek için gereken zaman ve kaynaklardan tasarruf edersiniz.",
                    "4. See the Big Picture": "4. Büyük Resmi Görün",
                    "An integrated marketing tool provides a holistic view of your marketing efforts. With all your data in one place, you can easily track and analyze the entire customer journey, from the first point of contact to conversion and beyond. This comprehensive view enables you to make informed, data-driven decisions that improve your marketing strategies.": "Entegre bir pazarlama aracı, pazarlama çabalarınıza bütünsel bir bakış sağlar. Tüm verilerinizin tek bir yerde olması, ilk temas noktasından dönüşüme ve ötesine kadar tüm müşteri yolculuğunu kolayca takip edip analiz etmenizi sağlar. Bu kapsamlı görüş, pazarlama stratejilerinizi iyileştiren bilgiye dayalı, veri odaklı kararlar almanızı sağlar.",
                    "Imagine being able to see how your ad campaigns impact customer engagement or how changes to your website affect conversion rates. With an all-in-one tool, you gain these insights effortlessly, allowing you to optimize your campaigns for maximum impact. You can identify trends, uncover opportunities, and address challenges more effectively, ensuring your marketing efforts are always aligned with your goals.": "Reklam kampanyalarınızın müşteri etkileşimini nasıl etkilediğini veya web sitenizdeki değişikliklerin dönüşüm oranlarını nasıl etkilediğini görebildiğinizi hayal edin. Hepsi bir arada bir araçla, bu içgörüleri zahmetsizce elde edersiniz, bu da kampanyalarınızı maksimum etki için optimize etmenizi sağlar. Eğilimleri belirleyebilir, fırsatları ortaya çıkarabilir ve zorluklarla daha etkili bir şekilde başa çıkabilirsiniz, böylece pazarlama çabalarınız her zaman hedeflerinizle uyumlu olur.",
                    "5. Make Your Customers Happy": "5. Müşterilerinizi Mutlu Edin",
                    "In today’s competitive landscape, providing an exceptional customer experience is essential. An all-in-one marketing tool helps you deliver consistent, personalized communication across all channels, ensuring your customers feel valued and understood.": "Günümüzün rekabetçi ortamında, olağanüstü bir müşteri deneyimi sunmak esastır. Hepsi bir arada pazarlama aracı, tüm kanallar boyunca tutarlı, kişiselleştirilmiş iletişim sağlamanıza yardımcı olur, böylece müşterileriniz değerli ve anlaşıldıklarını hisseder.",
                    "For example, by analyzing data from various touchpoints, you can tailor your messaging to resonate with different segments of your audience. This personalization fosters stronger connections and builds customer loyalty. Additionally, having a unified view of customer interactions allows you to quickly address any issues or concerns, enhancing customer satisfaction and retention.": "Örneğin, çeşitli temas noktalarından gelen verileri analiz ederek, mesajlarınızı kitlenizin farklı segmentleriyle uyumlu hale getirebilirsiniz. Bu kişiselleştirme, daha güçlü bağlantılar oluşturur ve müşteri sadakatini artırır. Ayrıca, müşteri etkileşimlerine birleşik bir bakış açısına sahip olmak, herhangi bir sorun veya endişeyi hızla ele almanızı sağlar, bu da müşteri memnuniyetini ve sadakatini artırır.",
                    "Popular All-in-One Marketing Tools": "Popüler Hepsi Bir Arada Pazarlama Araçları",
                    "There are several well-known all-in-one marketing tools available that offer a range of features to help businesses manage their marketing efforts effectively. Here are a few popular options:": "İşletmelerin pazarlama çabalarını etkili bir şekilde yönetmelerine yardımcı olacak çeşitli özellikler sunan birçok tanınmış hepsi bir arada pazarlama aracı mevcuttur. İşte birkaç popüler seçenek:",
                    "Triple Whale: Known for its comprehensive analytics and insights tailored for e-commerce businesses, Triple Whale helps marketers make informed decisions by providing a clear view of performance metrics.": "Triple Whale: E-ticaret işletmeleri için kapsamlı analizleri ve içgörüleriyle tanınan Triple Whale, performans metriklerine net bir bakış sağlayarak pazarlamacıların bilinçli kararlar almasına yardımcı olur.",
                    "TrueRoas: Focused on tracking return on ad spend (ROAS) across various platforms, TrueRoas is designed to give marketers a detailed understanding of their ad campaign effectiveness, helping optimize investment.": "TrueRoas: Farklı platformlarda reklam harcamalarının getirisini (ROAS) takip etmeye odaklanan TrueRoas, pazarlamacılara reklam kampanyalarının etkinliğini ayrıntılı bir şekilde anlamalarını sağlamak ve yatırımı optimize etmek için tasarlanmıştır.",
                    "Roasberry: Our very own all-in-one marketing tool at Roasberry is designed to provide comprehensive insights and management capabilities for your marketing campaigns. With a focus on user behavior, ad performance, and strategy optimization, Roasberry offers a powerful solution to take your marketing efforts to the next level.": "Roasberry: Kendi hepsi bir arada pazarlama aracımız olan Roasberry, pazarlama kampanyalarınız için kapsamlı içgörüler ve yönetim yetenekleri sağlamak üzere tasarlanmıştır. Kullanıcı davranışı, reklam performansı ve strateji optimizasyonuna odaklanarak, Roasberry, pazarlama çabalarınızı bir üst seviyeye çıkarmak için güçlü bir çözüm sunar.",
                    "Why Roasberry is the Perfect Choice": "Neden Roasberry Mükemmel Bir Seçimdir",
                    "At Roasberry, we understand the challenges marketers face in today’s fast-paced environment. Our all-in-one marketing tool is designed to simplify your efforts and amplify your success. Here’s why Roasberry stands out:": "Roasberry olarak, pazarlamacıların günümüzün hızlı ortamında karşılaştıkları zorlukları anlıyoruz. Hepsi bir arada pazarlama aracımız, çabalarınızı basitleştirmek ve başarınızı artırmak için tasarlanmıştır. İşte Roasberry'nin öne çıkma nedenleri:",
                    "● Understand Your Audience: Roasberry provides deep insights into user behavior, helping you connect with your audience on a more personal level. Our analytics tools allow you to segment your audience, tailor your messaging, and deliver content that resonates.": "● Kitlenizi Anlayın: Roasberry, kullanıcı davranışları hakkında derinlemesine içgörüler sağlar ve kitlenizle daha kişisel bir düzeyde bağlantı kurmanıza yardımcı olur. Analiz araçlarımız, kitlenizi segmentlere ayırmanıza, mesajlarınızı özelleştirmenize ve yankı uyandıran içerik sunmanıza olanak tanır.",
                    "● Boost Ad Performance: With Roasberry, you can easily track your ad campaigns and gain insights into what’s working and what needs improvement. Our platform provides detailed metrics and reports, allowing you to optimize your campaigns for better results.": "● Reklam Performansını Artırın: Roasberry ile reklam kampanyalarınızı kolayca takip edebilir ve neyin işe yaradığını, neyin iyileştirilmesi gerektiğini anlayabilirsiniz. Platformumuz, kampanyalarınızı daha iyi sonuçlar için optimize etmenizi sağlayan ayrıntılı metrikler ve raporlar sunar.",
                    "● Access E-commerce Data: Roasberry integrates seamlessly with your e-commerce platform, allowing you to access and analyze critical data. This helps you understand sales patterns, track inventory, and improve overall business performance.": "● E-ticaret Verilerine Erişin: Roasberry, e-ticaret platformunuzla sorunsuz bir şekilde entegre olur ve kritik verilere erişip analiz etmenizi sağlar. Bu, satış kalıplarını anlamanıza, envanteri takip etmenize ve genel iş performansınızı artırmanıza yardımcı olur.",
                    "● Optimize with Confidence: Our powerful analytics tools give you the confidence to refine your marketing strategies and achieve real results. By leveraging Roasberry’s insights, you can make data-driven decisions that drive growth and success.": "● Güvenle Optimize Edin: Güçlü analiz araçlarımız, pazarlama stratejilerinizi geliştirme ve gerçek sonuçlar elde etme konusunda size güven verir. Roasberry'nin içgörülerini kullanarak, büyümeyi ve başarıyı yönlendiren veri odaklı kararlar alabilirsiniz.",
                    "Choosing Roasberry means partnering with a platform that cares about your success. We’re committed to providing you with the tools and insights you need to excel in your marketing efforts. Visit roasberry.com to learn more and see how our platform can transform your marketing strategy.": "Roasberry'yi seçmek, başarınıza önem veren bir platformla ortaklık kurmak anlamına gelir. Pazarlama çabalarınızda mükemmel olmanız için size gerekli araçları ve içgörüleri sağlamaya kararlıyız. Daha fazla bilgi edinmek ve platformumuzun pazarlama stratejinizi nasıl dönüştürebileceğini görmek için roasberry.com'u ziyaret edin.",
                    "Conclusion": "Sonuç",
                    "Using an all-in-one marketing tool is like having a trusty sidekick in your business journey. It simplifies your workflow, enhances team collaboration, and helps you make data-driven decisions. With everything in one place, you can focus on what really matters—building genuine connections with your audience and delivering outstanding experiences. So why not give it a try and see how an all-in-one tool can transform your business success?": "Hepsi bir arada bir pazarlama aracı kullanmak, iş yolculuğunuzda güvenilir bir yardımcıya sahip olmak gibidir. İş akışınızı basitleştirir, ekip iş birliğini artırır ve veri odaklı kararlar almanıza yardımcı olur. Her şeyin tek bir yerde olmasıyla, gerçekten önemli olana odaklanabilirsiniz—kitlenizle gerçek bağlantılar kurmak ve olağanüstü deneyimler sunmak. Öyleyse neden bir denemeyle, hepsi bir arada bir aracın iş başarınızı nasıl dönüştürebileceğini görmüyorsunuz?",
                    "NewsWriter": "Berry",
                    "NewsPublishedDate": "06.08.2024",
                    "compareUs": "Karşılaştırın",

                    //Roasberry vs Kendall i18n 
                    "Roasberry vs Kendall": "Roasberry vs Kendall",
                    "Kendall is an affordable tool that provides great insights regarding your purchases. We believe in providing a more holistic view of your analytics including your website traffic and your users' checkout behavior at a similar cost.": "Kendall, satın alımlarınızla ilgili harika içgörüler sağlayan uygun fiyatlı bir araçtır. Web sitesi trafiğiniz ve kullanıcılarınızın ödeme davranışları dahil olmak üzere analitiğinize daha bütünsel bir bakış sunmayı benzer bir maliyetle sağlamayı hedefliyoruz.",
                    "Our solution sets itself apart by providing a state-of-the-art RFM segmentation and many more detailed attribution models which are essential for brands making over half-a-million US Dollars annually.": "Çözümümüz, en son teknoloji RFM segmentasyonu ve yılda yarım milyon ABD dolarından fazla gelir elde eden markalar için gerekli olan birçok ayrıntılı atıf modeli sağlayarak kendini ayırıyor.",
                    "Book a Demo": "Demo Rezervasyonu Yap",
                    "Wouldn't you like to make your ad tracking more effective with Roasberry?": "Reklam takibinizi Roasberry ile daha etkili hale getirmek istemez misiniz?",
                    "Features": "Özellikler",
                    "Pixel Tracking": "Pixel Takibi",
                    "Detailed Shopify Analytics": "Detaylı Shopify Analitiği",
                    "Google Ads & Meta Ads Integration": "Google Ads & Meta Ads Entegrasyonu",
                    "RFM Analysis and Customer Segments": "RFM Analizi ve Müşteri Segmentleri",
                    "First Click, Last Click, Linear Attribution": "İlk Tıklama, Son Tıklama, Doğrusal Atıf",
                    "Visitor History for Converted Visits": "Dönüştürülmüş Ziyaretler için Ziyaretçi Geçmişi",
                    "Visitor History for All Visits": "Tüm Ziyaretler için Ziyaretçi Geçmişi",
                    "Time-Decay Attribution": "Zaman Azalması Atıfı",
                    "Position-Based Attribution": "Konum Bazlı Atıf",
                    "Merchant Friendly Cost": "Satıcı Dostu Maliyet",
                    "Yes": "Evet",
                    "No": "Hayır",

                    //Roasberry vs Upstackified i18n
                    "Roasberry vs Upstackified": "Roasberry vs Upstackified",
                    "First things first, there is no order limit on Roasberry. We charge based on revenue, as it affects the cost, but not on order quantity. As our costs depend on ad spend and data we track, we aim to charge a very small fee to provide insights on data.": "İlk olarak, Roasberry'de sipariş limiti yoktur. Maliyeti etkilediği için gelire dayalı ücret alıyoruz, ancak sipariş miktarına dayalı değil. Maliyetlerimiz reklam harcamasına ve izlediğimiz verilere bağlı olduğundan, verilerle ilgili içgörüler sağlamak için çok küçük bir ücret talep etmeyi hedefliyoruz.",
                    "Roasberry provides you insights on purchases, such as abandoned items, most viewed pages, on which pages users land and leave, how their flow looks so that you won’t need GA4 data, or you will not have to rely on Meta Pixel while Upstackified will push data back to Meta and Google.": "Roasberry, terk edilen ürünler, en çok görüntülenen sayfalar, kullanıcıların hangi sayfalara indiği ve hangi sayfalardan ayrıldığı, akışlarının nasıl göründüğü gibi satın alımlarla ilgili içgörüler sağlar, böylece GA4 verilerine ihtiyacınız kalmaz veya Meta Pixel'e güvenmenize gerek kalmazken Upstackified verileri Meta ve Google'a geri iletecektir.",
                    "Roasberry has a built-in RFM analysis, which you can upload to platforms based on campaign goals. Plus, you can see how every visitor moves through your website to get insights on conversions, and how customers react on your website.": "Roasberry, kampanya hedeflerine göre platformlara yükleyebileceğiniz yerleşik bir RFM analizine sahiptir. Ayrıca, her ziyaretçinin web sitenizde nasıl hareket ettiğini ve dönüşümler hakkında içgörüler elde etmek ve müşterilerin web sitenizde nasıl tepki verdiğini görebilirsiniz.",
                    "Book a Demo": "Demo Rezervasyonu Yap",
                    "Wouldn't you like to make your ad tracking more effective with Roasberry?": "Reklam takibinizi Roasberry ile daha etkili hale getirmek istemez misiniz?",
                    "Features": "Özellikler",
                    "Pixel Tracking": "Pixel Takibi",
                    "Detailed Shopify Analytics": "Detaylı Shopify Analitiği",
                    "Google Ads & Meta Ads Integration": "Google Ads & Meta Ads Entegrasyonu",
                    "RFM Analysis and Customer Segments": "RFM Analizi ve Müşteri Segmentleri",
                    "First Click, Last Click, Linear Attribution": "İlk Tıklama, Son Tıklama, Doğrusal Atıf",
                    "Visitor History for Converted Visits": "Dönüştürülmüş Ziyaretler için Ziyaretçi Geçmişi",
                    "Visitor History for All Visits": "Tüm Ziyaretler için Ziyaretçi Geçmişi",
                    "Time-Decay Attribution": "Zaman Azalması Atıfı",
                    "Position-Based Attribution": "Konum Bazlı Atıf",
                    "Merchant Friendly Cost": "Satıcı Dostu Maliyet",
                    "Yes": "Evet",
                    "No": "Hayır",

                    //Roasberry vs Polar Analytics i18n
                    "Roasberry vs Polar Analytics": "Roasberry vs Polar Analytics",
                    "Polar Analytics is a great tool for marketers with many integrations from different platforms. A platform providing real-time insights, pixel tracking, AI assistance, different attribution models, a user-friendly interface, and quick integrations sounds incredible yet familiar doesn’t it? We provide all of the above at a fraction of the cost, with dedicated customer support to answer all your needs.": "Polar Analytics, farklı platformlardan birçok entegrasyon sunan pazarlamacılar için harika bir araçtır. Gerçek zamanlı bilgiler, piksel takibi, yapay zeka yardımı, farklı atıf modelleri, kullanıcı dostu arayüz ve hızlı entegrasyonlar sağlayan bir platform harika değil mi? Üstelik tanıdık gelmiyor mu? Biz tüm bunları, tüm ihtiyaçlarınıza cevap verecek özel müşteri desteği ile çok daha düşük bir maliyetle sağlıyoruz.",
                    "Book a Demo": "Demo Rezervasyonu Yap",
                    "Wouldn't you like to make your ad tracking more effective with Roasberry?": "Reklam takibinizi Roasberry ile daha etkili hale getirmek istemez misiniz?",
                    "Features": "Özellikler",
                    "Pixel Tracking": "Pixel Takibi",
                    "Detailed Shopify Analytics": "Detaylı Shopify Analitiği",
                    "Google Ads & Meta Ads Integration": "Google Ads & Meta Ads Entegrasyonu",
                    "RFM Analysis and Customer Segments": "RFM Analizi ve Müşteri Segmentleri",
                    "First Click, Last Click, Linear Attribution": "İlk Tıklama, Son Tıklama, Doğrusal Atıf",
                    "Visitor History for Converted Visits": "Dönüştürülmüş Ziyaretler için Ziyaretçi Geçmişi",
                    "Visitor History for All Visits": "Tüm Ziyaretler için Ziyaretçi Geçmişi",
                    "Time-Decay Attribution": "Zaman Azalması Atıfı",
                    "Position-Based Attribution": "Konum Bazlı Atıf",
                    "Merchant Friendly Cost": "Satıcı Dostu Maliyet",
                    "Yes": "Evet",
                    "No": "Hayır",

                    //Roasberry vs Northbeam i18n
                    "Roasberry vs Northbeam": "Roasberry vs Northbeam",
                    "Northbeam is an amazing tool for brands spending more than $2.5 M annually on paid advertising. It provides multiple attribution models, and also works for physical retail too.": "Northbeam, yılda 2.5 milyon dolardan fazla reklam harcaması yapan markalar için harika bir araçtır. Birden fazla atıf modeli sunar ve fiziksel perakende için de çalışır.",
                    "We designed Roasberry to be effective for brands in an affordable way while being accurate. Our digital fingerprint system tracks every visit - not just order - to provide you insights on not only purchases, but also on abandoned checkouts, page views, and many more. We provide all the information for approximately 1/10th of the price.": "Roasberry'yi, uygun maliyetli bir şekilde markalar için etkili olacak şekilde tasarladık. Dijital parmak izi sistemimiz, sadece siparişleri değil, her ziyareti izler ve size yalnızca satın alma hakkında değil, aynı zamanda terk edilen sepetler, sayfa görüntülemeleri ve daha fazlası hakkında bilgi sağlar. Tüm bu bilgileri yaklaşık olarak 1/10 fiyatına sunuyoruz.",
                    "You can use time decay attribution model to check your conversion cycles, or switch between 6 different attribution models to use the best and most suitable one.": "Dönüşüm döngülerinizi kontrol etmek için zaman azalma atıf modelini kullanabilir veya en uygun ve en iyi olanı kullanmak için 6 farklı atıf modeli arasında geçiş yapabilirsiniz.",
                    "Book a Demo": "Demo Rezervasyonu Yap",
                    "Wouldn't you like to make your ad tracking more effective with Roasberry?": "Reklam takibinizi Roasberry ile daha etkili hale getirmek istemez misiniz?",
                    "Features": "Özellikler",
                    "Pixel Tracking": "Pixel Takibi",
                    "Detailed Shopify Analytics": "Detaylı Shopify Analitiği",
                    "Google Ads & Meta Ads Integration": "Google Ads & Meta Ads Entegrasyonu",
                    "RFM Analysis and Customer Segments": "RFM Analizi ve Müşteri Segmentleri",
                    "First Click, Last Click, Linear Attribution": "İlk Tıklama, Son Tıklama, Doğrusal Atıf",
                    "Visitor History for Converted Visits": "Dönüştürülmüş Ziyaretler için Ziyaretçi Geçmişi",
                    "Visitor History for All Visits": "Tüm Ziyaretler için Ziyaretçi Geçmişi",
                    "Time-Decay Attribution": "Zaman Azalması Atıfı",
                    "Position-Based Attribution": "Konum Bazlı Atıf",
                    "Merchant Friendly Cost": "Satıcı Dostu Maliyet",
                    "Yes": "Evet",
                    "No": "Hayır",

                    //Roasberry vs Triple Whale i18n
                    "Roasberry vs Triple Whale": "Roasberry vs Triple Whale",
                    "With TripleWhale, you can keep track of your orders, use it for attributions. For example, you can see order history to view how customers interacted with your ads.": "TripleWhale ile siparişlerinizi takip edebilir, atıflar için kullanabilirsiniz. Örneğin, müşterilerin reklamlarınızla nasıl etkileşime geçtiğini görmek için sipariş geçmişini görüntüleyebilirsiniz.",
                    "With Roasberry you can filter the visitors to see how people who abandon checkout interact with your website and ads, just as you can see only orders with TripleWhale.": "Roasberry ile, ödeme yapmadan ayrılan ziyaretçilerin web siteniz ve reklamlarınızla nasıl etkileşime geçtiğini görmek için ziyaretçileri filtreleyebilirsiniz, tıpkı TripleWhale ile yalnızca siparişleri görebildiğiniz gibi.",
                    "Book a Demo": "Demo Rezervasyonu Yap",
                    "Wouldn't you like to make your ad tracking more effective with Roasberry?": "Reklam takibinizi Roasberry ile daha etkili hale getirmek istemez misiniz?",
                    "Features": "Özellikler",
                    "Pixel Tracking": "Pixel Takibi",
                    "Detailed Shopify Analytics": "Detaylı Shopify Analitiği",
                    "Google Ads & Meta Ads Integration": "Google Ads & Meta Ads Entegrasyonu",
                    "RFM Analysis and Customer Segments": "RFM Analizi ve Müşteri Segmentleri",
                    "First Click, Last Click, Linear Attribution": "İlk Tıklama, Son Tıklama, Doğrusal Atıf",
                    "Visitor History for Converted Visits": "Dönüştürülmüş Ziyaretler için Ziyaretçi Geçmişi",
                    "Visitor History for All Visits": "Tüm Ziyaretler için Ziyaretçi Geçmişi",
                    "Time-Decay Attribution": "Zaman Azalması Atıfı",
                    "Position-Based Attribution": "Konum Bazlı Atıf",
                    "Merchant Friendly Cost": "Satıcı Dostu Maliyet",
                    "Yes": "Evet",
                    "No": "Hayır"



                }
            }
        },
        fallbackLng: 'en',
        debug: true,
        interpolation: {
            escapeValue: false // React already safes from xss
        }
    });

export default i18n;
