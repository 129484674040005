import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import VisitorInsightsSection from './VisitorInsightsSection';
import UserBehaviorSection from './UserBehaviorSection';
import ProductGoogleAdsIcon from './Images/ProductGoogleAdsIcon.png';
import ProductMetaAdsIcon from './Images/ProductMetaAdsIcon.png';
import ProductInstagramIcon from './Images/ProductInstagramIcon.png';
import ProductTiktokAdsIcon from './Images/ProductTiktokAdsIcon.png';
import ProductShopifyIcon from './Images/ProductShopifyIcon.png';
import ProductPagePixelIcon from "./Images/ProductPagePixelIcon.png";
import ProductPageAccess1 from "./Images/ProductPageAccess1.png";
import ProductPageAccess2 from "./Images/ProductPageAccess2.png";
import BottomFooter from "./bottomfooter";

import CustomerFlowVideo from './ProductPageVideos/ProductPageVisitor/CustomerFlow.mp4';
import VisitorChannelsVideo from './ProductPageVideos/ProductPageVisitor/VisitorChannels.mp4';
import AnalyzeBehaviorVideo from './ProductPageVideos/ProductPageVisitor/AnalyzeDetailedUserBehaviorPatterns.mp4';
import VisitorProfileVideo from './ProductPageVideos/ProductPageVisitor/VisitorProfile.mp4';
import VisitsRealTimeVideo from './ProductPageVideos/ProductPageVisitor/VisitsinRealTime.mp4';
import ImproveAnalyticsVideo from './ProductPageVideos/ProductPageVisitor/ImproveYourWebsitewithUserAnalytics.mp4';

const ProductPageVisitor = () => {
    const { t } = useTranslation();
    const [currentVideo, setCurrentVideo] = useState(CustomerFlowVideo);

    return (
        <div className="w-full h-full flex flex-col gap-[10vh] mt-[15vh] ">
            {/* SECTION 1 */}
            <div className='lg:mb-[5vh]'>
                <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-[#e30b5c] to-black opacity-50"></div>
                <VisitorInsightsSection
                    title={t("Visitor Insights")}
                    subtitleMain={t("Track Your LOG1")}
                    subtitleEmphasized={t("Visitors Flow Easily.")}
                    videoUrl={currentVideo}
                    buttons={[
                        {
                            title: t('Customer Flow'),
                            description: t('Gain insights into customer navigation and optimize their journey for a better user experience and increased conversions.'),
                            onClick: () => setCurrentVideo(CustomerFlowVideo)
                        },
                        {
                            title: t('Visitor Channels'),
                            description: t('Identify top traffic sources, analyze their performance metrics, and refine marketing strategies to boost engagement.'),
                            onClick: () => setCurrentVideo(VisitorChannelsVideo)
                        }
                    ]}
                />
            </div>
            {/* SECTION 2 */}
            <UserBehaviorSection
                title={t("Analyze Detailed User Behavior Patterns")}
                subtitle={t("Gain a deep understanding of your website visitors' actions and preferences. Identify which pages are most engaging and where users drop off.")}
                videoUrl={AnalyzeBehaviorVideo}
                analyticsTitle={t("Detailed Analytics")}
                analyticsDescription={t("Use detailed analytics to uncover patterns in user behavior. Make data-driven decisions to enhance user experience and increase conversion rates.")}
                interactionsTitle={t("Track Interactions")}
                interactionsDescription={t("Track user interactions across different pages and devices. Optimize your website layout and content based on insights from user behavior analysis.")}
            />
            {/* SECTION 3 */}
            <VisitorInsightsSection
                title={t("Visitor Log")}
                subtitleMain={t("Track Your LOG2")}
                subtitleEmphasized={t("Customer Visits and Actions.")}
                videoUrl={currentVideo}
                buttons={[
                    {
                        title: t('Visitor Profile'),
                        description: t('See detailed visitor information such as their visit summary, device and location details, and their interaction history on your site.'),
                        onClick: () => setCurrentVideo(VisitorProfileVideo)
                    },
                    {
                        title: t('Visits in Real Time'),
                        description: t('Get up-to-the-minute data on your website visitors. See how many people are visiting your site in the last 24 hours and last 30 minutes.'),
                        onClick: () => setCurrentVideo(VisitsRealTimeVideo)
                    }
                ]}
            />
            {/* SECTION 4 */}
            <UserBehaviorSection
                title={t("Improve Your Website with User Analytics")}
                subtitle={t("Enhance your website by understanding your users. Use detailed analytics to improve user experience and increase conversions.")}
                videoUrl={ImproveAnalyticsVideo}
                analyticsTitle={t("Behavior Analysis")}
                analyticsDescription={t("Analyze visitor behavior to identify popular pages, user journeys, and potential drop-off points.")}
                interactionsTitle={t("Actionable Insights")}
                interactionsDescription={t("Leverage actionable insights from visitor data to refine your marketing campaigns and boost engagement.")}
            />
            <BottomFooter />
        </div>
    );
};

export default ProductPageVisitor;
