import React from 'react';

function BlogList({ blogData }) {
    return (
        <div>
            {blogData.map((blog) => (
                <div key={blog.newsId}>
                    <h2>{blog.newsTitle}</h2>
                    <p>{blog.newsText}</p>
                    <p>{blog.newsWriter}</p>
                    <p>{blog.newsPublishedDate}</p>
                    <img src={blog.newsImage} alt={blog.newsTitle} />
                </div>
            ))}
        </div>
    );
}

export default BlogList;
